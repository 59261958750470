import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const mock = [
  {
    title: 'Built for developers',
    subtitle:
      'theFront is built to make your life easier. Variables, build tooling, documentation, and reusable components.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    ),
  },
  {
    title: 'Designed to be modern',
    subtitle:
      'Designed with the latest design trends in mind. theFront feels modern, minimal, and beautiful.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    ),
  },
  {
    title: 'Documentation for everything',
    subtitle:
      'We\'ve written extensive documentation for components and tools, so you never have to reverse engineer anything.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    ),
  },
];

const About = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
     
        <Grid
          item
        container
          alignItems={'center'}
          flexDirection={window.innerWidth > 800 ? 'row' : 'column-reverse'}
          xs={12}
          p={"5%"}
          sm={12}
          mx={"auto"}
          mb={"200px"}
          data-aos={isMd ? 'fade-right' : 'fade-up'}
        >
          <Grid xs={12} sm={6} sx={{ mx: "auto", p: "10px"}}>
            <Box marginBottom={4}>
              <Typography variant={"h3"} mb={"60px"} color={"#ccc"} sx={{fontWeight: "bold"}}>Warum unser 3D-Konfigurator?</Typography>
              <Typography component={'p'} color={'#999'}>
                <b style={{color: "#ccc"}}>Speziell für kleine und mittelgroße Hersteller</b><br/>
                Unser System ist speziell auf kleine bis mittelgroße Hersteller von hochpreisigen und erklärungsbedürftigen Produkten ausgerichtet. 
              <br/><br/>
              <b style={{color: "#ccc"}}>Schnell anpassbar auf Deine Bedürfnisse</b><br/>
              Im Kern steht unser 3D-Konfigurator, den wir gezielt auf die Struktur Deiner Produkte anpassen.
              <br/><br/>
              <b style={{color: "#ccc"}}>Der Kunde steht absolut im Fokus</b><br/>
              Durch den Konfigurator ist Dein Produkt in Echtzeit auf Deiner Website in 3D konfigurierbar. Damit begenest Du den Kundenbedürfnissen optimal.
              {/* <br></br><br></br>Aber nicht nur das: Du hast auch eine verlässliche Eingangsgröße für Deinen Vertrieb, denn alle Kofigurationen sind strukturell identisch, vollständig und könnten sofort gekauft und produziert werden. Die Konfiguration ist schon ein interaktives Angebot. */}
              {/* <br></br><br></br>Durch die Standartisierung ist es möglich, unter anderem das Marketing und die Erstellung von Dokumenten wie Angebote oder Stücklisten systematisch zu automatisieren.
               */}
              </Typography>
            </Box>
            {/* <List disablePadding>
              {mock.map((item, index) => (
                <ListItem
                  key={index}
                  disableGutters
                  data-aos="fade-up"
                  data-aos-delay={index * 300}
                  data-aos-offset={100}
                  data-aos-duration={600}
                >
                  <ListItemAvatar>
                    <Box
                      component={Avatar}
                      variant={'rounded'}
                      color={theme.palette.primary.dark}
                      bgcolor={`${theme.palette.primary.light}22`}
                    >
                      {item.icon}
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title}
                    secondary={item.subtitle}
                  />
                </ListItem>
              ))}
            </List> */}
          </Grid>
          <Grid xs={12} sm={6}  item justifyContent={'center'} style={{margin:0}} sx={{mt:0, pt:0,margin: "auto",display: "flex", height: "100%"}} >
          <Box height={"100%"} sx={{display: "flex",  mt:0, mb: window.innerWidth < 800 ? "100px" : 0}}  >
            {/* <Box
              component={'video'}
              src={
                'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/foodtruck_demo.mp4?alt=media&token=83d9d654-58eb-4108-97dd-c3f6ac28ead5'
              }
              width={1}
              autoPlay
              sx={{
                margin: "auto",
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            /> */}
             <video
              style={{
                boxShadow:"rgba(0, 0, 0, 0.15) 0px 2px 8px",
                borderRadius: "8px",
                // objectFit: "cover",
                zIndex: 200,
                // width: "100%",
                height: "100%",
                 width: "100%",
              }}
              controls
              //poster="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/vsl_poster.png?alt=media&token=c17d861f-b2ad-4e55-a7b5-4302e5af7a95"
               //autoPlay
               muted
               loop
            >
              <source
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/foodtruck_demo.mp4?alt=media&token=83d9d654-58eb-4108-97dd-c3f6ac28ead5"
                }
              ></source>
            </video>
          </Box>
        </Grid>
        </Grid>
     
    
    </Box>
  );
};

export default About;
