/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import {
 
  OrbitControls,
  Stage,
  useGLTF,
} from "@react-three/drei";

import { Canvas, useFrame } from "@react-three/fiber";
import TWEEN from "@tweenjs/tween.js";

export default function Showcase3d(props) {
  const gltf = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/designchair.glb?alt=media&token=4f73c88c-b565-4e45-a6c8-ebfce9f1457a",
    "/draco-gltf"
  );
  //const {nodes, materials} = useGLTF("https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Cube.glb?alt=media&token=ba22e2ed-a95a-42cb-9345-c41d67424434&_gl=1*5drolx*_ga*MTMwODY2MDE0NS4xNjk3NjUxOTUx*_ga_CW55HF8NVT*MTY5ODA0MzU5MC4zLjEuMTY5ODA0NDEyMy41NS4wLjA.", '/draco-gltf');
  // useEffect(()=>{
  //   props.toggleModelLoaded(true)
  // },[])
  const gltf1 = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/586-transformed.glb?alt=media&token=41f42ac5-aa32-4884-a7be-9dcdf9960227"
  );
  const gltf2 = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/trailer-transformed.glb?alt=media&token=0dde1166-b5a5-49d6-b236-0424a371277d"
  );
  const gltf3 = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/handbag-transformed.glb?alt=media&token=0d6501c0-fc3a-4c26-85e4-180f12461025"
  );
 
  const [ticking, setTicking] = useState(true);
  const [count, setCount] = useState(0);
  const [ticking1, setTicking1] = useState(true);
  const [count1, setCount1] = useState(0);
  const [currentlyVisible, setCurrentlyVisible] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => ticking && setCount(count + 1), 2e3);
    return () => clearTimeout(timer);
  }, [count, ticking]);

  // useEffect(() => {
  //   const timer1 = setTimeout(() => ticking1 && setCount1(count1+1), 2e2)
  //   return () => clearTimeout(timer1)

  //  }, [count1, ticking1])

  useEffect(() => {
    console.log("count is at", count);
  }, [count]);
  let objectRef1 = useRef();
  let objectRef2 = useRef();
  let objectRef3 = useRef();
  let objectRef4 = useRef();
  let orbitRef = useRef();
  function Tween() {
    useFrame(() => {
      TWEEN.update();
    });
  }
  let nObjs = 4;
  // useEffect(() => {
  //   gltf3 &&
  //     gltf3.scene.traverse((c) => {
  //       c.castShadow = true;
  //       c.receiveShadow = true;
  //     });
  //     gltf2 &&
  //     gltf2.scene.traverse((c) => {
  //       c.castShadow = true;
  //     });
  //     gltf1 &&
  //     gltf1.scene.traverse((c) => {
  //       c.castShadow = true;
  //     });
  //     gltf &&
  //     gltf.scene.traverse((c) => {
  //       c.castShadow = true;
  //     });
  // }, [gltf3]);
  useEffect(() => {
    if (gltf.scene && objectRef1.current) {
      console.log("this is the ref", objectRef1, count);
      try {
        if (count % nObjs == 0) {
          console.log("Doing tween!");
          new TWEEN.Tween(objectRef1.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef2.current.scale)
            .to({ x: 0.022, y: 0.022, z: 0.022 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef3.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef4.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          //setTimeout(()=>{objectRef1.current.visible=false; objectRef2.current.visible=true} ,500)
        } else if (count % nObjs == 1) {
          console.log("Doing tween!");
          new TWEEN.Tween(objectRef1.current.scale)
            .to({ x: 1.1, y: 1.1, z: 1.1 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef2.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef3.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef4.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          //setTimeout(()=>{objectRef1.current.visible=true; objectRef2.current.visible=false} ,500)
        } else if (count % nObjs == 2) {
          console.log("Doing tween!");
          new TWEEN.Tween(objectRef1.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef2.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef3.current.scale)
            .to({ x: 0.04, y: 0.04, z: 0.04 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef4.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          //setTimeout(()=>{objectRef1.current.visible=true; objectRef2.current.visible=false} ,500)
        } else if (count % nObjs == 3) {
          console.log("Doing tween!");
          new TWEEN.Tween(objectRef1.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef2.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef3.current.scale)
            .to({ x: 0, y: 0, z: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          new TWEEN.Tween(objectRef4.current.scale)
            .to({ x: 1.8, y: 1.8, z: 1.8 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
          //setTimeout(()=>{objectRef1.current.visible=true; objectRef2.current.visible=false} ,500)
        }
        //objectRef1.current.scale.set(0.5,0.5,0.5)
      } catch (e) {
        console.log("Could not scale");
      }
    }
  }, [objectRef1, count]);

  return (
    <>
      <Canvas
        style={{
          height: "100%",
          zIndex: "999",
          position: "relative",
          margin: "auto",
          cursor: "-webkit-grab",
          pointerEvents: window.innerWidth < 800 ? "none" : "auto",
          // backgroundImage: "url('https://www.nga.gov/content/dam/ngaweb/collections/sculpture-garden/sg-05-smith-rocks.jpg')",
          // backgroundSize: "cover",
        }}
        className="cvs"
        onPointerDown={() => setTicking(false)}
        onPointerUp={() => setTicking(true)}
        //shadows
        // shadows={{ enable: true, type: THREE.VSMShadowMap }}
        camera={{
          fov: 45,
          near: 0.1,
          far: 200,
          position: [-4, 3, 6],
        }}
      >
        <Stage
              adjustCamera={false}
              preset="upfront"
              intensity={0.001}
              shadows={true}
              environment="city"
            >
        <mesh
          castShadow
          receiveShadow
          scale={window.innerWidth > 800 ? [0.8, 0.8, 0.8] : [0.9, 0.9, 0.9]}
        >
          <Tween />
          {/* <spotLight intensity={1} position={[4, 2000, 4]} castShadow /> */}
          <ambientLight intensity={0.1} />
          {/* <pointLight position={[100, 100, 100]} intensity={0.8} castShadow /> */}

          {/* <ContactShadows
            position={[0, 0, 0]}
            rotation-x={Math.PI / 2}
            width={100}
            height={100}
            opacity={0.2}
            scale={100}
            blur={5.5}
            far={0.8}
          /> */}
          <OrbitControls
            ref={orbitRef}
            autoRotate={true}
            autoRotateSpeed={6}
            onClick={() => setTicking(false)}
            enableZoom={false}
            enablePan={false}
            enableRotate={window.innerWidth < 800 ? false : true}
          />
          <primitive
            castShadow
            receiveShadow
            ref={objectRef1}
            object={gltf.scene}
          />
          <mesh castShadow receiveShadow ref={objectRef2} scale={[0, 0, 0]}>
            <primitive
              castShadow
              receiveShadow
              position={[-2.5 / 0.022, 0 / 0.022, 2 / 0.022]}
              object={gltf1.scene}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            ref={objectRef3}
            rotation={[0, 3.14, 0]}
            scale={[0, 0, 0]}
          >
            {count % nObjs == 2 && (
              <pointLight position={[100, 100, 100]} intensity={3} />
            )}
            {count % nObjs == 2 && (
              <pointLight position={[0, -100, -100]} intensity={3} />
            )}
            <primitive
              castShadow
              receiveShadow
              position={[-2.5, -10.5, 0]}
              object={gltf2.scene}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            ref={objectRef4}
            rotation={[0, 0, 0]}
            scale={[0, 0, 0]}
          >
            {count % nObjs == 3 && (
              <pointLight position={[100, 100, 100]} intensity={.3} />
            )}
            {count % nObjs == 3 && (
              <pointLight position={[0, -100, -100]} intensity={.3} />
            )}
            <primitive
              castShadow
              receiveShadow
              position={[0, 0.5, 0]}
              object={gltf3.scene}
            />
        
          </mesh>
        </mesh>
        {/* <mesh
                geometry={new THREE.BoxGeometry(100, 100, 0.01)}
                material={
                  new THREE.ShadowMaterial({
                    // roughness: 0.0,
                     color: 0xffffff,
                    // metalness: 0.0,
                    opacity: 0.1,
                    //emissive: 0x000000,
                    //bumpScale: 0.0005
                  })
                }
                position={[0, 0.025, 0]}
                rotation={[1.6, 0, 0]}
                name={"shadowFloor"}
                receiveShadow
              ></mesh> */}
              </Stage>
      </Canvas>
      {/* <br/>
    wohohoh
    <LinearProgress sx={{zIndex: 1000000}} variant="determinate" value={(count1 * 13) % 110}/>
     */}
    </>
  );
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/designchair.glb?alt=media&token=4f73c88c-b565-4e45-a6c8-ebfce9f1457a"
);
