import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import RightArrow from 'icons/right-arrow';

const mock = [
  {
    image: '/haus_onta.png',
    description: 'Bauliche Eigenschaften kennenlernen',
    title: 'Massivhaus entdecken',
    link: "https://ynflow.com/experience/vPM6pC95KgfgIJ7Vu0kV",
    parametrizationId: "vPM6pC95KgfgIJ7Vu0kV",
    color: '#bbb',
    background: "#fff",
    product: "Explainer"
  },
  
  {
    image: '/car_personalizer.png',
    description: 'Farbvarianten kennenlernen',
    title: 'Compact SUV personalisieren',
    link: "https://ynflow.com/experience/ZcbfRTpQy2amMJmSPcQn",
    parametrizationId: "ZcbfRTpQy2amMJmSPcQn",
    color: '#bbb',
    background: "#fff",
    product: "Personalizer"
  },
  {
    image: '/coffee_bike_configurator_transp.png',
    description: 'Individuelles Branding und Ausstattungsvarianten',
    title: 'Coffee Bike personalisieren',
    link: "https://ynflow.com/experience/CTj8E6bpuY0KwNBH49w8",
    parametrizationId: "CTj8E6bpuY0KwNBH49w8",
    color: '#bbb',
    background: "#fff",
    product: "Personalizer"
  },
  {
    image: '/nimbles_valves_configurator_transp.png',
    link: "https://ynflow.com/experience/kzGiEoS5bKtyeLGiHDme",
    parametrizationId: "kzGiEoS5bKtyeLGiHDme",
    description: 'Verschiedene Bauformen, Manometer und Flansche',
    title: 'Industrieventil konfigurieren',
    color: '#bbb',
    background: "#fff", 
    product: "Configurator"
  },
  {
    image: '/saw_explainer.png',
    description: 'Entdecken Sie verschiedene Baureihen und Werkzeuge.',
    title: 'Kreissäge erklärt',
    color: '#bbb',
    link: "https://ynflow.com/experience/rPbMh5d6IRHwVBTjGa1L",
    parametrizationId: "rPbMh5d6IRHwVBTjGa1L",
    product: "Explainer"

  },
  // {
  //   image: '/tiny_home_explainer.png',
  //   description:
  //     'Wählen Sie aus verschiedenen Grundmodellen und Dachformen.',
  //   title: 'Gartenpavillon erleben',
  //   color: '#bbb',
  //   link: "https://ynflow.com/experience/7XTAkAwxcZyELu2em0NJ",
  //   parametrizationId: "7XTAkAwxcZyELu2em0NJ",
  //   product: "Explainer"
  // },
 
];

const Folio = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container rowSpacing={"50px"} spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i}  item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
               textAlign: "left",
               
                
                height: "430px",
                cursor: "pointer",
                '&:hover': {
                 
                  '& img': {
                    transform: 'scale(1.1)',
                  },
                },
              }}
              onClick={()=>item.link && window.open(item.link, "_blank")}
            >
              <Box
                component={'img'}
                loading="lazy"
                height={1}
                width={1}
                src={item.image}
                alt="..."
                minHeight={{ xs: 300, md: 300 }}
                sx={{
                  transition: 'transform .7s ease !important',
                  transform: 'scale(1.0)',
                  objectFit: 'contain',
                  height: "300px",
                  margin: "auto",
                  marginBottom: "30px",
                  background: item.background,
                  // filter: "drop-shadow(3px 3px 3px black)",
                
                }}
              />
              <Box
                position={'absolute'}
                bottom={0}
                left={0}
                right={0}
                
                padding={3}
                sx={{
                paddingTop: "30px"
                  
                  // backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 2%, ${item.color})`,
                 
                  
                }}
              >
                <Grid container sx={{padding: window.innerWidth > 800 ? "0 50px" : "150px 0 0 0"}}>
                  <Grid xs={10}>   
                  <Typography variant={"overline"} sx={{marginTop: "20px"}}>{item.product}</Typography>
                  <Typography
                 
                 // fontWeight={700}
                 sx={{ color: 'common.black', fontSize: "21px" }}
               >
                 {item.title}
               </Typography>
               <Typography
                 
                 // fontWeight={700}
                 sx={{ color: 'common.black', fontSize: "14px" }}
                 gutterBottom
               >
                 {item.description}
               </Typography></Grid>
                  <Grid xs={2} sx={{color: "#ffcb00", marginTop: "30px"}}><RightArrow width={"40px"} strokewidth={8}/></Grid>
                </Grid>
             
                {/* {item.link ? <Button
                  size={'large'}
                  variant={'contained'}
                  color={'secondary'}
                  sx={{width: "200px", float: "right"}}
                  onClick={()=>window.open(item.link, "_blank")}
                >
                  Los geht's
                </Button>:<Button
                  size={'large'}
                  variant={'contained'}
                  // color={'white'}
                  // disabled
                  sx={{width: "200px", float: "right", cursor: "not-allowed",background: "grey", color: "white", "&:hover":{backgroundColor: "grey"}}}
                 
                >
                  In Arbeit
                </Button>} */}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Folio;
