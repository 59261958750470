/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import {
  Avatar,
  Button,
 
  Chip,
 
  Container,
  ListItem,
  ListItemAvatar,
  ListItemText,
 
} from "@mui/material";
import { useFormik } from "formik";
const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const initialValues = {
    name: "",
    email: "",
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(2, "Please enter a valid name")
      .max(50, "Please enter a valid name")
      .required("Please specify your name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),
  });

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2} >
        <center className={"animate__animated animate__fadeInDown animate__delay-1s"}>
        <Chip  style={{fontSize: "12px", fontWeight: "bold", marginBottom: "30px", }} label={<>Wir  ❤️  Hersteller</>}></Chip>
        </center>
        <Typography
          className={"animate__animated animate__fadeInUp"} //block reveal
          color="black"
          sx={{
            fontWeight: 700,
            zIndex: 1000,
            maxWidth: "950px",
            mx: "auto",
            color: "#222",
            textAlign: "center",
            position: "relative",
            fontSize: window.innerWidth > 800 ? "75px" : "50px",
            lineHeight: window.innerWidth > 800 ? "80px" : "55px",
          }}
        >
          <span
            class="reveal-inner"
            style={{ letterSpacing: "-2px", lineHeight: "2rem" }}
          >
            Mehr Neukunden durch einen 3D-Konfigurator.
          </span>
        </Typography>

        {/* <Typography
          component={"span"}
          variant="h3"
          sx={{ fontWeight: 700, color: "common.black" }}
        >
          Got a complex product? No existing solution works for you? 
         
        </Typography> */}
      </Box>
      <Box sx={{ maxWidth: "600px", mx: "auto", mt: 4, textAlign: "center" }}>
        <Typography
          className={"animate__animated animate__fadeIn"}
          variant="h6"
          component="p"
          sx={{ color: "#222", fontWeight: "400", fontSize: "22px" }}
        >
          Erkläre Dein Produkt in kürzester Zeit mit unserem bewährten System für 3D-Konfiguratoren.
        </Typography>
      </Box>
      {/* {window.innerWidth < 800 && (
        <Box>
          <div style={{ marginTop: "30px", position: "relative" }}>
            <video
              style={{
                // objectFit: "cover",
                zIndex: 200,
                width: "100%",
                // width: "550px",
              }}
              controls
              poster="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/vsl_poster.png?alt=media&token=c17d861f-b2ad-4e55-a7b5-4302e5af7a95"
              // autoPlay
              // muted
              // loop
            >
              <source
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/1%20(3).mp4?alt=media&token=e552172a-9b17-42a3-8c78-902aae892e1c"
                }
              ></source>
            </video>
          </div>
        </Box>
       
      )} */}

      {/* <Box
              padding={{ xs: 3, sm: 6 }}
              width={"100vw"}
              margin={"auto"}
              component={"div"}
              // boxShadow={1}
              // data-aos="fade-up"
              // sx={{background: "#999"}}
            >
                 <Container>
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                  <Box
                    width={1}
                    // marginRight={{ xs: 0, md: 2 }}
                    // marginBottom={{ xs: 4, md: 0 }}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                  >
                    <TextField
                      sx={{
                        height: 54,
                        marginRight: { xs: 0, md: 2 },
                        marginBottom: { xs: 4, md: 0 },
                      }}
                      variant="outlined"
                      color="primary"
                      size="medium"
                      label="Name"
                      fullWidth
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                      sx={{
                        height: 54,
                      }}
                      variant="outlined"
                      color="primary"
                      size="medium"
                      label="Telefon"
                      fullWidth
                      name={'email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                  <Box>
                    <Button
                      sx={{ height: 54, whiteSpace: 'nowrap', minWidth: 100, color: "black",ml:2 }}
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                      type="submit"
                    >
                      Rückruf erhalten
                    </Button>
                  </Box>
                </Box>
              </form>
              </Container>
            </Box> */}
      <center>
        <Button
          variant="contained"
         
          color="primary"
          component="a"
          className={"animate__animated animate__fadeIn butn butn__new"}
          // target="blank"
          speed={1}
          href="#request-demo"
          onClick={() =>
            window.open(
              "https://calendar.app.google/q9TvtW389n7NsqaP6",
              "_blank"
            )
          }
          size="large"
          sx={{
            mb: window.innerWidth > 800 ? "30px" : "0px",
            position: "relative",
            width: window.innerWidth > 800 ? "400px" : "350px",
            mt: "40px",
            borderRadius: "28px",
            mx: "auto",
            position: "relative",
            zIndex: 10000,
            // fontWeight: "bold",
            color: "black",
            fontWeight: "500",
            // background: theme.palette.primary.main,
            boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
          }}
          endIcon={
            <Box
              component={"svg"}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width={24}
              height={24}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </Box>
          }
        >
          Kostenloses Erstgespräch
        </Button>
        {/* <button
          onClick={() =>
            window.open(
              "https://calendar.app.google/q9TvtW389n7NsqaP6",
              "_blank"
            )
          }
          style={{ height: "60px", cursor: "pointer" }}
          class="ctabutton"
        >
          <div class="gradient">Kostenloses Erstgespräch</div>
        </button> */}
      </center>
      <Container>
        <Grid
          container
          spacing={0}
          sx={{ mt: 3, mb: 4, maxWidth: "auto", mx: "auto" }}
        >
          {[
            <span>
              <b>  <center>  ✔️ Gewinne mehr Neukunden </center></b>
                   
            </span>,
            <span>
              <center>
              <b> ✔️ Mehr Interessenten zu Kunden machen</b>
              </center>
            </span>,
            <span>
               <center>
              <b> ✔️ Blitzschnell beraten und verkaufen</b></center>
            </span>,
          ].map((item, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Box
                component={ListItem}
                disableGutters
                width={"auto"}
                padding={0}
              >
                {/* <Box
                  component={ListItemAvatar}
                  minWidth={"auto !important"}
                  marginRight={2}
                >
                  <Box
                    component={Avatar}
                    // bgcolor={theme.palette.primary.main}
                    bgcolor={"transparent"}
                    width={20}
                    height={20}
                    sx={{ marginRight: "-13px" }}
                  >
                    <svg
                      width={16}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill={"black"}
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Box>
                </Box> */}

                <ListItemText
                  primaryTypographyProps={{ fontSize: "12px" }}
                  primary={item}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={"flex-start"}
        marginTop={2}
        sx={{ filter: "invert(1)" }}
      >
        {[
          "https://assets.maccarianagency.com/svg/logos/airbnb-original.svg",
          "https://assets.maccarianagency.com/svg/logos/amazon-original.svg",
          "https://assets.maccarianagency.com/svg/logos/fitbit-original.svg",
          "https://assets.maccarianagency.com/svg/logos/netflix-original.svg",
          "https://assets.maccarianagency.com/svg/logos/google-original.svg",
          "https://assets.maccarianagency.com/svg/logos/paypal-original.svg",
        ].map((item, i) => (
          <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
            <Box
              component="img"
              height={1}
              width={1}
              src={item}
              alt="..."
              sx={{
                filter: "brightness(0) invert(1)",
              }}
            />
          </Box>
        ))}
      </Box> */}
    </Box>
  );

  return (
    <Grid
      container
      // spacing={4}
      sx={{
        m:0,
        width: "100%",
        px: 2,
       
        position: "relative",
        paddingBottom: window.innerWidth > 600 ? "20px" : "20px",
        marginTop: window.innerWidth < 800 ? "100px" : "auto",
        pl: 0,
      }}
    >
      <Grid
        order={{ xs: 2, md: 1 }}
        sx={{ zIndex: "500", position: "relative" }}
        item
        container
        alignItems={"center"}
        xs={12}
        md={12}
      >
        <Box sx={{ width: "100vw" }}>
          <LeftSide />
        </Box>
      </Grid>
      <Grid
        order={{ xs: 1, md: 2 }}
        xs={12}
        md={6}
        sx={{ pl: 0, pt: 0, zIndex: 50 }}
      >
        {window.innerWidth < 800 ? (
          <Box
            // component={"img"}
            // loading={"eager"}
            // sx={{
            //   marginLeft: window.innerWidth > 800 ? "-100px" : "30px",
            //   marginBottom: window.innerWidth > 800 ? "-70px" : "0px",
            //   marginTop: window.innerWidth > 800 ? "30px" : "100px",
            // }}
            // src={"heli.png"}
            width={window.innerWidth > 800 ? "180%" : "100%"}
          >
            <>
              {/* <div class="ring" style={{ margin: "0 auto -400px auto", marginBottom: "-400px",}}>
  <i 
  style={{"--clr":"#ffcb00"}}
  ></i>
  <i 
  style={{"--clr":"#ffcb00"}}
  ></i>
  <i 
  style={{"--clr":"#ffcb00"}}
  ></i>
   </div> */}
            </>
          </Box>
        ) : (
          <>
            {/* <div
              class="ring"
              style={{ margin: "0 auto -400px auto", marginBottom: "-400px", marginLeft: "60px" }}
            >
              <i style={{ "--clr": "#ffcb00" }}></i>
              <i style={{ "--clr": "#ffcb00" }}></i>
              <i style={{ "--clr": "#ffcb00" }}></i>
            </div> */}
            {/* <div
              style={{
                marginLeft: "100px",
                position: "absolute",
                marginTop: "-50px",
              }}
            >
              <video
                style={{
                  // objectFit: "cover",
                  zIndex: 200,
                  height: "300px",
                  // width: "550px",
                }}
                controls
                poster="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/vsl_poster.png?alt=media&token=c17d861f-b2ad-4e55-a7b5-4302e5af7a95"
                // autoPlay
                // muted
                // loop
              >
                <source
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/1%20(3).mp4?alt=media&token=e552172a-9b17-42a3-8c78-902aae892e1c"
                  }
                ></source>
              </video>
            </div> */}
          </>
        )}

        {/* <Box
          sx={{
            position: "relative",
            marginX: "auto",
          }}
        >
          <Box
            sx={{
              position: "relative",
              marginX: "auto",
            }}
          >
            <Box>
              <Box
                position={"relative"}
                zIndex={200}
                maxWidth={1}
                height={"auto"}
                sx={{ verticalAlign: "middle", filter: "drop-shadow(1px 1px 3px darkgrey)" }}
              >
                {window.innerWidth > 600 ? (
                  <LaptopSkeletonIllustration />
                ) : (
                  <div style={{transform: "rotate(3deg)", width: "90%", margin: "auto"}}>
                  <PhoneSkeletonIllustration  />
                  </div>
                )}
              </Box>
              {window.innerWidth > 600 ? (
                <Box
                  position={"absolute"}
                  top={"8.4%"}
                  left={"12%"}
                  width={"76%"}
                  height={"83%"}
                  border={`1px solid ${theme.palette.alternate.dark}`}
                  zIndex={300}
                >
                  <Box
                    component={"div"}
                    loading="lazy"
                    alt="Image Description"
                    width={1}
                    height={1}
                    sx={{
                      objectFit: "cover",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  >
                    <video
                      style={{
                        objectFit: "cover",
                        zIndex: 200,
                        height: "100%",
                        width: "100%",
                      }}
                      controls
                      poster="/laptop_poster.jpg"
                      // autoPlay
                      // muted
                      // loop
                    >
                      <source
                        src={
                          "https://bcassets.s3.eu-west-1.amazonaws.com/yellow_explainer_2.mp4"
                        }
                      ></source>
                    </video>
                  </Box>
                </Box>
              ) : (
                <Box
                  position={"absolute"}
                  top={"2.4%"}
                  left={"4%"}
                  width={"92.4%"}
                  height={"95%"}
                  zIndex={300}
                  
                
                >
                  <Box
                    component={"div"}
                    loading="lazy"
                    alt="Image Description"
                    width={1}
                    height={1}
                    sx={{
                      transform: "rotate(3deg)", width: "90%", margin: "auto",
                      objectFit: "cover",
                      borderRadius: "2rem",
                      zIndex: 100,
                      position: "relative",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  >
                    <video
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        zIndex: 200,
                        borderRadius: "48px"
                      }}
                      controls
                      poster="/mobile_poster.png"
                      // autoPlay
                      // muted
                      // loop
                    >
                      <source
                        src={
                          "https://bcassets.s3.eu-west-1.amazonaws.com/yellow_explainer_1.mp4"
                        }
                      ></source>
                    </video>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box> */}
      </Grid>
    </Grid>
  );
};

export default Hero;
