import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { DoNotDisturbAltOutlined } from "@mui/icons-material";
const mock = [
  {
    title: "Du versuchst Dein Produkt mit PDFs oder komplizierten Formularen zu verkaufen.",
    description:
      "Du schaffst es nicht, Kunden schnell für Dein Produkt zu begeistern, sondern schreckst sie noch zusätzlich mit umständlichen PDFs und Formularen ab. Damit verlierst Du täglich wichtige Kunden.",
    illustration:
      // "/undraw_lost_re_xqjt.svg",
      "/undraw_throw_away_re_x60k.svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration4--dark.svg",
  },
  {
    title: "Deine Website hat nichts zu bieten.",
    description:
      "Interessenten wollen ein interaktives, spannendes Beratungs- und einkaufserlebnis. Nutzt Du Deine Möglichkeiten oder ist Deine Website eigentlich nur ein digitales PDF?",
    illustration:
      "/undraw_feeling_blue_-4-b7q (1).svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg",
  },
  // {
  //   label: "Client portal access",
  //   title: "Chaos in Vertrieb und Marketing durch die falschen Tools, undurchsichtige Excel-Sheets oder sogar Zettelwirtschaft",
  //   description:
  //     "Chaos sorgt dafür, dass Kundenanfragen nicht schnell oder nicht wirksam bearbeitet werden und der Umsatz an Deinen Konkurrenten geht, der es besser macht.",
  //   illustration:
  //     "/undraw_abstract_re_l9xy.svg",
  //   illustrationDark:
  //     "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  // },
  // {
  //   label: "Client portal access",
  //   title: "Keine systematische Datenerhebung in Marketing und Vertrieb",
  //   description:
  //     "Dadurch bleiben Fehler in Vertrieb und Marketing unentdeckt und Du bleibst langfristig unter Deinen Umsatzmöglichkeiten.",
  //   illustration:
  //     "/undraw_logic_re_nyb4.svg",
  //   illustrationDark:
  //     "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  // },
  {
    label: "Client portal access",
    title: "Unzureichende Unterstützung durch digitale Systeme",
    description:
      "Mühsame, sich wiederholende Prozesse müssen ständig per Hand ausgeführt werden. Dadurch lähmst Du den Vertrieb und erzielst wenige Abschlüsse und damit wenig Umsatz.",
    illustration:
      "/undraw_autumn_re_rwy0.svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  },
];

const Work = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={12}>
        {/* <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary"}
          align={"center"}
        >
          Das machst Du als Hersteller falsch
        </Typography> */}
        <Typography variant={"h3"} sx={{ fontWeight: "bold", color: "#222" }} align={"center"}>
          Diese Probleme im Vertrieb kosten dich 7-stellige Umätze.
        </Typography>
        {/* <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={"center"}
          sx={{my:4, maxWidth: "750px", mx: "auto"}}
        >
          Ohne ein bewährtes digitales System im Vertrieb schleichen sich immer wieder Fehler in Deine Prozesse ein, die langfristig hohe 7-stellige Umsatzeinbußen bedeuten können.
        </Typography> */}
        {/* <Box marginTop={2} display={"flex"} justifyContent={"center"}>
          <Button
            color={"primary"}
            variant={"contained"}
            size={"large"}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width={20}
                height={20}
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            }
          >
            Contact us
          </Button>
        </Box> */}
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
            key={i}
            item
            container
            xs={12}
            spacing={4}
            mb={6}
            // direction={i % 2 === 1 ? "row-reverse" : "row"}
          >
            <Grid item container alignItems={"center"} xs={12} sm={12} >
              <Box sx={{ maxWidth: "700px", mx: "auto"}}>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 700, fontSize: "16px" }}
                >
                  <Grid container>
                    <Grid xs={1.5} sm={1} ><DoNotDisturbAltOutlined sx={{color: "#555", fontSize: "35px"}}/></Grid>
                    <Grid xs={10.5} sm={11}><Typography sx={{fontWeight: "bold", color: "#222"}}>{item.title}</Typography>
                    <Typography color="text.secondary">
                  {item.description}
                </Typography></Grid>
                  </Grid>
                  
                  
                </Typography>
              
                {/* <Button
                  size={"large"}
                  sx={{ marginTop: 2 }}
                  endIcon={
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={24}
                      height={24}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </Box>
                  }
                >
                  Learn more
                </Button> */}
              </Box>
            </Grid>
            {/* <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              xs={12}
              sm={6}
            >
              <Box
                component={"img"}
                src={`${
                  theme.palette.mode === "light"
                    ? item.illustration
                    : item.illustrationDark
                }`}
                sx={{filter: "grayscale(0.8)"}}
                alt={item.title}
                width={0.45}
                maxWidth={"30%"}
              />
            </Grid> */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Work;
