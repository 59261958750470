import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

import React, {
  Profiler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { ColorSelection } from "./ColorSelection";
import ConfiguratorTextField from "./ConfiguratorTextField";
import ConfiguratorSelectField from "./ConfiguratorSelectField";
import { Helmet } from "react-helmet";

import audioSource from "./click1.wav";
import { useNavigate, useParams } from "react-router-dom";
// import InfoIcon from "@mui/icons-material/Info";
import {
  HelpOutlineOutlined,
  HelpOutline as InfoIcon,
} from "@mui/icons-material";
import { useDocumentData } from "react-firebase-hooks/firestore";
import "yet-another-react-lightbox/styles.css";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { app, auth, db } from "index";
import LoadingOverlay from "react-loading-overlay";
import { Transition } from "react-transition-group";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import {
  Badge,
  Card,
  Chip,
  Input,
  LinearProgress,
  Table,
  Tooltip,
} from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import ConfigForm, { actionCodeSettings } from "ConfigForm/ConfigForm";
import { useAuthState } from "react-firebase-hooks/auth";
import { RWebShare } from "react-web-share";
import FadeModalDialog from "FadeModalDialog";
import { getFunctions, httpsCallable } from "firebase/functions";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { initializeApp } from "firebase/app";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDebounce } from "use-debounce";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ConfiguratorUploadField from "ConfiguratorUploadField";
import FadeInModal from "FadeInModal";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";
import NetworkConnection from "icons/network-connection";

import ConfiguratorExtendableSelect from "ConfiguratorExtendableSelect";
import ConfiguratorMultiSelectField from "ConfiguratorMultiSelectField";

import { Toaster, toast } from "sonner";
import CountTo from "CountTo";
import debounce from "lodash.debounce";
import Sheet from "react-modal-sheet";
import { instanceOf } from "prop-types";
import { useTour } from "@reactour/tour";
import { useCookies } from "react-cookie";
import ConfiguratorMultiSelectAmountsField from "ConfiguratorMultiSelectAmountsField";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import CQuestion from "icons/c-question";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import ARView from "ARView";
import ClipboardCopy from "CopyButton";
import useWindowSize from "useWindowSize";
import FloppyDisk from "icons/floppy-disk";
import CameraControls from "camera-controls";
import { useCountUp } from "react-countup";
import CheckList from "icons/check-list";
import CurrencyFormat from "react-currency-format";
import CtrlDown from "icons/ctrl-down";
import Expand from "icons/expand";
import Expand_more from "icons/expand_more";
import { WebGLPathTracer } from "three-gpu-pathtracer";
import AugmentedReality from "icons/augmented-reality";
var isEqual = require("lodash.isequal");

const firebaseConfig = {
  apiKey: "AIzaSyBTmxMWFGNRQ5QtJ2XnPBlFxxSl368w6Gg",
  authDomain: "yellow-web-6310c.firebaseapp.com",
  projectId: "yellow-web-6310c",
  storageBucket: "yellow-web-6310c.appspot.com",
  messagingSenderId: "622819967778",
  appId: "1:622819967778:web:c10f46040744b59ae65b7b",
  measurementId: "G-J2JMB5QDN9",
};
try {
  const app = initializeApp(firebaseConfig);
} catch (e) {
  console.log(e);
}

function BottomSheetDrawer(props) {
  const [isOpen, setOpen] = useState(true);

  const ref = props.sheetRef;
  const snapTo = (i) => ref.current?.snapTo(i);
  const innerWidth = useWindowSize();
  return (
    <>
      {/* <button onClick={() => setOpen(true)}>Open sheet</button> */}

      <Sheet
        style={{ zIndex: 100 }}
        initialSnap={1}
        snapPoints={[-30, 230]}
        isOpen={isOpen}
        onClose={() => {
          //  setOpen(false)
        }}
        tweenConfig={{ ease: "easeInOut", duration: 0.55 }}
        // rootId="menu"

        onSnap={(e) => {
          if (e == 0) {
            props.toggleLargeMenu(true);
          }
          if (e == 1) {
            props.toggleLargeMenu(false);
          }
        }}
        ref={ref}
      >
        <Sheet.Container
          disableDrag={
            window.location.href.indexOf("embed") > -1 && innerWidth < 800
          }
        >
          {/* {!props.navigation ? ( */}
          <Sheet.Header
            style={{
              cursor: "pointer",
              background:
                window.location.href.indexOf("embed") > -1
                  ? "rgb(250,250,250)"
                  : "white",
            }}
            onClick={(e) => {
              ref.current.snapTo(ref.current.y.current == 0 ? 1 : 0);
              console.log(ref.current);
            }}
            disableDrag={
              window.location.href.indexOf("embed") > -1 && innerWidth < 800
            }
          />
          {/* // ) : (
          //   <div style={{ height: "40px" }}></div>
          // )} */}

          <Sheet.Content
            disableDrag={
              // window.location.href.indexOf("embed") > -1 ||
              // innerWidth < 800
              true
            }
          >
            {props.largeMenu ? (
              <>
                {props.largeMenu && innerWidth > 800 && (
                  <div
                    style={{
                      background: "rgba(255,255,255,0.5)",
                      paddingTop: "10px",
                      paddingLeft: "15px",
                      borderRadius: "8px",
                      paddingTop: "10px",
                      paddingLeft: "15px",
                      zIndex: 9999999,
                      textAlign: "right",
                      display: "inline-block",
                      position: "fixed",
                      top: "50px",
                      right: "30px",
                      paddingRight: "20px",
                    }}
                    onClick={() => ref.current.snapTo(1)}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        height: "30px",
                        zIndex: 99999999999999,
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          //marginTop: "8px",
                          marginRight: "5px",
                          marginTop: "-1px",
                        }}
                      >
                        schließen
                      </span>
                      <span style={{ marginBottom: "-6px" }}>
                        <CloseIcon width={"25px"} strokewidth={3} />{" "}
                      </span>
                    </div>
                  </div>
                )}{" "}
                <Sheet.Scroller
                  draggableAt={
                    window.location.href.indexOf("embed") > -1 &&
                    innerWidth < 800
                      ? false
                      : false //"both"
                  }
                >
                  <>
                    <> {props.children}</>
                  </>
                </Sheet.Scroller>
              </>
            ) : (
              props.children
            )}
          </Sheet.Content>
        </Sheet.Container>
        {/* {ref.current == 1 && <Sheet.Backdrop onTap={()=>ref.current.snapTo(1)}></Sheet.Backdrop>}
         */}
      </Sheet>
    </>
  );
}

export function ShareModalDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const { isOpen } = useTour();
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();
  let { id, configurationId } = useParams();
  const [showLoadingSpinner, toggleShowLoadingSpinner] = useState(false);
  const innerWidth = useWindowSize();
  const [storedConfig, setStoredConfig] = useState(props.storedConfig);

  //const [storedConfig, setStoredConfig] = useState(props.storedConfig)
  //useEffect(()=>{setStoredConfig(props.storedConfig)},[props.storedConfig])

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setStoredConfig(props.storedConfig);
    console.log("STORED CONFIG", props.storedConfig);
  }, [props.storedConfig]);
  return (
    <React.Fragment>
      <LoadingOverlay active={showLoadingSpinner} spinner text="Loading ...">
        {/* <p>Please wait a second</p> */}
      </LoadingOverlay>

      {!(window.location.href.indexOf("embed") > -1) && (
        <Box
          onClick={() => {
            props.parametrization &&
              props.parametrization.branding.urlToWebsite &&
              window.open(
                props.parametrization.branding.urlToWebsite,
                "_blank"
              );
          }}
          component={"img"}
          id={"brand-icon"}
          src={
            props.parametrization && props.parametrization.branding.brandIcon
          }
          width={innerWidth > 800 ? "120px" : "45px"}
          sx={{
            maxHeight: "70px",
            maxWidth: "95px",
            objectFit: "contain",
            zIndex: 100,
            position: "fixed",
            userSelect: "none",
            top: "20px",
            left: innerWidth > 800 ? "20px" : "10px",
            cursor:
              props.parametrization &&
              props.parametrization.branding.urlToWebsite
                ? "pointer"
                : "auto",
          }}
        ></Box>
      )}
      <Box
        element={"div"}

        // sx={{
        //   position: "fixed",
        //   color: "#777",
        //   top: innerWidth < 800 ? "auto" : "200px",
        //   bottom: innerWidth < 800 ? "310px" : "auto",
        //   left: "20px",
        //   zIndex: 1100,
        //   textAlign: "right",
        // }}const menu
      >
        {/* <Typography variant={"overline"} sx={{ fontSize: "0.8rem" }}>
          {user ? (
            // <>
            //   Logged in as {user.email} |{" "}
            //   <span
            //     style={{
            //       cursor: "pointer",
            //       "&:hover": { textDecoration: "underline" },
            //     }}
            //     onClick={() => signOut(auth)}
            //   >
            //     Log out
            //   </span>
            // </>
            <PersonOutlineIcon sx={{display: "inline-block", marginBottom: "-5px", marginRight: "4px"}}/>
          ) : (
            <span
              style={{
                paddingRight: "23px",
                display: "inline-block",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => window.open("/login", "_blank")}
            >
              
              Login <ChevronRightIcon sx={{fontSize: "0.85rem", marginBottom: "-3px", display: "inline-block"}}/>
            </span>
          )}
        </Typography> */}
        {/* <br /> */}
        {/* {user && (
          <Typography
            variant={"overline"}
            sx={{
              fontSize: "0.8rem",
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
            onClick={() => window.open("/portal/my-configurations", "_blank")}
          >
            View all configurations
          </Typography>
        )} */}

        <Box
          className={"save-button"}
          onClick={(e) => {
            //       if (!props.configuration.submitted) {
            //   const docRef = setDoc(
            //     doc(db, "configurations", configurationId),
            //     {
            //       lastUpdateTimestamp: new Date().toISOString(),

            //       data: props.configuration.reduce(
            //         (acc, cur) => ({
            //           ...acc,
            //           [cur.title]:
            //             cur.currentValue != undefined ? cur.currentValue : "",
            //         }),
            //         {}
            //       ),
            //     },
            //     { merge: true }
            //   );
            // }
            !isOpen && setOpen(true);
          }}
          sx={{ cursor: "pointer", mt: 1, ml: "2px", userSelect: "none" }}
        >
          <Tooltip
            title={
              // isEqual(
              // configurationId && props.storedConfig && props.storedConfig.data,
              //   props.configuration.reduce(
              //     (acc, cur) => ({
              //       ...acc,
              //       [cur.title]: cur.currentValue,
              //     }),
              //     {}
              //   )
              // )
              !props.configDelta ? "speichern" : "Ungespeicherte Änderungen"
            }
            placement="right"
          >
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  animation: "pulsate-red 1s ease-in-out infinite",
                  backgroundColor: "#ff4444",
                  display:
                    // isEqual(
                    //   props.storedConfig && props.storedConfig.data,
                    //   props.configuration.reduce(
                    //     (acc, cur) => ({
                    //       ...acc,
                    //       [cur.title]: cur.currentValue,
                    //     }),
                    //     {}
                    //   )
                    // )
                    !props.configDelta ? "none" : "auto",
                },
              }}
              color="primary"
              overlap="circular"
              badgeContent={"!"}
              variant="dot"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <span
                style={{
                  color: "#333",
                  background: "white",
                  borderRadius: "50px",
                  display: "inline-block",
                  width: "40px",
                  height: "40px",
                  boxShadow: props.parametrization.branding?.boxShadow
                    ? props.parametrization.branding.boxShadow
                    : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                  border: props.parametrization.branding?.border
                    ? props.parametrization.branding.border
                    : "none",
                }}
              >
                <center style={{ marginTop: "7px", marginLeft: "0px" }}>
                  <FloppyDisk width={"24px"} strokewidth={2} />
                </center>
              </span>
            </Badge>
          </Tooltip>
          {/* {!user && "save & "} */}
        </Box>
        <Box
          className={"share-button"}
          onClick={(e) => {
            !isOpen && configurationId && setShareOpen(true);
          }}
          sx={{
            cursor: configurationId ? "pointer" : "not-allowed",
            mt: innerWidth < 800 ? "55px" : "65px",
            ml: "2px",
            userSelect: "none",
          }}
        >
          <Tooltip
            title={
              configurationId ? "teilen" : "Zum Teilen bitte zuerst abspeichern"
            }
            placement="right"
          >
            <span
              style={{
                color: configurationId ? "#333" : "#aaa",
                background: configurationId ? "white" : "transparent",
                borderRadius: "50px",
                display: "inline-block",
                width: "40px",
                height: "40px",
                boxShadow: props.parametrization.branding?.boxShadow
                  ? props.parametrization.branding.boxShadow
                  : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                border: props.parametrization.branding?.border
                  ? props.parametrization.branding.border
                  : "none",
              }}
            >
              <center style={{ marginTop: "7px", marginLeft: "-4px" }}>
                <NetworkConnection width={"24px"} strokewidth={2} />
              </center>
            </span>
          </Tooltip>
          {/* {!user && "save & "} */}
        </Box>
      </Box>
      <Box
        element={"div"}

        // sx={{
        //   position: "fixed",
        //   color: "#777",
        //   top: innerWidth < 800 ? "auto" : "200px",
        //   bottom: innerWidth < 800 ? "310px" : "auto",
        //   left: "20px",
        //   zIndex: 1100,
        //   textAlign: "right",
        // }}const menu
      >
        {/* <Typography variant={"overline"} sx={{ fontSize: "0.8rem" }}>
          {user ? (
            // <>
            //   Logged in as {user.email} |{" "}
            //   <span
            //     style={{
            //       cursor: "pointer",
            //       "&:hover": { textDecoration: "underline" },
            //     }}
            //     onClick={() => signOut(auth)}
            //   >
            //     Log out
            //   </span>
            // </>
            <PersonOutlineIcon sx={{display: "inline-block", marginBottom: "-5px", marginRight: "4px"}}/>
          ) : (
            <span
              style={{
                paddingRight: "23px",
                display: "inline-block",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => window.open("/login", "_blank")}
            >
              
              Login <ChevronRightIcon sx={{fontSize: "0.85rem", marginBottom: "-3px", display: "inline-block"}}/>
            </span>
          )}
        </Typography> */}
        {/* <br /> */}
        {/* {user && (
          <Typography
            variant={"overline"}
            sx={{
              fontSize: "0.8rem",
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
            onClick={() => window.open("/portal/my-configurations", "_blank")}
          >
            View all configurations
          </Typography>
        )} */}

        <Box
          className={"table-button"}
          onClick={(e) => {
            props.setOverviewModalOpen(true);
          }}
          sx={{
            cursor: "pointer",
            mt: innerWidth > 800 ? "14px" : "14px",
            ml: innerWidth > 800 ? "2px" : "2px",
            userSelect: "none",
          }}
        >
          <Tooltip title="Übersicht" placement="right">
            <span
              style={{
                color: "#333",
                background: "white",
                borderRadius: "50px",
                display: "inline-block",
                width: "40px",
                height: "40px",
                boxShadow: props.parametrization.branding?.boxShadow
                  ? props.parametrization.branding.boxShadow
                  : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                border: props.parametrization.branding?.border
                  ? props.parametrization.branding.border
                  : "none",
              }}
            >
              <center style={{ marginTop: "7px", marginLeft: "0px" }}>
                {" "}
                <CheckList width={"24px"} strokewidth={2} />
              </center>
            </span>
          </Tooltip>
          {/* {!user && "save & "} */}
        </Box>

        {props.showDocs && (
          <Box
            className={"docs-button"}
            onClick={(e) => {
              props.setInfoModalOpen(true);
            }}
            sx={{
              cursor: "pointer",
              mt: innerWidth > 800 ? "14px" : "14px",
              ml: innerWidth > 800 ? "2px" : "2px",
              userSelect: "none",
            }}
          >
            <Tooltip title="Mehr Infos" placement="right">
              <span
                style={{
                  color: "#333",
                  background: "white",
                  borderRadius: "50px",
                  display: "inline-block",
                  width: "40px",
                  height: "40px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                }}
              >
                <center style={{ marginTop: "8px", marginLeft: "0px" }}>
                  {" "}
                  <CQuestion width={"24px"} strokewidth={2} />
                </center>
              </span>
            </Tooltip>
            {/* {!user && "save & "} */}
          </Box>
        )}

        {/* {props.parametrization.id == "2NSNN8FBJxlNUq98Liix" && <Box
            className={"measures-button"}
            onClick={(e) => {
              // props.setInfoModalOpen(true);
              console.log("got scene", props.scene)
              props.scene.traverse(obj => {
                if([""]obj.name)
              })
            }}
            sx={{ cursor: "pointer", mt: "140px" }}
          ><Tooltip title="Bemaßung ein/ausblenden" placement="right">
            <span style={{ color: "#333" }}>
              <CQuestion width={"28px"} strokewidth={2} />
            </span>
            </Tooltip>
           
          </Box>} */}
      </Box>
      <Box
        sx={{
          zIndex: "-1",
          position: "fixed",
          top: 0,
          left: 0,
          width: innerWidth < 800 ? "60px" : "150px",
          height: "100vh",
          backgroundColor: "transparent",
        }}
      ></Box>

      <Transition in={shareOpen} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={() => {
              setShareOpen(false);
              // if (configurationId && !props.submitted) {
              //   window.location.reload();
              // }
            }}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
              height: "auto",
              maxHeight: "auto",
              overflow: "scroll",
            }}
          >
            <ModalDialog
              aria-labelledby="fade-modal-dialog-title"
              aria-describedby="fade-modal-dialog-description"
              sx={{
                height: "auto",
                maxHeight: "500vh",
                //marginY: innerWidth > 800 ? null : "150px",

                opacity: 0,
                transition: `opacity 300ms height`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <CloseIcon
                sx={{ float: "right", ml: "auto", cursor: "Pointer" }}
                onClick={() => setShareOpen(false)}
              />
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#25252d",
                }}
              >
                Konfiguration teilen
              </h2>

              <Typography variant={"body1"} sx={{ mt: 1, mb: 2 }}>
                Hier kannst Du den Link zu Deiner Konfiguration kopieren oder
                direkt teilen:
              </Typography>
              <div style={{ marginBottom: "15px" }}>
                <ClipboardCopy
                  parametrization={props.parametrization}
                  copyText={`${
                    props.parametrization.branding.customDomain
                      ? props.parametrization.branding.customDomain
                      : "https://ynflow.com"
                  }/experience/${id}/${configurationId}`}
                />
              </div>
              <RWebShare
                data={{
                  text: `Schau Dir meine ${props.parametrization.productName} Konfiguration an! Was hältst Du davon?`,

                  url: `${
                    props.parametrization.branding.customDomain
                      ? props.parametrization.branding.customDomain
                      : "https://ynflow.com"
                  }/experience/${id}/${configurationId}`,
                  title: "Teile jetzt Deine Konfiguration mit anderen.",
                }}
                onClick={() => console.info("share successful!")}
              >
                <Button
                  variant="contained"
                  sx={{
                    background: props.parametrization.branding.primaryColor.rgb,
                    color: "white",
                    width: "50%",
                    borderRadius: props.parametrization.branding.borderRadius
                      ? props.parametrization.branding.borderRadius
                      : "8px",
                    "&:hover": {
                      background:
                        props.parametrization.branding.primaryColor.rgb,
                    },
                  }}
                >
                  Teilen
                </Button>
              </RWebShare>
            </ModalDialog>
          </Modal>
        )}
      </Transition>

      <Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={() => {
              setOpen(false);
              props.setOpen(false);
              // if (configurationId && !props.submitted) {
              //   window.location.reload();
              // }
            }}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
              height: "auto",
              maxHeight: "auto",
              overflow: "scroll",
            }}
          >
            <ModalDialog
              aria-labelledby="fade-modal-dialog-title"
              aria-describedby="fade-modal-dialog-description"
              sx={{
                height: "auto",
                maxHeight: "500vh",
                //marginY: innerWidth > 800 ? null : "150px",

                opacity: 0,
                transition: `opacity 300ms height`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <CloseIcon
                sx={{ float: "right", ml: "auto", cursor: "Pointer" }}
                onClick={() => {
                  setOpen(false);
                  props.setOpen(false);
                }}
              />
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#25252d",
                }}
              >
                Konfiguration speichern
              </h2>

              <>
                {!configurationId && (
                  <>
                    <Typography
                      variant={"description"}
                      sx={{ display: "inline-block", marginBottom: "40px" }}
                    >
                      Zum Speichern benötigen wir Deine Email-Adresse
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight={700}
                      gutterBottom
                      sx={{ fontSize: "1.3rem" }}
                    >
                      Deine Email*
                    </Typography>
                    <Input
                      placeholder="max@mustermann.de"
                      variant="outlined"
                      size="medium"
                      name="email"
                      fullWidth
                      type="email"
                      // onBlur={() =>
                      //   fetchSignInMethodsForEmail(auth, formik.values.email).then(
                      //     (result) => {
                      //       console.log("result", result);
                      //       if (result.length === 0) {
                      //         console.log("Email not in use");
                      //         toggleEmailInUse(false);
                      //       } else {
                      //         console.log("Email in use");

                      //         toggleEmailInUse(true);
                      //       }
                      //     }
                      //   )
                      // }
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      // error={formik.touched.email && Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                      sx={{ padding: "6px" }}
                    />
                  </>
                )}
                {!configurationId && (
                  <Button
                    variant="contained"
                    // type="submit"
                    size="large"
                    onClick={() => {
                      props.parametrization.GTM_ids &&
                        props.parametrization.GTM_ids.map((id, key) => {
                          let dataLayer = window["dataLayer" + key] || [];
                          // console.log("pushing to", dataLayer);

                          dataLayer.push({
                            event: "yn_save",
                            configurator: props.parametrization.id,
                          });
                        });
                      toggleShowLoadingSpinner(true);
                      console.log(email, actionCodeSettings, auth);
                      // sendSignInLinkToEmail(
                      //   auth,
                      //   email,
                      //   actionCodeSettings
                      // ).then((data) => {
                      //window.localStorage.setItem("emailForSignIn", email);

                      addDoc(collection(db, "configurations"), {
                        submitted: false,
                        parametrizationId: id,
                        comment: "",
                        calculatedPrice: props.price,
                        email: email,
                        createdTimestamp: new Date().toISOString(),
                        lastUpdateTimestamp: new Date().toISOString(),
                        data: props.configuration.reduce(
                          (acc, cur) => ({
                            ...acc,
                            [cur.title]: cur.currentValue,
                          }),
                          {}
                        ),
                      }).then((docRef) => {
                        if (window.location.href.indexOf("embed") > -1) {
                          navigate(`/embed/${id}/${docRef.id}`);
                        } else {
                          navigate(`/experience/${id}/${docRef.id}`);
                        }
                        props.parametrization.savingWebhook &&
                          fetch(props.parametrization.savingWebhook, {
                            method: "POST",
                            body: JSON.stringify({
                              ...props.configuration,
                              ["configurationId"]: docRef.id,
                              ["email"]: email,
                              ...props.verboseConfiguration
                            }),
                            headers: {
                              "Content-type": "application/json; charset=UTF-8",
                            },
                          });
                        toggleShowLoadingSpinner(false);
                        props.toggleConfigDelta(false);
                        setOpen(false);
                        props.setOpen(false);

                        toast("Speichern erfolgreich! Prüfe Deine Emails 📩", {
                          //description: 'Monday, January 3rd at 6:00pm',
                          duration: 5000,
                          id: "initial-saving-successfull",
                          style: {
                            background: "rgba(30,180,30,0.92)",
                            color: "rgba(255,255,255,1)",
                            margin: innerWidth < 800 ? "0" : "auto",
                            zIndex: 500,
                            lineHeight: "1.2",
                            fontSize: innerWidth < 800 ? "0.6rem" : "0.76rem",
                            position: "relative",
                            padding: "8px 12px 8px 12px",
                            textAlign: "center",
                            border: 0,
                            width: innerWidth < 800 ? "auto" : "500px",
                            userSelect: "none",
                            display: "block",
                          },
                          className: "my-toast",
                          descriptionClassName: "my-toast-description",
                        });

                        if (props.parametrization.screenshotCam) {
                          const storage = getStorage();
                          let renderer = new THREE.WebGLRenderer({
                            antialias: true,
                            preserveDrawingBuffer: true,
                          });
                          renderer.setClearColor(0x000000, 0);
                          renderer.setSize(2400, 1600);
                          let camera = new THREE.PerspectiveCamera(
                            70,
                            800 / 500,
                            1,
                            1000
                          );
                          camera.position.z =
                            props.parametrization.screenshotCam.positionZ; //1.5;
                          camera.position.y =
                            props.parametrization.screenshotCam.positionY; //0.6;
                          camera.position.x =
                            props.parametrization.screenshotCam.positionX; //5.4;
                          camera.rotation.y =
                            props.parametrization.screenshotCam.rotationY; //1.3;
                          // camera.rotation.x = -0.2;
                          camera.setFocalLength(45);
                          // camera.zoom=5;
                          // should only be called when there really is a
                          try {
                            // save screenshot to firestore storage
                            console.log("saving screenshot!", props);

                            //var w = window.open('', '');
                            //w.document.title = "Screenshot";
                            //w.document.body.style.backgroundColor = "red";
                            var img = new Image();
                            // Without 'preserveDrawingBuffer' set to true, we must render now
                            // The call of render method causes memory leak :-(
                              renderer.toneMapping = THREE.ACESFilmicToneMapping;

                              let pathTracer = new WebGLPathTracer( renderer );
                              pathTracer.setScene( props.scene, camera );
                              
                            renderer.render(props.scene, camera);
                            img.src = renderer.domElement.toBlob(function (
                              blob
                            ) {
                              var image = new Image();
                              image.src = blob;
                              const storageRef = ref(storage, `${docRef.id}`);
                              uploadBytes(storageRef, blob)
                                .then((snapshot) => {
                                  return getDownloadURL(snapshot.ref);
                                })
                                .then((downloadURL) => {
                                  console.log("url: ", downloadURL);
                                  const docRef1 = setDoc(
                                    doc(db, "configurations", docRef.id),
                                    {
                                      img: downloadURL,
                                    },
                                    { merge: true }
                                  );
                                });
                              // renderer = null;
                              //     // renderer.domElement.remove();
                              //   // var uploadTask = storageRef.child('images/' + configuration.id).put(blob);
                            });
                            //w.document.body.appendChild(img);
                          } catch (e) {
                            console.log(e);
                          }
                        }
                      });

                      // });
                    }}
                    sx={{
                      background:
                        props.parametrization.branding.primaryColor.rgb,
                      padding: "20px",
                      marginTop: "30px",
                      color: "white",
                      fontWeight: "normal",
                      fontSize: "1rem",
                      maxWidth: "270px",
                      borderRadius: props.parametrization.branding.borderRadius
                        ? props.parametrization.branding.borderRadius
                        : "auto",
                    }}
                  >
                    Speichern
                  </Button>
                )}
                {configurationId && (
                  <>
                    <center>
                      <Button
                        sx={{
                          width: "250px",
                          background:
                            props.parametrization.branding.primaryColor.rgb,
                          padding: "10px",
                          marginTop: "30px",
                          color: "white",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          lineHeight: "1.4",
                          width: "270px",
                          "&:hover": {
                            background:
                              props.parametrization.branding.primaryColor.rgb,
                          },
                          borderRadius: props.parametrization.branding
                            .borderRadius
                            ? props.parametrization.branding.borderRadius
                            : "auto",
                        }}
                        onClick={() => {
                          const docRef = setDoc(
                            doc(db, "configurations", configurationId),
                            {
                              lastUpdateTimestamp: new Date().toISOString(),
                              calculatedPrice: props.price,
                              currentStep: 0, //props.currentStep,
                              data: props.configuration.reduce(
                                (acc, cur) => ({
                                  ...acc,
                                  [cur.title]:
                                    cur.currentValue != undefined
                                      ? cur.currentValue
                                      : "",
                                }),
                                {}
                              ),
                            },
                            { merge: true }
                          );
                          if (props.parametrization.screenshotCam) {
                            const storage = getStorage();
                            let renderer = new THREE.WebGLRenderer({
                              antialias: true,
                              preserveDrawingBuffer: true,
                            });
                            renderer.setClearColor(0x000000, 0);
                            renderer.setSize(1600, 900);
                            renderer.setPixelRatio(16/9)
                            let camera = new THREE.PerspectiveCamera(
                              70,
                              800 / 500,
                              1,
                              1000
                            );
                            camera.position.z =
                              props.parametrization.screenshotCam.positionZ; //1.5;
                            camera.position.y =
                              props.parametrization.screenshotCam.positionY; //0.6;
                            camera.position.x =
                              props.parametrization.screenshotCam.positionX; //5.4;
                            camera.rotation.y =
                              props.parametrization.screenshotCam.rotationY; //1.3;
                            // camera.rotation.x = -0.2;
                            camera.setFocalLength(45);
                            // camera.zoom=5;
                            // should only be called when there really is a
                            try {
                              // save screenshot to firestore storage
                              console.log("saving screenshot!", props);

                              //var w = window.open('', '');
                              //w.document.title = "Screenshot";
                              //w.document.body.style.backgroundColor = "red";
                              var img = new Image();
                              // Without 'preserveDrawingBuffer' set to true, we must render now
                              // The call of render method causes memory leak :-(
                                renderer.toneMapping = THREE.ACESFilmicToneMapping;
                                renderer.colorSpace = "srgb"
                                // let pathTracer = new WebGLPathTracer( renderer );
                                // pathTracer.setScene( props.scene, camera );
                                renderer.shadowMap.enabled = true;
                                renderer.shadowMap.type = THREE.PCFSoftShadowMap;
                              renderer.render(props.scene, camera);
                              img.src = renderer.domElement.toBlob(function (
                                blob
                              ) {
                                var image = new Image();
                                image.src = blob;
                                const storageRef = ref(
                                  storage,
                                  `${configurationId}`
                                );
                                uploadBytes(storageRef, blob)
                                  .then((snapshot) => {
                                    return getDownloadURL(snapshot.ref);
                                  })
                                  .then((downloadURL) => {
                                    console.log("url: ", downloadURL);
                                    const docRef1 = setDoc(
                                      doc(
                                        db,
                                        "configurations",
                                        configurationId
                                      ),
                                      {
                                        img: downloadURL,
                                      },
                                      { merge: true }
                                    );
                                  });
                                // renderer = null;
                                //     // renderer.domElement.remove();
                                //   // var uploadTask = storageRef.child('images/' + configuration.id).put(blob);
                              });
                              //w.document.body.appendChild(img);
                            } catch (e) {
                              console.log(e);
                            }
                          }
                          props.toggleConfigDelta(false);
                          setOpen(false);
                          props.setOpen(false);
                          toast("Speichern erfolgreich", {
                            //description: 'Monday, January 3rd at 6:00pm',
                            duration: 3000,
                            id: "saving-successfull",
                            style: {
                              background: "rgba(30,180,30,0.92)",
                              color: "rgba(255,255,255,1)",
                              margin: innerWidth < 800 ? "0" : "auto",
                              zIndex: 500,
                              lineHeight: "1.2",
                              fontSize: innerWidth < 800 ? "0.6rem" : "0.76rem",
                              position: "relative",
                              padding: "8px 12px 8px 12px",
                              textAlign: "center",
                              border: 0,
                              width: innerWidth < 800 ? "auto" : "400px",
                              userSelect: "none",
                              display: "block",
                            },
                            className: "my-toast",
                            descriptionClassName: "my-toast-description",
                          });
                        }}
                      >
                        Speichern
                      </Button>
                      <br />
                      <small style={{ mt: "10px", fontSize: "11px" }}>
                        Der aktuelle Stand wird dadurch überschrieben.
                      </small>
                      <p style={{ textAlign: "center" }}>oder</p>
                      <a
                        onClick={() => {
                          props.parametrization.GTM_ids &&
                            props.parametrization.GTM_ids.map((id, key) => {
                              let dataLayer = window["dataLayer" + key] || [];
                              // console.log("pushing to", dataLayer);

                              dataLayer.push({
                                event: "yn_duplicate",
                                configurator: props.parametrization.id,
                              });
                            });
                          toggleShowLoadingSpinner(true);
                          console.log(email, actionCodeSettings, auth);
                          // sendSignInLinkToEmail(
                          //   auth,
                          //   email,
                          //   actionCodeSettings
                          // ).then((data) => {
                          //window.localStorage.setItem("emailForSignIn", email);

                          addDoc(collection(db, "configurations"), {
                            submitted: false,
                            parametrizationId: id,
                            comment: "",
                            createdTimestamp: new Date().toISOString(),
                            lastUpdateTimestamp: new Date().toISOString(),
                            data: props.configuration.reduce(
                              (acc, cur) => ({
                                ...acc,
                                [cur.title]: cur.currentValue,
                              }),
                              {}
                            ),
                          }).then((docRef) => {
                            if (window.location.href.indexOf("embed") > -1) {
                              //navigate(`/embed/${id}/${docRef.id}`);
                              window.open(`/predef/${id}/${docRef.id}`);
                            } else {
                              //navigate(`/experience/${id}/${docRef.id}`);
                              window.open(`/predef/${id}/${docRef.id}`);
                            }
                            if (props.parametrization.screenshotCam) {
                              const storage = getStorage();
                              let renderer = new THREE.WebGLRenderer({
                                antialias: true,
                                preserveDrawingBuffer: true,
                              });
                              renderer.setClearColor(0x000000, 0);
                              renderer.setSize(1600, 900);
                              let camera = new THREE.PerspectiveCamera(
                                70,
                                800 / 500,
                                1,
                                1000
                              );
                              camera.position.z =
                                props.parametrization.screenshotCam.positionZ; //1.5;
                              camera.position.y =
                                props.parametrization.screenshotCam.positionY; //0.6;
                              camera.position.x =
                                props.parametrization.screenshotCam.positionX; //5.4;
                              camera.rotation.y =
                                props.parametrization.screenshotCam.rotationY; //1.3;
                              // camera.rotation.x = -0.2;
                              camera.setFocalLength(45);
                              // camera.zoom=5;
                              // should only be called when there really is a
                              try {
                                // save screenshot to firestore storage
                                console.log("saving screenshot!", props);

                                //var w = window.open('', '');
                                //w.document.title = "Screenshot";
                                //w.document.body.style.backgroundColor = "red";
                                var img = new Image();
                                // Without 'preserveDrawingBuffer' set to true, we must render now
                                // The call of render method causes memory leak :-(
                                renderer.render(props.scene, camera);
                                img.src = renderer.domElement.toBlob(function (
                                  blob
                                ) {
                                  var image = new Image();
                                  image.src = blob;
                                  const storageRef = ref(
                                    storage,
                                    `${docRef.id}`
                                  );
                                  uploadBytes(storageRef, blob)
                                    .then((snapshot) => {
                                      return getDownloadURL(snapshot.ref);
                                    })
                                    .then((downloadURL) => {
                                      console.log("url: ", downloadURL);
                                      const docRef1 = setDoc(
                                        doc(db, "configurations", docRef.id),
                                        {
                                          img: downloadURL,
                                        },
                                        { merge: true }
                                      );
                                    });
                                  // renderer = null;
                                  //     // renderer.domElement.remove();
                                  //   // var uploadTask = storageRef.child('images/' + configuration.id).put(blob);
                                });
                                //w.document.body.appendChild(img);
                              } catch (e) {
                                console.log(e);
                              }
                            }
                            toggleShowLoadingSpinner(false);
                            props.toggleConfigDelta(false);
                            setOpen(false);
                            props.setOpen(false);
                            toast("Duplizieren war erfolgreich!", {
                              //description: 'Monday, January 3rd at 6:00pm',
                              duration: 3000,
                              id: "saving-successfull",
                              style: {
                                background: "rgba(30,180,30,0.92)",
                                color: "rgba(255,255,255,1)",
                                margin: innerWidth < 800 ? "0" : "auto",
                                zIndex: 500,
                                lineHeight: "1.2",
                                fontSize:
                                  innerWidth < 800 ? "0.6rem" : "0.76rem",
                                position: "relative",
                                padding: "8px 12px 8px 12px",
                                textAlign: "center",
                                border: 0,
                                width: innerWidth < 800 ? "auto" : "300px",
                                userSelect: "none",
                                display: "block",
                              },
                              className: "my-toast",
                              descriptionClassName: "my-toast-description",
                            });
                          });

                          // });
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color:
                            props.parametrization.branding.primaryColor.rgb,
                        }}
                      >
                        Konfiguration duplizieren
                      </a>
                    </center>
                  </>
                )}
              </>
              {/*               
                 <>
                   <Typography variant={"body1"} sx={{ mt: 1 }}>
                    Ihre Konfiguration wurde gespeichert. Sie können diese nun
                    mit dem individuellen Link jederzeit aufrufen.
              
                   </Typography>
                  <Typography variant={"body1"} sx={{ mt: 1, mb: 2 }}>
                     Um sie zu teilen, klicken Sie auf den folgenden Link:
                   </Typography>
                   <div style={{marginBottom: "15px"}}>
                  <ClipboardCopy copyText={`${
                        props.parametrization.branding.customDomain
                          ? props.parametrization.branding.customDomain
                          : "https://ynflow.com"
                      }/experience/${id}/${configurationId}`}/>
                      </div>
                  <RWebShare
                  
                    data={{
                      text: `Schau Dir meine ${props.parametrization.productName} Konfiguration an! Was hältst Du davon?`,
                      
                      url: `${
                        props.parametrization.branding.customDomain
                          ? props.parametrization.branding.customDomain
                          : "https://ynflow.com"
                      }/experience/${id}/${configurationId}`,
                      title: "Teile jetzt Deine Konfiguration mit anderen.",
                    }}
                    onClick={() => console.info("share successful!")}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        background:
                          props.parametrization.branding.primaryColor.rgb,
                        color: "white",
                        width: "50%",
                      }}
                    >
                      Teilen
                    </Button>
                  </RWebShare>
                </> */}
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </React.Fragment>
  );
}

export const useTabActive = () => {
  const [visibilityState, setVisibilityState] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return visibilityState;
};

const functions = getFunctions();
// const trackIp = httpsCallable(functions, "trackIp");

export const MenuSlider = (props) => {
  const [configuration, setConfiguration] = useState(props.configuration);
  const { id, configurationId, predefId } = useParams();
  const [openShareModal, toggleOpenShareModal] = useState(false);
  const [submitted, toggleSubmitted] = useState(false);
  const [largeMenu, toggleLargeMenu] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(false);
  const isTabActive = useTabActive();
  //const [predefinedLoaded, togglePredifinedLoaded] = useState(false);
  const [infoModalText, setInfoModalText] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [stateLoaded, setStateLoaded] = useState(false);
  const [lastNotificationId, setLastNotificationId] = useState(false);
  const [stateDependencies, setStateDependencies] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [to, setTo] = useState(0);
  const { isOpen, setIsOpen } = useTour();
  const [sliderRef, setSliderRef] = useState();
  const [deliveryCosts, setDeliveryCosts] = useState(0);
  //const [cookies, setCookie] = useCookies(["tourOffered"]);
  const [price, setPrice] = useState(0);
  const [index, setIndex] = useState(-1);
  const [currentImages, setCurrentImages] = useState([]);
  const [glbDownloadUrl, setGlbDownloadUrl] = useState(false);
  const innerWidth = useWindowSize();
  const [configDelta, toggleConfigDelta] = useState(false);
  const [open, setOpen] = useState(false);
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const [verboseConfiguration, setVerboseConfiguration] = useState(null);
 
  // useEffect(()=>{
  //   stateLoaded && configurationId && setConfiguration([...configuration])
  // },[stateLoaded])
  const [user, loadingUser, errorUser] = useAuthState(auth);

  //console.log("GOT A CONFIGURATION FROM PREDEF:", props.configuration);
  useEffect(() => {
    if (props.configuration && predefId) {
      setConfiguration(props.configuration);
    }
    if (stateLoaded) {
      if (configurationId && storedConfig) {
        if (
          !isEqual(
            storedConfig.data,
            props.configuration.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.title]: cur.currentValue,
              }),
              {}
            )
          )
        ) {
          toggleConfigDelta(true);
        } else {
          toggleConfigDelta(false);
        }
      } else {
        toggleConfigDelta(true);
      }
    }

    let technicalToVerboseValueConversion;

    if (
      props.parametrization &&
      !technicalToVerboseValueConversion &&
      props.configuration
    ) {
      technicalToVerboseValueConversion = {};
      for (let field of props.parametrization.menuConfiguration) {
        // grab options
        if (field.options) {
          for (let option of field.options) {
            technicalToVerboseValueConversion[option.title] =
              option.verboseTitle ? option.verboseTitle : option.title;
          }
        }
        // grab choices
        if (field.choices) {
          for (let choice of field.choices) {
            if (field.inputType == "color") {
              if (choice.rgb) {
                technicalToVerboseValueConversion[choice.rgb] = choice.title;
              }
              if (choice.material) {
                technicalToVerboseValueConversion[choice.material] =
                  choice.title;
                // technicalToVerboseValueConversion[choice.title] =
                // choice.verboseTitle;
              }
            } else {
              technicalToVerboseValueConversion[choice.title] =
                choice.verboseTitle ? choice.verboseTitle : choice.title;
            }
          }
        }
        // sub choices
        if (field.subchoices) {
          for (let subchoice of field.subchoices) {
            technicalToVerboseValueConversion[subchoice.title] =
              subchoice.verboseTitle;
          }
        }

        //props.parametrization &&
          // console.log(
          //   "this is the conversion table:",
          //   technicalToVerboseValueConversion
          // );
      }

      console.log(
        "DATA IS THIS",
        props.configuration,
        technicalToVerboseValueConversion
      );
      let data = {};
      for (let t of props.configuration) {
        if (Array.isArray(t.currentValue)) {
          data[t.title] = t.currentValue.map((k) => {
            return technicalToVerboseValueConversion[k]
              ? technicalToVerboseValueConversion[k]
              : k;
          });
        } else {
          data[t.title] = technicalToVerboseValueConversion[t.currentValue]
            ? technicalToVerboseValueConversion[t.currentValue]
            : t.currentValue;
        }
      }

      // console.log("VERBOSE:",(props.parametrization["menuConfiguration"][0]
      //   .numOrder != null
      //   ? props.parametrization["menuConfiguration"]
      //       .filter((i) => !i.hide && !(i.inputType == "submit")&& !(i.inputType == "demo"))
      //       .sort((a, b) => a.numOrder - b.numOrder)
      //   : props.parametrization[
      //       "menuConfiguration"
      //     ].filter((i) => !i.hide)
      // ).map(t =>
      //   {
      //     return {  [t.title]: data[t.title] };
      //   }

      // ), configuration )

      

      let data_v = {};
      for (let k of props.parametrization["menuConfiguration"][0].numOrder !=
      null
        ? props.parametrization["menuConfiguration"]
            .filter(
              (i) =>
                !i.hide &&
                !(i.inputType == "submit") &&
                !(i.inputType == "demo")
            )
            .sort((a, b) => a.numOrder - b.numOrder)
        : props.parametrization["menuConfiguration"].filter((i) => !i.hide)) {
        data_v[k.title] = data[k.title];
        // {
        //   return {  [t.title]: data[t.title] };
        // }
      }
      let data_k = {}
      for (let k of Object.keys(data_v)){
        if(!props.parametrization.menuConfiguration.filter(i => i.title==k)[0].hideFromOverview){
          data_k[k] = Array.isArray(data_v[k])   ? (
          
            data_v[k].map((v, i) => (
              i < data_v[k].length - 1 ? (
  
                  v && (v != "placeholder") ? v + ", " : 
                    v
                  
                ) : (
                   typeof data_v[k] === 'object' && !Array.isArray(data_v[k])?
                  Object.keys(data_v[k]).map(t => t).join("")
                  :(v != "placeholder") && v
                )
            )).join("")
          ) : (
            data_v[k] instanceof Object && data_v[k] != null?
           Object.keys(data_v[k]).map((key,index )=> data_v[k][key]+ " x "+key+ (index < Object.keys(data_v[k]).length - 1? ", ":"")).join(""):
            data_v[k]
          )
        }
      }
      

      //console.log("TRANSAIOTN", data_k)
      
      setVerboseConfiguration(data_k);

      // let usr = { ...users.filter((u) => u.id == i.userId)[0] };
      // delete usr["id"];
      // modify to use verboseNames where possible
    }
  }, [props.configuration]);
  useEffect(() => {
    try {
      window.setTimeout(function () {
        let d = document.getElementById("defaultNext");
        d.id = "next-button";
      }, 10000);
    } catch (e) {
      console.log(e);
    }
  }, []);
  // useEffect(()=>{
  //   if(props.modelLoaded && props.configuration && predefId){
  //     console.log("setting always visible", props.configuration);
  //     for (let mesh of props.parametrization.allwaysVisible) {
  //       try {
  //         let v = props.scene.getObjectByName(mesh);
  //         if (
  //           !props.parametrization.childrenAllwaysVisible ||
  //           !props.parametrization.childrenAllwaysVisible.includes(mesh)
  //         ) {
  //           v.visible = true;
  //         }
  //         for (let c of v.children) {
  //           c.visible = true;
  //         }
  //       } catch (e) {
  //         console.log("setting always visible failed", mesh, e);
  //       }
  //     }
  //   }
  // },[props.modelLoaded, predefId, props.configuration])
  const MenuArrowForward =
    props.parametrization.id == "ALix3WF4dSsrubYu2NBH"
      ? KeyboardArrowRightOutlinedIcon
      : ArrowForwardIcon;
  const MenuArrowBackward =
    props.parametrization.id == "ALix3WF4dSsrubYu2NBH"
      ? KeyboardArrowLeftOutlinedIcon
      : ArrowBackIcon;

  useEffect(() => {
    !configurationId &&
      props.parametrization.id == "2NSNN8FBJxlNUq98Liix" &&
      innerWidth > 800 &&
      props.modelLoaded &&
      setIsOpen(true);
  }, [props.modelLoaded]);
  useEffect(() => {
    updateTo(
      configuration.reduce((acc, cur) => {
        // console.log("summing on ", configuration, cur);
        if (
          cur.options &&
          cur.options.filter((o) => o.title == cur.currentValue).length > 0
        ) {
          let price = cur.options.filter((o) => o.title == cur.currentValue)[0]
            .price;
          if (price) {
            // console.log("adding", price);
            return acc + price;
          } else {
            return acc;
          }
        }
        if (
          cur.choices &&
          cur.choices.filter((o) => o.rgb == cur.currentValue).length > 0
        ) {
          let price = cur.choices.filter((o) => o.rgb == cur.currentValue)[0]
            .price;
          if (price) {
            // console.log("adding", price);
            return acc + price;
          } else {
            return acc;
          }
        }
        return acc;
      }, 0)
    );
    let newprice = (props.parametrization.basePrice ? props.parametrization.basePrice : 0);
    if(!(props.parametrization.id == "ALix3WF4dSsrubYu2NBH")){
    newprice =
    (props.parametrization.basePrice ? props.parametrization.basePrice : 0) +
      deliveryCosts +
      configuration.reduce((acc, cur) => {
        // console.log("summing on ", configuration, cur);
        try {
          if (
            cur.options &&
            cur.options.filter((o) => o.title == cur.currentValue).length > 0
          ) {
            let price = cur.options.filter(
              (o) => o.title == cur.currentValue
            )[0].price;
            console.log(
              "PRICING",
              cur.options.filter((o) => o.title == cur.currentValue)[0],
              price
            );

            // if (cur.currentValue[2]) {
            //   console.log("there is a subvalue ", cur.currentValue[2]);
            //   price += cur.subchoices.filter(
            //     (c) =>
            //       cur.currentValue[2] == c.rgb ||
            //       cur.currentValue[2] == c.material
            //   )[0].price;
            // }
            if (price) {
              console.log("adding", price);
              return acc + price;
            } else {
              return acc;
            }
          }
        } catch (e) {
          console.log("problem adding price", e);
        }
        // layeredSelect: Sum prices over choices and subchoices
        if (
          cur.inputType == "layeredSelect" &&
          cur.choices &&
          cur.subchoices &&
          Array.isArray(cur.currentValue) &&
          cur.currentValue.length > 0
        ) {
          console.log("there is a subvalue ", cur.currentValue[2]);
          let price = cur.options.filter((c) =>
            cur.currentValue.includes(c.title)
          )[0].price;
          console.log("top layer price is  ", price);

          if (cur.currentValue[2]) {
            console.log("there is a subvalue ", cur.currentValue[2]);
            price += cur.subchoices.filter(
              (c) =>
                cur.currentValue.includes(c.rgb) ||
                cur.currentValue.includes(c.material)
            )[0].price;
          }
          return acc + price;
        }

        if (
          !cur.inputType == "extendableSelect" &&
          !cur.inputType == "layeredSelect" &&
          !cur.inputType == "color" &&
          cur.choices &&
          cur.choices.filter((o) => o.rgb == cur.currentValue).length > 0
        ) {
          let price = cur.choices.filter((o) => o.rgb == cur.currentValue)[0]
            .price;
          if (price) {
            console.log("adding", price);
            return acc + price;
          } else {
            return acc;
          }
        }
        if (cur.inputType == "extendableSelect" && cur.choices) {
          console.log("field is extendable", cur.choices, cur.currentValue);
          try {
            let price = 0;
            if (cur.segmentTitles.length > 0 && cur.segmentPrices.length > 0) {
              let increment =
                cur.segmentPrices[cur.currentValue.filter((i) => i).length - 1]
                  .price;
              if (increment) {
                price += increment;
              }
            }
            // remove elements with span = 2
            for (let v of cur.currentValue.map((arrayValue) => {
              if (!arrayValue) {
                return 0;
              } else if (
                cur.choices.filter((choice) => choice.title == arrayValue)
                  .length > 0
              ) {
                return cur.choices.filter(
                  (choice) => choice.title == arrayValue
                )[0].price;
                // console.log
              } else if (
                Array.isArray(cur.choices) &&
                !(arrayValue.includes("_left") || arrayValue.includes("2er"))
              ) {
                return 0;
              } else if (
                Array.isArray(cur.choices) &&
                arrayValue.includes("_2")
              ) {
                return cur.choices.filter((choice) =>
                  choice.title.includes(arrayValue)
                )[0].price;
              }
            })) {
              // console.log("v is ", v);
              price += v;
            }
            if (price || price == 0) {
              console.log("adding", price);
              return acc + price;
            } else {
              return acc;
            }
          } catch (e) {
            // .filter((item,
            //   index) => arr.indexOf(item) === index)){
            //   v = v.split("_")[]
            console.log(e);
          }
        }
        if (
          cur.inputType == "color" &&
          cur.choices &&
          cur.choices.filter((o) => o.rgb == cur.currentValue).length > 0
        ) {
          let price = cur.choices.filter((o) => o.rgb == cur.currentValue)[0]
            .price;
          if (price) {
            console.log("adding", price);
            return acc + price;
          } else {
            return acc;
          }
        }
        return acc;
      }, 0);
    }else{
      let sizePrices = {
        "237": {
          "260": 23147.88,
          "310": 25363.66,
          "360": 27579.44,
          "410": 29795.22,
          "460": 32011,
          "510": 34226.78,
          "560": 36442.56,
          "610": 38658.34
        },
        "337": {
          "260": 27786.50,
          "310": 30472.33,
          "360": 33158.16,
          "410": 35843.99,
          "460": 38529.82,
          "510": 41216.84,
          "560": 43902.67,
          "610": 46588.50
        },
        "387": {
          "260": 30105.81,
          "310": 33026.07,
          "360": 35947.52,
          "410": 38868.97,
          "460": 41790.42,
          "510": 44710.68,
          "560": 47632.13,
          "610": 50553.58
        }
      }
      console.log("CONFIG", configuration)
      newprice += sizePrices[configuration.filter(i => i.title == "Breite")[0].currentValue][configuration.filter(i => i.title == "Tiefe")[0].currentValue]
      // right side
      let right = configuration.filter(i => i.title == "Fenster rechts")[0].currentValue
      if(right.includes("Rundfenster_90")){
        newprice += 1312.57
      }
      if(right.includes("Rundfenster_130")){
        newprice += 1517.25
      }
      if(right.includes("_Eckfenster")){
        if(right.includes("Eckfenster_Eckfenster")){
          newprice += 2*888.93
        }else{
        newprice += 888.93
        }
      }
      //left side
      let left = configuration.filter(i => i.title == "Fenster links")[0].currentValue
      if(left.includes("Rundfenster_90")){
        newprice += 1312.57
      }
      if(left.includes("Rundfenster_130")){
        newprice += 1517.25
      }
      if(left.includes("_Eckfenster")){
        if(left.includes("Eckfenster_Eckfenster")){
          newprice += 2*888.93
        }else{
        newprice += 888.93
        }
      }
       //back side
       let back = configuration.filter(i => i.title == "Fenster hinten")[0].currentValue
      
       if(back.includes("_Eckfenster")){
        
         newprice += 888.93
         
       }

       // Heating
       let interior = configuration.filter(i => i.title == "Innenraum")[0].currentValue
       if(interior.includes("Steinheizung 400W")){
        newprice += 671.16
       }
       if(interior.includes("Steinheizung 600W")){
        newprice += 715.19
       }
       if(interior.includes("Steinheizung 800W")){
        newprice += 768.74
       }
       if(interior.includes("Steinheizung 1200W")){
        newprice += 835.38
       }
       if(interior.includes("Fußbodenheizung")){
        let floorheatingPrices = {
          "237": {
            "260": 393.89,
            "310": 476,
            "360": 558.11,
            "410": 639.03,
            "460": 721.14,
            "510": 803.25,
            "560": 775.88,
            "610": 847.28
          },
          "337": {
            "260": 600.95,
            "310": 725.90,
            "360": 850.85,
            "410": 855.61,
            "460": 965.09,
            "510": 1074.57,
            "560": 1184.05,
            "610": 1294.72,
          },
          "387": {
            "260": 704.48,
            "310": 850.85,
            "360": 874.65,
            "410": 1003.17,
            "460": 1131.69,
            "510": 1260.21,
            "560": 1388.73,
            "610": 1517.25
          }
        }
        newprice += floorheatingPrices[configuration.filter(i => i.title == "Breite")[0].currentValue][configuration.filter(i => i.title == "Tiefe")[0].currentValue]
       }

       let terrace = configuration.filter(i => i.title == "Holzterrasse")[0].currentValue
       let terracePrices = {
        "Terasse_0": {
          "237": 0,
          "337": 0,
          "387": 0,
         
        },
        "Terasse_50": {
          "237": 364.14,
          "337": 516.46,
          "387": 593.81,
         
        },
        "Terasse_100": {
         "237": 728.28,
          "337": 1034.11,
          "387": 1187.62,
        },
        "Terasse_150": {
          "237": 1092.42,
          "337": 1389.92,
          "387": 1594.60,
        },
        "Terasse_200": {
          "237": 1457.75,
          "337": 1852.83,
          "387": 2126.53,
        }
      }
       
        newprice += terracePrices[terrace][configuration.filter(i => i.title == "Breite")[0].currentValue]
       
      
    }
    setPrice(newprice);
    // setTimeout(()=>{
    // if (configurationId && props.modelLoaded) {
    //   setDoc(
    //     doc(db, "configurations", configurationId),
    //     {
    //       calculatedPrice: newprice,
    //     },
    //     { merge: true }
    //   );
    // }

    // },1000)
  }, [configuration]);

  const updateTo = useCallback(
    debounce((v) => {
      setTo(v);
    }, 100),
    []
  );
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 10;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      toggleLargeMenu(true);
      props.toggleLargeMenu(!true);
    }
    // add your conditional logic here
  };
  let glbFileName = "";
  if (id == "CTj8E6bpuY0KwNBH49w8") {
    glbFileName = "/Coffeestand.glb";
  } else if (id == "kzGiEoS5bKtyeLGiHDme") {
    glbFileName = "/ValvesComplete.glb";
  } else if (id == "DecTsHiSM84qDG6vAekP") {
    glbFileName = "/pressure_gauge (1).glb";
  } else if (id == "g9ZogO6sKI9UJXJEt9iR") {
    glbFileName = "/Drehkreuz_Ecco_Alle_20.glb";
  } else if (id == "7XTAkAwxcZyELu2em0NJ") {
    glbFileName = "/tiny_house_-_prefab_steel_framing.glb";
  } else if (id == "2NSNN8FBJxlNUq98Liix") {
    glbFileName =
      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Cube.glb?alt=media&token=ba22e2ed-a95a-42cb-9345-c41d67424434&_gl=1*5drolx*_ga*MTMwODY2MDE0NS4xNjk3NjUxOTUx*_ga_CW55HF8NVT*MTY5ODA0MzU5MC4zLjEuMTY5ODA0NDEyMy41NS4wLjA.";
  } else if (id == "rPbMh5d6IRHwVBTjGa1L") {
    glbFileName = "/a_circular_saw.glb";
  } else if (id == "vPM6pC95KgfgIJ7Vu0kV") {
    glbFileName = "/house_no05.glb";
  } else if (id == "ZcbfRTpQy2amMJmSPcQn") {
    glbFileName = "/peugeot_3008.glb";
  }else if (id == "lcZSpOekBUVYbTkSDcvf") {
    glbFileName = "/Raptor.glb";
  }  if (id == "xhnglL5ZUs6mxoYv3eMI") {
    glbFileName = "/walkingboxes.glb";
  } else if (id == "RjC8Ss0qnMrIjseH0VSp") {
    glbFileName =
      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild.glb?alt=media&token=abf70b88-66c6-4f40-9974-3998b97f9e12";
  } else if (id == "ALix3WF4dSsrubYu2NBH") {
    glbFileName = "/gartenkubus.glb";
  } else {
    console.log(
      "There is no glb file registered in MenuSlider Component... Please enter it there!"
    );
  }
  let navigate = useNavigate();
  const { nodes, materials } = useGLTF(glbFileName);
  const [configId, setConfigId] = useState(configurationId);
  const [initialConfigurationLoaded, setInitialConfigurationLoaded] =
    useState(false);
  const [storedConfig, loading, error, snapshot, reload] = useDocumentData(
    configurationId //&& !predefinedLoaded
      ? doc(getFirestore(app), "configurations", configurationId)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    setConfigId(configurationId);

    console.log("Received config Id");
  }, [configurationId]);

  useEffect(() => {
    console.log("GOT NEW STORED CONFIG", storedConfig, configurationId);
  }, [storedConfig, configurationId]);

  // useEffect(()=>{
  //   if(storedConfig && storedConfig.predefined && props.modelLoaded){
  //     navigate("/experience/" + id);

  //       togglePredifinedLoaded(true);

  //   }
  // },[storedConfig])

  // useEffect(()=>{
  //   if(props.parametrization){
  //     let currentFieldTitle = props.parametrization.menuConfiguration[currentStep].title
  //     for (let notification of props.parametrization.notifications){
  //       console.log("notification ist ", notification)
  //       try{

  //       if(notification.showIfSelected.filter(i => currentFieldTitle == i.title)[0].values.includes(configuration[currentFieldTitle])

  //         ){}
  //       }catch(e){
  //     console.log(e)
  //     }
  //     }

  // }
  // },[configuration])

  useEffect(() => {
    console.log("materials are...", materials);
  }, [materials]);

  const debouncedConfiguraton = useDebounce(storedConfig, 500);
  const [fieldMenuVisibleIndex, setFieldMenuVisibleIndex] = useState(0);
  useEffect(() => {
    // using layers now instead of visibility!
    try {
      props.scene &&
        props.scene.traverse((obj) => {
          let v = obj.visible;

          let o = props.scene.getObjectByName(obj.name);

          if (v) {
            o.visible = true;
            o.layers.disable(1);
            o.layers.enable(0);
          } else {
            o.visible = false;
            o.layers.disable(0);
            o.layers.enable(1);
          }
        });
    } catch (e) {
      console.log("Problem setting initial visibility!", e);
    }
  }, [props.scene]);
  useEffect(() => {
    //console.log("now visible: ", fieldMenuVisibleIndex);
    if (props.parametrization && fieldMenuVisibleIndex >= 0) {
      //console.log("setting viewpoint for visible field", configuration);
      // console.log(JSON.stringify(configuration));

      props.setCurrentView(
        (props.parametrization["menuConfiguration"][0].numOrder != null
          ? props.parametrization["menuConfiguration"]
              .filter((i) => !i.hide)
              .sort((a, b) => a.numOrder - b.numOrder)
          : props.parametrization["menuConfiguration"].filter((i) => !i.hide))[
          fieldMenuVisibleIndex
        ].viewpoint
      );
    }
  }, [fieldMenuVisibleIndex]);
  // Maybe important
  // useEffect(() => {
  //   if (storedConfig) {
  //     toggleSubmitted(storedConfig.submitted);
  //     sliderRef && sliderRef.slickGoTo(storedConfig["currentStep"]);
  //     controlSliderRef.current &&
  //       controlSliderRef.current.slickGoTo(storedConfig["currentStep"]);
  //   }
  // }, [storedConfig]);

  useEffect(() => {
    !largeMenu &&
      controlSliderRef.current &&
      controlSliderRef.current.slickGoTo(currentStep);
  }, [largeMenu]);

  useEffect(() => {
    fetch("https://us-central1-yellow-web-6310c.cloudfunctions.net/trackIP");
  }, []);

  // const sliderRef = useRef();
  const controlSliderRef = useRef();
  useEffect(() => {
    console.log("receiving config!", storedConfig ? storedConfig : "");
    // storedConfig &&
    //   console.log(
    //     storedConfig.data,
    //     configuration.reduce(
    //       (acc, cur) => ({
    //         ...acc,
    //         [cur.title]: cur.currentValue,
    //       }),
    //       {}
    //     ),
    //     isEqual(
    //       storedConfig.data,
    //       props.configuration.reduce(
    //         (acc, cur) => ({
    //           ...acc,
    //           [cur.title]: cur.currentValue,
    //         }),
    //         {}
    //       )
    //     )
    //   );
    if (
      // isTabActive &&
      storedConfig &&
      storedConfig.data &&
      !loading &&
      !isEqual(
        storedConfig.data,
        configuration.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.title]: cur.currentValue,
          }),
          {}
        )
      )
    ) {
      console.log(
        "receiving remote config!",
        storedConfig.data,
        configuration.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.title]: cur.currentValue,
          }),
          {}
        )
      );
      if (!initialConfigurationLoaded) {
        setConfiguration(
          configuration.map((i) => {
            return { ...i, ["currentValue"]: storedConfig.data[i.title] };
          })
        );
      }

      setInitialConfigurationLoaded(true);
      // für alle singleselect-felder mit currentValue als newValue einsetzen...
    }
    // MAYBE IMPORTANT
    // if (storedConfig && currentStep != storedConfig["currentStep"]) {
    //   setCurrentStep(
    //     storedConfig["currentStep"] ? storedConfig["currentStep"] : 0
    //   );
    //   props.setCurrentStep(
    //     storedConfig["currentStep"] ? storedConfig["currentStep"] : 0
    //   );
    // }
  }, [storedConfig]);
  useEffect(() => {
    props.parametrization &&
      setInfoModalText(
        (props.parametrization["menuConfiguration"][0].numOrder != null
          ? props.parametrization["menuConfiguration"]
              .filter((i) => !i.hide && i.title != "Fertig" && i.info)
              .sort((a, b) => a.numOrder - b.numOrder)
          : props.parametrization["menuConfiguration"].filter((i) => !i.hide)
        ).map((field, key) => {
          return (
            <div
              style={{
                lineHeight: "1.2rem",
                fontFamily: "'Montserrat',sans-serif",
              }}
            >
              <br />
              <br />
              <h2
                id={field.title}
                style={{
                  fontWeight: 400,
                  fontSize: "35px",
                  margin: "7px",
                  marginLeft: 0,
                  paddingTop: "45px",
                  marginTop: "-45px",
                }}
              >
                {field.title}
              </h2>
              <p
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{ __html: field.info }}
              ></p>
              {field.infoImages && (
                <>
                  <div style={{ paddingBottom: "20px" }}>
                    <PhotoAlbum
                      rowConstraints={{ singleRowMaxHeight: 120 }}
                      onClick={({ index: current }) => {
                        setCurrentImages(
                          field.infoImages.map((i) => {
                            return {
                              ...i,
                              width: i.width ? i.width : 1400,
                              height: i.height ? i.height : 1000,
                            };
                          })
                        );
                        setIndex(current);
                        console.log("opening lightbox");
                      }}
                      layout={"rows"}
                      targetRowHeight={100}
                      photos={field.infoImages.map((i) => {
                        return {
                          ...i,
                          height: i.height ? i.height : 100 * 0.6,
                          width: i.width ? i.width : 140 * 0.6,
                        };
                      })}
                    />{" "}
                  </div>
                </>
              )}
              {(field.choices
                ? field.choices
                : field.options
                ? field.options
                : []
              ).map((c) => (
                <>
                  <h4
                    style={{
                      fontWeight: 600,
                      marginLeft: 0,
                      fontSize: "21px",
                      margin: "4px",
                      marginLeft: 0,
                    }}
                    id={c.title + "-" + field.title}
                  >
                    {(c.info || c.infoImages) &&
                      (c.verboseTitle ? c.verboseTitle : c.title)}
                  </h4>
                  {c.info && (
                    <p dangerouslySetInnerHTML={{ __html: c.info }}></p>
                  )}
                  {c.infoImages && (
                    <div style={{ paddingBottom: "20px" }}>
                      <PhotoAlbum
                        rowConstraints={{ singleRowMaxHeight: 90 }}
                        onClick={({ index: current }) => {
                          setCurrentImages(
                            c.infoImages.map((i) => {
                              return {
                                ...i,
                                width: i.width ? i.width : 1400,
                                height: i.height ? i.height : 1000,
                              };
                            })
                          );
                          setIndex(current);
                          console.log("opening lightbox");
                        }}
                        layout={"rows"}
                        targetRowHeight={100}
                        photos={c.infoImages.map((i) => {
                          return {
                            ...i,
                            height: i.height ? i.height : 100 * 0.6,
                            width: i.width ? i.width : 140 * 0.6,
                          };
                        })}
                      />{" "}
                    </div>
                  )}
                </>
              ))}
            </div>
          );
        })
      );
  }, [props.parametrization]);

  // useEffect(()=>{
  //   if(props.parametrization["menuConfiguration"] && props.scene){
  //     for (let demoField of props.parametrization["menuConfiguration"].filter(i => i.inputType == "demo")){
  //       const spriteMaterial = new THREE.SpriteMaterial({
  //         map: new THREE.CanvasTexture(<div style={{background: "red"}}>INGO</div>),
  //         alphaTest: 0.5,
  //         transparent: true,
  //         depthTest: false,
  //         depthWrite: false
  //     });
  //       let sprite = new THREE.Sprite(spriteMaterial);
  //       sprite.position.set(-5, 0, 0);
  //       sprite.scale.set(60, 60, 1);

  //       props.scene.add(sprite);

  //     }
  //   }
  // },[props.parametrization["menuConfiguration"]])

  useEffect(() => {
    if (
      id == "2NSNN8FBJxlNUq98Liix" &&
      (currentStep == 7 || currentStep == 8)
    ) {
      // innenansicht edelstahl unlackiert
      setTimeout(
        () => (materials["GRAY25"].color = new THREE.Color("#333333")),
        1400
      );
    }
    if (
      id == "2NSNN8FBJxlNUq98Liix" &&
      (previousStep == 7 || previousStep == 8)
    ) {
      // innenansicht edelstahl unlackiert
      // console.log("is the color here?", configuration)
      setTimeout(
        () =>
          (materials["GRAY25"].color = new THREE.Color(
            configuration.filter(
              (i) => i.title == "Pulverbeschichtung"
            )[0].currentValue
          )),
        500
      );
    }
    // MAYBE IMPORTANT
    // if (
    //   storedConfig &&
    //   configurationId &&
    //   currentStep != storedConfig["currentStep"]
    // ) {
    //   sliderRef && sliderRef.slickGoTo(storedConfig["currentStep"]);
    //   controlSliderRef.current &&
    //     controlSliderRef.current.slickGoTo(storedConfig["currentStep"]);
    //   // const docRef = setDoc(
    //   //   doc(db, "configurations", configurationId),
    //   //   {
    //   //     lastUpdateTimestamp: new Date().toISOString(),
    //   //     currentStep: currentStep,
    //   //     currentViewpoint: (props.parametrization["menuConfiguration"][0]
    //   //       .numOrder != null
    //   //       ? props.parametrization["menuConfiguration"]
    //   //           .filter((i) => !i.hide)
    //   //           .sort((a, b) => a.numOrder - b.numOrder)
    //   //       : props.parametrization["menuConfiguration"].filter(
    //   //           (i) => !i.hide
    //   //         ))[fieldMenuVisibleIndex >= 0 ? fieldMenuVisibleIndex : 0]
    //   //       .viewpoint
    //   //       ? (props.parametrization["menuConfiguration"][0].numOrder != null
    //   //           ? props.parametrization["menuConfiguration"]
    //   //               .filter((i) => !i.hide)
    //   //               .sort((a, b) => a.numOrder - b.numOrder)
    //   //           : props.parametrization["menuConfiguration"].filter(
    //   //               (i) => !i.hide
    //   //             ))[fieldMenuVisibleIndex >= 0 ? fieldMenuVisibleIndex : 0]
    //   //           .viewpoint
    //   //       : [-4, 3.7, 13, 0, 0, 0, true],
    //   //   },
    //   //   { merge: true }
    //   // );
    // }
    //   else{
    //   sliderRef && sliderRef.slickGoTo(currentStep);
    //   controlSliderRef && controlSliderRef.current.slickGoTo(currentStep);
    // }
  }, [currentStep]);

  useEffect(() => {
    try {
      console.log("CONFIGURATION IS: ", configuration);
      if (
        isTabActive &&
        debouncedConfiguraton &&
        storedConfig &&
        configurationId &&
        !isEqual(
          storedConfig.data,
          configuration.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.title]: cur.currentValue,
            }),
            {}
          )
        )
      ) {
        console.log(
          "sending configuration",
          storedConfig.data,
          configuration.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.title]: cur.currentValue,
            }),
            {}
          )
        );
        // This enables auto update for every change in the
        // if (!configuration.submitted) {
        //   const docRef = setDoc(
        //     doc(db, "configurations", configurationId),
        //     {
        //       lastUpdateTimestamp: new Date().toISOString(),

        //       data: configuration.reduce(
        //         (acc, cur) => ({
        //           ...acc,
        //           [cur.title]:
        //             cur.currentValue != undefined ? cur.currentValue : "",
        //         }),
        //         {}
        //       ),
        //     },
        //     { merge: true }
        //   );
        // }
      }

      console.log("Setting configuration with ", configuration);
      props.setConfiguration(configuration);
      console.log("now its configuration with ", configuration);

      if (configuration && props.scene) {
        console.log("PARAM", props.parametrization, configuration);
        for (const field of props.parametrization["menuConfiguration"].filter(
          (f) => f.inputType == "color"
        )) {
          console.log("updating field ", field.title);
          try {
            field.associatedMeshesNames.forEach((meshName) => {
              console.log(
                "updating ",
                meshName,
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ]
              );
              let meshObject = props.scene.getObjectByName(meshName);
              if (
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ][0] == "#"
              ) {
                // It's a color!
                meshObject.material.color = new THREE.Color(
                  configuration.filter((f) => f.title == field.title)[0][
                    "currentValue"
                  ]
                );
              } else {
                // It's a material!
                console.log("materials are: ", materials);
                meshObject.material =
                  materials[
                    configuration.filter((f) => f.title == field.title)[0][
                      "currentValue"
                    ]
                  ];
              }
            });
          } catch (e) {
            console.log("Could not change color/material: ", e);
          }
        }

        // setting color for input type layeredSelect with color == true
        for (const field of props.parametrization["menuConfiguration"].filter(
          (f) => f.inputType == "layeredSelect" && f.colors
        )) {
          // ony for cube fx interior for menu Wand und Decke
          if (
            id == "2NSNN8FBJxlNUq98Liix" &&
            (currentStep == 7 || currentStep == 8)
          ) {
            // innenansicht edelstahl unlackiert

            materials["GRAY25"].color = new THREE.Color("#333333");
          }
          // if(id == "2NSNN8FBJxlNUq98Liix" && (previousStep == 7 || previousStep == 8)  ){
          //   // innenansicht edelstahl unlackiert
          //   materials["GRAY25"].color = new THREE.Color(configuration.filter(i => i.title == "Pulverbeschichtung")[0].currentValue)
          // }
          if (field.options && field.options[0].fauxVisibility) {
            try {
              // get current value
              let val = configuration.filter((i) => i.title == field.title)[0]
                .currentValue;
              console.log("current value", val);
              // get fauxVisiblity info for currently chosen option
              let fld = field.options.filter((i) => i.title == val[0])[0];
              console.log("current fld", fld);
              for (let meshName of fld.fauxVisibility.associatedMeshesNames) {
                let obj = props.scene.getObjectByName(meshName);
                obj.material = materials[fld.fauxVisibility.material];
              }
            } catch (e) {
              console.log("Problem with faux visibility", e);
            }
          }
          console.log("updating field ", field.title);
          try {
            field.associatedMeshesNames.forEach((meshName) => {
              console.log(
                "updating ",
                meshName,
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ][2]
              );
              let meshObject = props.scene.getObjectByName(meshName);
              if (
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ][2][0] == "#"
              ) {
                // It's a color!
                meshObject.material.color = new THREE.Color(
                  configuration.filter((f) => f.title == field.title)[0][
                    "currentValue"
                  ][2]
                );
              } else {
                // It's a material!
                console.log("materials are: ", materials);
                console.log(
                  "setting with : ",
                  configuration.filter((f) => f.title == field.title)[0][
                    "currentValue"
                  ],
                  materials[
                    configuration.filter((f) => f.title == field.title)[0][
                      "currentValue"
                    ][2]
                  ]
                );
                meshObject.material =
                  materials[
                    configuration.filter((f) => f.title == field.title)[0][
                      "currentValue"
                    ][2]
                  ];
              }
            });
          } catch (e) {
            console.log(
              "Could not change color/material: ",
              e,
              configuration.filter((f) => f.title == field.title)[0][
                "currentValue"
              ][2]
            );
          }
        }

        // shift position for extendableSelect
        for (const field of props.parametrization["menuConfiguration"]
          .filter((f) => f.inputType == "extendableSelect")
          .sort((a, b) => {
            if (!a.shift && b.shift) {
              return -1;
            } else {
              return 1;
            }
          })) {
          console.log("sssifting", field);
          if (field.shift) {
            // console.log("shifting!")
            for (let fld of field.shift) {
              console.log("shifting field ", fld.fieldTitle);
              if (fld.shiftType == "all") {
                let currentLength = configuration
                  .filter((f) => f.title == field.title)[0]
                  ["currentValue"].filter((i) => i).length;
                let optTitles = configuration.filter(
                  (f) => f.title == fld.fieldTitle
                )[0];

                if (optTitles.options) {
                  optTitles = optTitles.options.map((i) => i.title);
                }
                if (optTitles.choices) {
                  optTitles = optTitles.choices.map((i) => i.title);
                }

                console.log(
                  "THIS IS LINKED FIELD",
                  configuration.filter((f) => f.title == fld.fieldTitle)[0]
                );
                let targetField = configuration.filter(
                  (f) => f.title == fld.fieldTitle
                )[0];
                props.scene.traverse((child) => {
                  if (
                    optTitles.filter(
                      (t) =>
                        child.name.includes(t) &&
                        (targetField.inputType != "extendableSelect" ||
                          child.name.includes(fld.fieldTitle))
                    ).length > 0
                  ) {
                    console.log("got match! shifting field", child, optTitles);
                    //child.visible=true;
                    let posDelta = fld.position.filter(
                      (i) => i.length == currentLength
                    )[0].position;
                    if (!targetField.positioningArrays) {
                      console.log(
                        "shifting to absolute",
                        child,
                        posDelta[0],
                        posDelta[1],
                        posDelta[2]
                      );
                      child.position.set(posDelta[0], posDelta[1], posDelta[2]);
                    } else {
                      console.log(
                        "shifting to increment: ",
                        child,
                        child.position.x + posDelta[0],
                        child.position.y + posDelta[1],
                        child.position.z + posDelta[2]
                      );
                      child.position.set(
                        child.position.x + posDelta[0],
                        child.position.y + posDelta[1],
                        child.position.z + posDelta[2]
                      );
                      child.visible = true;
                      for (let cld of child.children) {
                        cld.visible = true;
                      }
                    }
                    // console.log("shifting", child, posDelta, fld.fieldTitle);
                    // child.position.set(posDelta[0], posDelta[1], posDelta[2]);
                  }
                });
              }
            }
          }
        }

        // shift currentValue array in extendableSelect

        for (const field of props.parametrization["menuConfiguration"]
          .filter((f) => f.inputType == "extendableSelect")
          .sort((a, b) => {
            if (!a.shift && b.shift) {
              return -1;
            } else {
              return 1;
            }
          })) {
          let optTitles = field.choices.map((i) => i.title);
          // remove all -> better with intersection than two loops!
          props.scene.traverse((child) => {
            if (
              optTitles.filter(
                (t) =>
                  child.name.includes(t) &&
                  child.name.includes(field.title.replace(" ", "-"))
              ).length > 0 &&
              !optTitles.includes(child.name)
            ) {
              try {
                //props.scene.remove(child)
                if (child instanceof THREE.Group) {
                  console.log(
                    "it's a group aaa setting invislbe",
                    child.name,
                    child,
                    child.children
                  );
                  child.visible = false;

                  // props.scene.remove(child)
                  for (let cld of child.children) {
                    cld.visible = false;
                    cld.layers.disable(0);
                    cld.layers.enable(1);
                  }
                } else {
                  child.visible = false;
                  child.layers.disable(0);
                  child.layers.enable(1);
                }
              } catch (e) {
                console.log(e);
              }
            }
          });
          // make visible along currentValue array with correct positions
          let i = 0;
          let currentData =
            configurationId || predefId
              ? configuration.filter((i) => i.title == field.title)[0]
                  .currentValue
              : field.currentValue;
          console.log("current data is ", currentData);
          for (let element of currentData.filter((i) => i)) {
            if (!element.includes("placeholder")) {
              console.log("elements is ", element);
              let newMesh = props.scene.getObjectByName(element);
              newMesh = newMesh.clone();

              if (newMesh instanceof THREE.Group) {
                console.log("it's a group bbb", newMesh, newMesh.children);
                newMesh.visible = true;
                for (let cld of newMesh.children) {
                  cld.visible = true;
                  cld.layers.disable(1);
                  cld.layers.enable(0);
                  for (let scld of cld.children) {
                    scld.visible = true;
                    scld.layers.disable(1);
                    scld.layers.enable(0);
                  }
                }
              } else {
                newMesh.visible = true;
                newMesh.layers.disable(1);
                newMesh.layers.enable(0);
              }

              newMesh.visible = true;

              console.log(
                "just set visible",
                i + "_" + field.title.replace(" ", "-") + "_" + element
              );

              try {
                for (let obj of props.scene.getObjectsByProperty(
                  "myId",
                  i + "_" + field.title.replace(" ", "-")
                )) {
                  console.log("now deleting", obj);
                  props.scene.remove(obj);
                  // obj.remove()
                }
              } catch (e) {
                console.log("clean up failed", e);
              }

              newMesh.myId = i + "_" + field.title.replace(" ", "-");
              newMesh.name =
                i + "_" + field.title.replace(" ", "-") + "_" + element;

              console.log("positioning field ", field.title, newMesh);
              field.positioningArrays &&
                newMesh.position.set(
                  field.positioningArrays.filter(
                    (i) => i.length == currentData.filter((i) => i).length
                  )[0].position[i],
                  field.positioningArrays.filter(
                    (i) => i.length == currentData.filter((i) => i).length
                  )[0].positionZ
                    ? field.positioningArrays.filter(
                        (i) => i.length == currentData.filter((i) => i).length
                      )[0].positionZ[i]
                    : 0,
                  field.positioningArrays.filter(
                    (i) => i.length == currentData.filter((i) => i).length
                  )[0].positionY
                    ? field.positioningArrays.filter(
                        (i) => i.length == currentData.filter((i) => i).length
                      )[0].positionY[i]
                    : 0
                );
              try {
                if (field.positioningArrays) {
                  newMesh.rotation.y = field.positioningArrays.filter(
                    (i) => i.length == currentData.filter((i) => i).length
                  )[0].rotationY[i];
                }
              } catch (e) {
                console.log("rotating not possible", e);
              }
              try {
                if (field.positioningArrays) {
                  newMesh.rotation.z = field.positioningArrays.filter(
                    (i) => i.length == currentData.filter((i) => i).length
                  )[0].rotationZ[i];
                }
              } catch (e) {
                console.log("rotating not possible", e);
              }
              //newMesh.scale.set(0.001,0.001,0.001)
              props.scene.add(newMesh);

              newMesh.parent = props.scene.getObjectByName(
                field.parentForSpawn ? field.parentForSpawn : "Scene"
              );
              // props.scene.add(newMesh)

              console.log(
                "ELEMENT IS",
                props.scene.getObjectByName(
                  i + "_" + field.title.replace(" ", "-") + "_" + element
                ),
                i + "_" + element,
                props.scene.getObjectByName("2_" + element)
              );
            }
            i += 1;
          }
          console.log("FIELD SHIFT IS", field, field.shift);
          if (field.shift) {
            // console.log("shifting!")
            for (let fld of field.shift) {
              console.log("shifting field ", fld.fieldTitle);
              if (fld.shiftType == "all") {
                let currentLength = configuration
                  .filter((f) => f.title == field.title)[0]
                  ["currentValue"].filter((i) => i).length;
                let optTitles = configuration.filter(
                  (f) => f.title == fld.fieldTitle
                )[0];

                if (optTitles.options) {
                  optTitles = optTitles.options.map((i) => i.title);
                }
                if (optTitles.choices) {
                  optTitles = optTitles.choices.map((i) => i.title);
                }
                let targetField = configuration.filter(
                  (f) => f.title == fld.fieldTitle
                )[0];
                console.log(
                  "THIS IS LINKED FIELD",
                  targetField,
                  optTitles,
                  targetField.inputType,
                  fld.fieldTitle
                );

                props.scene.traverse((child) => {
                  if (
                    optTitles.filter(
                      (t) =>
                        child.name.includes(t) &&
                        (targetField.inputType != "extendableSelect" ||
                          child.name.includes(fld.fieldTitle))
                    ).length > 0
                  ) {
                    console.log("got match! ", child.name, optTitles);
                    //child.visible=true;
                    let posDelta = fld.position.filter(
                      (i) => i.length == currentLength
                    )[0].position;
                    // console.log("shifting ", child, posDelta, fld.fieldTitle);
                    // distinction: If target menu has positioning, e.g. positioningArray, then *increment*. Otherwise set absolutely.
                    if (!targetField.positioningArrays) {
                      console.log(
                        "shifting to absolute",
                        child,
                        posDelta[0],
                        posDelta[1],
                        posDelta[2]
                      );
                      child.position.set(posDelta[0], posDelta[1], posDelta[2]);
                    } else {
                      console.log(
                        "shifting to increment: ",
                        child,
                        child.position.x + posDelta[0],
                        child.position.y + posDelta[1],
                        child.position.z + posDelta[2]
                      );
                      child.position.set(
                        child.position.x + posDelta[0],
                        child.position.y + posDelta[1],
                        child.position.z + posDelta[2]
                      );
                      child.name = child.name + "_shifted";
                    }
                  }
                });
              }
            }
          }
        }

        for (const field of props.parametrization["menuConfiguration"].filter(
          (f) => f.inputType == "singleSelect"
        )) {
          if (field.options.filter((i) => i.scale).length > 0) {
            try {
              let val = configuration.filter((i) => i.title == field.title)[0]
                .currentValue;
              let fld = field.options.filter((i) => i.title == val)[0];
              let target = props.scene.getObjectByName(fld.scale.targetName);
              console.log("scaling", val, fld, target);
              target.scale.set(...fld.scale.value);
            } catch (e) {
              console.log("scaling failed ", e);
            }
          }
          if (field.inducesOn) {
            try {
              let prefix = "";
              console.log("induces on 1: ", field.inducesOn);
              field.inducesOn.forEach((inducesOnFieldTitle) => {
                let curr = configuration.filter(
                  (i) => i.title == inducesOnFieldTitle
                )[0].currentValue;
                if (curr && curr.length > 0) {
                  // make every manometer invisible
                  prefix = "";
                  props.scene.traverse((child) => {
                    // console.log(configuration);
                    // console.log(
                    //   "check if name includes: ",
                    //   inducesOnFieldTitle,
                    //   configuration.filter(
                    //     (i) => i.title == inducesOnFieldTitle
                    //   )[0].currentValue
                    // );
                    // MAYBE THIS IF IS IMPORTANT!
                    // this IS important for example with walkingboxes so "außengestaltung" does not duplicate
                    if (
                      (child.name.includes(
                        configuration.filter(
                          (i) => i.title == inducesOnFieldTitle
                        )[0].currentValue
                          ? configuration.filter(
                              (i) => i.title == inducesOnFieldTitle
                            )[0].currentValue
                          : "###"
                        // != undefined
                      ) &&
                        (!child.name.includes("_mirrored_rotated") && !child.name.includes("_rotated"))) ||
                      (Array.isArray(curr) &&
                        curr.some((r) => child.name.includes(r)))
                    ) {
                      console.log(
                        "setting child invisible : " + child.name,
                        child
                      );
                      child.visible = false;
                      child.layers.disable(0);
                      child.layers.enable(1);
                    }
                  });
                  console.log("GOT THIS VALUE: ", configuration);
                  configuration
                    .filter((i) => i.title == inducesOnFieldTitle)[0]
                    .dependsOn.forEach((dependencyFieldTitle) => {
                      let value = configuration.filter(
                        (i) => i.title == dependencyFieldTitle
                      )[0].currentValue;

                      prefix = `${
                        Array.isArray(value)
                          ? value.filter((i) => i).length
                          : value
                      }|${prefix}`;
                    });
                  for (let c of Array.isArray(curr) ? curr : [curr]) {
                    let pref = prefix + c;
                    // prefix =
                    //   prefix +
                    //   configuration.filter((i) => i.title == inducesOnFieldTitle)[0]
                    //     .currentValue;
                    //prefix = prefix.replace
                    let meshObject = props.scene.getObjectByName(pref);
                    console.log("setting visible: ", pref);
                    // make correct manometer visible

                    if (meshObject instanceof THREE.Group) {
                      console.log(
                        "it's a group",
                        meshObject,
                        meshObject.children
                      );
                      meshObject.visible = true;
                      for (let cld of meshObject.children) {
                        cld.visible = true;
                        cld.layers.disable(1);
                        cld.layers.enable(0);
                        for (let scld of meshObject.children) {
                          scld.visible = true;
                          scld.layers.disable(1);
                          scld.layers.enable(0);
                        }
                      }
                    } else meshObject.visible = true;
                    meshObject.layers.disable(1);
                    meshObject.layers.enable(0);
                  }
                }
              });
            } catch (e) {
              console.log(e);
            }
          }

          if (field.shift) {
            try {
              // console.log("shifting!")
              for (let fld of field.shift) {
                console.log(
                  "shifting field ",
                  fld.fieldTitle,
                  fld.shiftType,
                  configuration
                );
                if (fld.shiftType == "all") {
                  let currentValue = configuration.filter(
                    (f) => f.title == field.title
                  )[0]["currentValue"];
                  let optTitles = configuration.filter(
                    (f) => f.title == fld.fieldTitle
                  )[0];

                  if (optTitles.options) {
                    optTitles = optTitles.options.map((i) => i.title);
                  }
                  if (optTitles.choices) {
                    optTitles = optTitles.choices.map((i) => i.title);
                  }
                  let targetField = configuration.filter(
                    (f) => f.title == fld.fieldTitle
                  )[0];
                  console.log(
                    "THIS IS LINKED FIELD",
                    targetField,
                    optTitles,
                    targetField.inputType,
                    fld.fieldTitle
                  );

                  props.scene.traverse((child) => {
                    if (
                      optTitles.filter(
                        (t) =>
                          child.name.includes(t) &&
                          (targetField.inputType != "extendableSelect" ||
                            child.name.includes(fld.fieldTitle))
                      ).length > 0
                    ) {
                      console.log("got match! ", child.name, optTitles);
                      //child.visible=true;
                      let posDelta = fld.position.filter(
                        (i) => i.value == currentValue
                      )[0].position;
                      // console.log("shifting ", child, posDelta, fld.fieldTitle);
                      // distinction: If target menu has positioning, e.g. positioningArray, then *increment*. Otherwise set absolutely.
                      if (!targetField.positioningArrays) {
                        console.log(
                          "shifting to absolute",
                          child,
                          posDelta[0],
                          posDelta[1],
                          posDelta[2]
                        );
                        child.position.set(
                          posDelta[0],
                          posDelta[1],
                          posDelta[2]
                        );
                      } else {
                        console.log(
                          "shifting to increment: ",
                          child,
                          child.position.x + posDelta[0],
                          child.position.y + posDelta[1],
                          child.position.z + posDelta[2]
                        );
                        child.position.set(
                          child.position.x + posDelta[0],
                          child.position.y + posDelta[1],
                          child.position.z + posDelta[2]
                        );
                        //child.name = child.name + "_shifted";
                      }
                    }
                  });
                }
              }
            } catch (e) {
              console.log("could not shift", e);
            }
          }

          try {
            for (let name of (props.parametrization["menuConfiguration"][0]
              .numOrder != null
              ? props.parametrization["menuConfiguration"]
                  .filter((i) => !i.hide)
                  .sort((a, b) => a.numOrder - b.numOrder)
              : props.parametrization["menuConfiguration"].filter(
                  (i) => !i.hide
                ))[previousStep].meshesInvisibleWhenActive) {
              console.log("SETTING VISIBLE ", name);
              let obj = props.scene.getObjectByName(name);
              if (obj instanceof THREE.Group) {
                for (let cld of obj.children) {
                  cld.visible = true;
                }
              }
              obj.visible = true;
            }
          } catch (e) {
            console.log(e);
          }
          try {
            for (let name of (props.parametrization["menuConfiguration"][0]
              .numOrder != null
              ? props.parametrization["menuConfiguration"]
                  .filter((i) => !i.hide)
                  .sort((a, b) => a.numOrder - b.numOrder)
              : props.parametrization["menuConfiguration"].filter(
                  (i) => !i.hide
                ))[currentStep].meshesInvisibleWhenActive) {
              console.log("SETTING INVISIBLE ", name);
              let obj = props.scene.getObjectByName(name);
              if (obj instanceof THREE.Group) {
                for (let cld of obj.children) {
                  console.log("got this child!", cld);
                  cld.visible = false;
                }
              }
              obj.visible = false;
            }
          } catch (e) {
            console.log(e);
          }

          // handle visibility here!:
          let prefix = "";
          try {
            field.options.forEach((options) => {
              // option.title is the mesh name!
              // check if there is a dependency -> concat with pipe along dependsOn array
              let meshName = options.title;
              prefix = "";
              if (field.dependsOn && field.dependsOn.length > 0) {
                field.dependsOn.forEach((dependencyFieldTitle) => {
                  let value = configuration.filter(
                    (i) => i.title == dependencyFieldTitle
                  )[0].currentValue;
                  console.log("VALUE IS", value);
                  prefix = `${
                    Array.isArray(value) ? value.filter((i) => i).length : value
                  }|${prefix}`;
                });
              }

              let meshObject = props.scene.getObjectByName(
                prefix + options.title
              );

              let isVisible =
                options.title ==
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ];

              if (isVisible) {
                console.log(
                  "setting visible now: ",
                  prefix + options.title,
                  meshObject
                );
                meshObject.visible = true;
                meshObject.layers.disable(1);
                meshObject.layers.enable(0);
                try {
                  for (let cld of meshObject.children) {
                    cld.visible = true;
                    cld.layers.disable(1);
                    cld.layers.enable(0);
                    for (let cld1 of cld.children) {
                      cld1.visible = true;
                      cld1.layers.disable(1);
                      cld1.layers.enable(0);
                    }
                  }
                } catch (e) {
                  console.log(e);
                }
              } else {
                console.log(
                  "setting invisible now: ",
                  prefix + options.title,
                  meshObject,
                  props.scene
                );
                meshObject.visible = false;
                meshObject.layers.disable(0);
                meshObject.layers.enable(1);
              }
            });
          } catch (e) {
            console.log(
              "Problem changing visibility in single select field: ",
              e
            );
          }
        }

        if (configuration && isTabActive) {
          let audio = document.getElementById("clickSound");
          audio.pause();
          audio.currentTime = 0;
          audio.volume = 0.02;
          audio.play();
        }
      }
    } catch (e) {
      console.log("Problem manipulating Model", e);
    }
  }, [configuration]);

  useEffect(() => {
    console.log("steps", previousStep, currentStep);
    if (
      props.parametrization &&
      configuration &&
      previousStep &&
      props.parametrization.menuConfiguration[previousStep].notifications
    ) {
      let previousField = props.parametrization.menuConfiguration[previousStep];
      console.log(
        "currentField ",
        previousStep,
        configuration,
        props.parametrization.menuConfiguration[previousStep]
      );

      previousField.notifications &&
        console.log(
          "Should be removed ",
          previousField.notifications.filter(
            (i) => i.value != configuration[previousStep].currentValue
          )
        );
      for (let notificationSpecs of previousField.notifications) {
        //let toBeDismissed = field.notifications.filter(i => i.value != newValue);
        try {
          console.log("sould be removed", notificationSpecs);
          // setLastNotificationId(notificationSpecs.id)
          toast.dismiss(notificationSpecs.id);
        } catch (e) {
          console.log(e);
        }
      }
    }

    // setting opacity focus
    // try {
    //   if (
    //     props.parametrization["menuConfiguration"][currentStep]
    //       .focusOnMeshes
    //   ) {
    //     props.scene.traverse((obj) => {
    //       if (
    //         props.parametrization["menuConfiguration"][
    //           next
    //         ].focusOnMeshes.includes(obj.name)
    //       ) {
    //         console.log("found ", obj.name);
    //         try {
    //           obj.material.opacity = 1;
    //           obj.material.transparent = true;
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       } else {
    //         try {
    //           obj.material.opacity = 0.3;
    //           obj.material.transparent = true;
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }
    //     });
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  }, [previousStep]);

  useEffect(() => {
    try {
      console.log("notification logic");
      if (
        props.parametrization &&
        configuration &&
        props.parametrization.menuConfiguration[currentStep].notifications
      ) {
        let currentField = props.parametrization.menuConfiguration[currentStep];
        let previousField =
          props.parametrization.menuConfiguration[previousStep];
        console.log(
          "currentField ",
          currentField,
          configuration,
          props.parametrization.menuConfiguration[currentStep]
        );

        // currentField.notifications &&
        //   console.log(
        //     "Should be removed ",
        //     currentField.notifications.filter(
        //       (i) =>
        //         i.value != configuration[currentStep].currentValue &&
        //         !configuration[currentStep].currentValue.includes(i.value) && i.value != "all"
        //     )
        //   );

        for (let notificationSpecs of currentField.notifications.filter(
          (i) =>
            i.value != configuration[currentStep].currentValue &&
            // !typeof !configuration[currentStep].currentValue === "object" &&
            configuration[currentStep].currentValue.includes &&
            !configuration[currentStep].currentValue.includes(i.value) &&
            i.value != "all"
        )) {
          //let toBeDismissed = field.notifications.filter(i => i.value != newValue);
          if (
            !currentField.notifications
              .filter((i) => i.value == configuration[currentStep].currentValue)
              .map((i) => i.id)
              .includes(notificationSpecs.id)
          ) {
            try {
              console.log("sould be removed", notificationSpecs);
              // setLastNotificationId(notificationSpecs.id)
              toast.dismiss(notificationSpecs.id);
            } catch (e) {
              console.log(e);
            }
          }
        }

        for (let notificationSpecs of currentField.notifications.filter(
          (i) =>
            i.value == configuration[currentStep].currentValue ||
            (Array.isArray(configuration[currentStep].currentValue) &&
              configuration[currentStep].currentValue?.includes(i.value)) ||
            i.value == "all"
        )) {
          console.log("new notification: ", notificationSpecs.id);
          setTimeout(() => {
            toast(notificationSpecs.text, {
              //description: 'Monday, January 3rd at 6:00pm',
              duration: Infinity,
              id: notificationSpecs.id,
              style: {
                background: "rgba(80,80,80,0.92)",
                color: "rgba(255,255,255,1)",
                margin: innerWidth < 800 ? "0" : "auto",
                zIndex: 500,
                lineHeight: "1.2",
                fontSize: innerWidth < 800 ? "0.6rem" : "0.76rem",
                position: "relative",
                padding: "8px 12px 8px 12px",
                textAlign: "center",
                border: 0,
                width: innerWidth < 800 ? "auto" : "500px",
                userSelect: "none",
                display: "block",
              },
              className: "my-toast",
              descriptionClassName: "my-toast-description",
            });
          }, 500);
        }

        // shift position for extendableSelect
        // MAYBE THIS WAS IMPORTANT BUT IT CAUSES SIDE ELEMENTS TO VANISH !!!
        // for (const field of props.parametrization["menuConfiguration"].filter(
        //   (f) => f.inputType == "extendableSelect"
        // )) {

        //   console.log("sssifting", field);
        //   if (field.shift) {

        //     // console.log("shifting!")
        //     for (let fld of field.shift) {
        //       if (fld.shiftType == "all") {
        //         let currentLength = configuration
        //           .filter((f) => f.title == field.title)[0]
        //           ["currentValue"].filter((i) => i).length;
        //         let optTitles = configuration
        //           .filter((f) => f.title == fld.fieldTitle)[0]
        //           .options.map((i) => i.title);

        //         props.scene.traverse((child) => {
        //           if (
        //             optTitles.filter((t) => child.name.includes(t)).length > 0
        //           ) {
        //             child.visible=false;
        //             // console.log("got match!",child.name, optTitles )
        //             let posDelta = fld.position.filter(
        //               (i) => i.length == currentLength
        //             )[0].position;
        //             child.position.set(posDelta[0], posDelta[1], posDelta[2]);
        //           }

        //           if(
        //             child.name.includes(configuration.filter((f) => f.title == fld.fieldTitle)[0].currentValue)
        //           ){
        //             console.log("TRYING TO SET VISIBLE",)
        //             child.visible=true;
        //             child.layers.disable(1);
        //             child.layers.enable(0);
        //           }
        //         });
        //       }
        //     }
        //   }
        // }

        // shift currentValue array in extendableSelect

        for (const field of props.parametrization["menuConfiguration"]
          .filter((f) => f.inputType == "extendableSelect")
          .sort((a, b) => {
            if (!a.shift && b.shift) {
              return -1;
            } else {
              return 1;
            }
          })) {
          let optTitles = field.choices.map((i) => i.title);
          // remove all -> better with intersection than two loops!
          props.scene.traverse((child) => {
            if (
              optTitles.filter((t) => child.name.includes(t)).length > 0 &&
              !optTitles.includes(child.name)
            ) {
              try {
                //props.scene.remove(child)
                if (child instanceof THREE.Group) {
                  console.log(
                    "it's a group aaa setting invisible",
                    child.name,
                    child,
                    child.children
                  );
                  child.visible = false;
                  for (let cld of child.children) {
                    cld.visible = false;
                    cld.layers.disable(0);
                    cld.layers.enable(1);
                  }
                } else {
                  child.visible = false;
                  child.layers.disable(0);
                  child.layers.enable(1);
                }
              } catch (e) {
                console.log(e);
              }
            }
          });
          // make visible along currentValue array with correct positions
          let i = 0;
          let currentData =
            configurationId || predefId
              ? configuration.filter((i) => i.title == "Vorderseite")[0]
                  .currentValue
              : field.currentValue;

          for (let element of currentData.filter((i) => i)) {
            if (!element.includes("placeholder")) {
              console.log("elements is ", element);
              let newMesh = props.scene.getObjectByName(element);
              newMesh = newMesh.clone();

              if (newMesh instanceof THREE.Group) {
                console.log("it's a group aaa", newMesh, newMesh.children);
                newMesh.visible = true;
                for (let cld of newMesh.children) {
                  cld.visible = true;
                  cld.layers.disable(1);
                  cld.layers.enable(0);
                }
              } else {
                newMesh.visible = true;
                newMesh.layers.disable(1);
                newMesh.layers.enable(0);
              }

              newMesh.visible = true;

              try {
                for (let obj of props.scene.getObjectsByProperty(
                  "myId",
                  i + "_" + field.title.replace(" ", "-")
                )) {
                  console.log("now deleting", obj);
                  props.scene.remove(obj);
                  // obj.remove()
                }
              } catch (e) {
                console.log("clean up failed", e);
              }

              newMesh.myId = i + "_" + field.title.replace(" ", "-");

              newMesh.name =
                i + "_" + field.title.replace(" ", "-") + "_" + element;
              let posarr = field.positioningArrays.filter(
                (i) => i.length == currentData.filter((i) => i).length
              )[0];
              console.log(
                "setting position from positioningArrays for ",
                newMesh
              );
              newMesh.position.set(
                posarr.position[i],
                posarr.positionZ ? [i] : 0,
                posarr.positionY ? [i] : 0
              );

              if (posarr.rotationX) {
                console.log("rotating x with ", posarr.rotationX);
                newMesh.rotation.x = posarr.rotationX[i];
              }

              if (posarr.rotationZ) {
                console.log("rotating z with ", posarr.rotationZ);
                newMesh.rotation.z = posarr.rotationZ[i];
              }

              //newMesh.scale.set(0.001,0.001,0.001)
              props.scene.add(newMesh);
              newMesh.parent = props.scene.getObjectByName(
                field.parentForSpawn ? field.parentForSpawn : "Scene"
              );
              // props.scene.add(newMesh)

              console.log(
                "ELEMENT IS",
                props.scene.getObjectByName(i + "_" + element),
                i + "_" + element,
                props.scene.getObjectByName("2_" + element)
              );
            }
            i += 1;
          }
          if (field.shift) {
            // console.log("shifting!")
            for (let fld of field.shift) {
              console.log("shifting field ", fld.fieldTitle);
              if (fld.shiftType == "all") {
                let currentLength = configuration
                  .filter((f) => f.title == field.title)[0]
                  ["currentValue"].filter((i) => i).length;
                let optTitles = configuration.filter(
                  (f) => f.title == fld.fieldTitle
                )[0];

                if (optTitles.options) {
                  optTitles = optTitles.options.map((i) => i.title);
                }
                if (optTitles.choices) {
                  optTitles = optTitles.choices.map((i) => i.title);
                }
                let targetField = configuration.filter(
                  (f) => f.title == fld.fieldTitle
                )[0];
                console.log(
                  "THIS IS LINKED FIELD",
                  targetField,
                  optTitles,
                  targetField.inputType,
                  fld.fieldTitle
                );

                props.scene.traverse((child) => {
                  if (
                    optTitles.filter(
                      (t) =>
                        child.name.includes(t) &&
                        (targetField.inputType != "extendableSelect" ||
                          child.name.includes(fld.fieldTitle))
                    ).length > 0
                  ) {
                    console.log("got match! ", child.name, optTitles);
                    //child.visible=true;
                    let posDelta = fld.position.filter(
                      (i) => i.length == currentLength
                    )[0].position;
                    // console.log("shifting ", child, posDelta, fld.fieldTitle);
                    // distinction: If target menu has positioning, e.g. positioningArray, then *increment*. Otherwise set absolutely.
                    if (!targetField.positioningArrays) {
                      console.log(
                        "shifting to absolute",
                        child,
                        posDelta[0],
                        posDelta[1],
                        posDelta[2]
                      );
                      child.position.set(posDelta[0], posDelta[1], posDelta[2]);
                    } else {
                      console.log(
                        "shifting to increment: ",
                        child,
                        child.position.x + posDelta[0],
                        child.position.y + posDelta[1],
                        child.position.z + posDelta[2]
                      );
                      child.position.set(
                        child.position.x + posDelta[0],
                        child.position.y + posDelta[1],
                        child.position.z + posDelta[2]
                      );
                      child.name = child.name + "_shifted";
                    }
                  }
                });
              }
            }
          }
        }
      }
    } catch (e) {
      console.log("There is a Problem w/ manipulation", e);
    }
  }, [configuration, currentStep]);

  useEffect(() => {
    setCurrentStep(props.currentStep);
  }, [props.currentStep]);

  useEffect(() => {
    try {
      if (configuration && props.scene && !stateLoaded && props.modelLoaded) {
        // props.scene.traverse(el => {
        //   try{
        //   el.visible = false;
        //   el.layers.disable(0);
        //   el.layers.enable(1);}catch(e){

        //   }
        // })
        console.log("PARAM", props.parametrization, configuration);
        // setting color for input type color

        for (const field of props.parametrization["menuConfiguration"].filter(
          (f) => f.inputType == "color"
        )) {
          console.log("updating field ", field.title);
          try {
            field.associatedMeshesNames.forEach((meshName) => {
              console.log(
                "updating ",
                meshName,
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ]
              );
              let meshObject = props.scene.getObjectByName(meshName);
              if (
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ][0] == "#"
              ) {
                // It's a color!
                meshObject.material.color = new THREE.Color(
                  configuration.filter((f) => f.title == field.title)[0][
                    "currentValue"
                  ]
                );
              } else {
                // It's a material!
                console.log("materials are: ", materials);
                meshObject.material =
                  materials[
                    configuration.filter((f) => f.title == field.title)[0][
                      "currentValue"
                    ]
                  ];
              }
            });
          } catch (e) {
            console.log(
              "Could not change color/material: ",
              e,
              configuration.filter((f) => f.title == field.title)[0][
                "currentValue"
              ]
            );
          }
        }

        // setting color for input type layeredSelect with color == true
        for (const field of props.parametrization["menuConfiguration"].filter(
          (f) => f.inputType == "layeredSelect" && f.colors
        )) {
          console.log("updating field ", field.title);
          // faux visibility

          if (field.options && field.options[0].fauxVisibility) {
            try {
              // get current value
              let val = configuration.filter((i) => i.title == field.title)[0]
                .currentValue;
              // get fauxVisiblity info for currently chosen option
              let fld = field.options.filter((i) => i.title == val)[0];
              for (let meshName of fld.fauxVisibility.associatedMeshesNames) {
                let obj = props.scene.getObjectByName(meshName);
                obj.material = materials[fld.fauxVisibility.material];
              }
            } catch (e) {
              console.log("Problem with faux visibility", e);
            }
          }

          try {
            field.associatedMeshesNames.forEach((meshName) => {
              console.log(
                "updating ",
                meshName,
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ][2]
              );
              let meshObject = props.scene.getObjectByName(meshName);
              if (
                configuration.filter((f) => f.title == field.title)[0][
                  "currentValue"
                ][2][0] == "#"
              ) {
                // It's a color!
                meshObject.material.color = new THREE.Color(
                  configuration.filter((f) => f.title == field.title)[0][
                    "currentValue"
                  ][2]
                );
              } else {
                // It's a material!
                console.log("materials are: ", materials);
                meshObject.material =
                  materials[
                    configuration.filter((f) => f.title == field.title)[0][
                      "currentValue"
                    ][2]
                  ];
              }
            });
          } catch (e) {
            console.log(
              "Could not change color/material: ",
              e,
              configuration.filter((f) => f.title == field.title)[0][
                "currentValue"
              ][2]
            );
          }
        }

        // .filter(
        //   (f) => f.inputType == "singleSelect"
        // )
        for (const field of props.parametrization["menuConfiguration"]) {
          if (field.inducesOn) {
            try {
              let prefix = "";
              console.log("induces on 2: ", field.inducesOn);
              field.inducesOn.forEach((inducesOnFieldTitle) => {
                let curr = configuration.filter(
                  (i) => i.title == inducesOnFieldTitle
                )[0].currentValue;
                if (
                  configuration.filter((i) => i.title == inducesOnFieldTitle)[0]
                    .currentValue.length > 0
                ) {
                  // make every manometer invisible
                  prefix = "";
                  props.scene.traverse((child) => {
                    // console.log(configuration);
                    // console.log(
                    //   "check if name includes: ",
                    //   inducesOnFieldTitle,
                    //   configuration.filter(
                    //     (i) => i.title == inducesOnFieldTitle
                    //   )[0].currentValue
                    // );
                    if (
                      child.name.includes(
                        configuration.filter(
                          (i) => i.title == inducesOnFieldTitle
                        )[0].currentValue
                          ? configuration.filter(
                              (i) => i.title == inducesOnFieldTitle
                            )[0].currentValue
                          : "###"
                        // != undefined
                      ) ||
                      (Array.isArray(curr) &&
                        curr.some((r) => child.name.includes(r)))
                    ) {
                      console.log("setting child invisible: ", child);
                      child.visible = false;
                      child.layers.disable(0);
                      child.layers.enable(1);
                    }
                  });
                  configuration
                    .filter((i) => i.title == inducesOnFieldTitle)[0]
                    .dependsOn.forEach(
                      (dependencyFieldTitle) => {
                        let value = configuration.filter(
                          (i) => i.title == dependencyFieldTitle
                        )[0].currentValue;
                        console.log("VALUE IS", value);
                        prefix = `${
                          Array.isArray(value)
                            ? value.filter((i) => i).length
                            : value
                        }|${prefix}`;
                      }
                      //  {
                      //   prefix = `${
                      //     configuration.filter(
                      //       (i) => i.title == dependencyFieldTitle
                      //     )[0].currentValue
                      //   }|${prefix}`;
                      // }
                    );
                  for (let c of Array.isArray(curr) ? curr : [curr]) {
                    let pref = prefix + c;
                    let meshObject = props.scene.getObjectByName(pref);
                    console.log(
                      "setting visible: ",
                      pref,
                      meshObject,
                      props.scene
                    );
                    // make correct manometer visible

                    if (meshObject instanceof THREE.Group) {
                      console.log(
                        "it's a group öäü",
                        meshObject,
                        meshObject.children
                      );
                      meshObject.visible = true;
                      meshObject.layers.disable(1);
                      meshObject.layers.enable(0);
                      for (let cld of meshObject.children) {
                        if (cld instanceof THREE.Group) {
                          console.log("it's a sub group", cld, cld.children);
                          cld.visible = true;
                          cld.layers.disable(1);
                          cld.layers.enable(0);
                          console.log("setting visible on load", cld);
                          for (let sub of cld.children) {
                            if (sub instanceof THREE.Group) {
                              for (let subsub of sub.children) {
                                console.log("it's a sub-sub group", subsub);
                                subsub.visible = true;
                                subsub.layers.disable(1);
                                subsub.layers.enable(0);
                              }
                            } else {
                              console.log("setting mesh visible on load", sub);
                              sub.visible = true;
                              sub.layers.disable(1);
                              sub.layers.enable(0);
                            }
                          }
                        } else {
                          console.log("not a saub-group, but a mesh", cld);
                          cld.visible = true;
                          cld.layers.disable(1);
                          cld.layers.enable(0);
                        }
                      }
                    } else {
                      meshObject.visible = true;
                      meshObject.layers.disable(1);
                      meshObject.layers.enable(0);
                    }
                  }
                }
              });
            } catch (e) {
              console.log(e);
            }
          }

          // handle visibility here!:
          let prefix = "";
          try {
            console.log("looking at field ", field.title);
            field.options &&
              field.options.forEach((options) => {
                // option.title is the mesh name!
                // check if there is a dependency -> concat with pipe along dependsOn array
                let meshName = options.title;
                prefix = "";
                if (field.dependsOn && field.dependsOn.length > 0) {
                  field.dependsOn.forEach((dependencyFieldTitle) => {
                    console.log(dependencyFieldTitle);
                    console.log("this: ", configuration);
                    prefix = `${
                      configuration.filter(
                        (i) => i.title == dependencyFieldTitle
                      )[0].currentValue
                    }|${prefix}`;
                  });
                }

                let meshObject = props.scene.getObjectByName(
                  prefix + options.title
                );

                let isVisible =
                  options.title ==
                  configuration.filter((f) => f.title == field.title)[0][
                    "currentValue"
                  ];
                if (isVisible) {
                  console.log(
                    "setting visible now: ",
                    prefix + options.title,
                    meshObject
                  );
                  meshObject.visible = true;
                  meshObject.layers.disable(1);
                  meshObject.layers.enable(0);
                  for (let cld of meshObject.children) {
                    console.log("VISBILIUM", cld);
                    cld.visible = true;
                    cld.layers.disable(1);
                    cld.layers.enable(0);
                  }
                } else {
                  console.log(
                    "setting invisible now: ",
                    prefix + options.title,
                    meshObject
                  );
                  meshObject.visible = false;
                  meshObject.layers.disable(0);
                  meshObject.layers.enable(1);
                }
              });
          } catch (e) {
            console.log(
              "Problem changing visibility in single select field: ",
              e
            );
          }
        }

        setStateLoaded(true);
      }
    } catch (e) {
      console.log("Manipulation failed!", e);
    }
  }, [props.configuration, props.modelLoaded]);

  const sheetRef = useRef();
  
  function download(scene) {
    let glbScene = scene.clone()
    //glbScene.traverse(i => i.scale.set(0.3,0.3,0.3))
  //let sc = glbScene.getObjectByName("Scene")
  
    console.log("got this scene", scene);
    try {
      let shadowFloor = glbScene.getObjectByName("shadowFloor");
      shadowFloor.visible = false;
    } catch (e) {
      console.log(e);
    }
    const exporter = new GLTFExporter();
    if(props.parametrization.id == "RjC8Ss0qnMrIjseH0VSp"){
      glbScene.traverse(m => {
        if((m instanceof THREE.Group) && m.name.includes("Rahmen")){
          m.position.y = -0.71
        }
      })
    }
    if(props.parametrization.id == "ALix3WF4dSsrubYu2NBH"){
      glbScene.traverse(m => {
        if((m instanceof THREE.Group) && ((m.name.includes("Seite_Rechts") && (m.name.includes("rotated")||m.name.includes("mirrored"))) || (m.name.includes("Frontseite") && m.name.includes("mirrored"))) ){
          m.position.y = -0.06
        }
      })
    }
    exporter.parse(
      glbScene,
      function (result) {
        saveArrayBuffer(result, "ThreejsScene.glb");
      },
      function (error) {
        console.log("An error happened", error);
      },
      {
        binary: true,
        onlyVisible: true,
        trs: true,
        truncateDrawRange: true,
      }
    );
    try {
      let shadowFloor = glbScene.getObjectByName("shadowFloor");
      shadowFloor.visible = true;
    } catch (e) {
      console.log(e);
    }
  }

  function saveArrayBuffer(buffer, filename) {
    save(new Blob([buffer], { type: "application/octet-stream" }), filename);
  }

  function save(blob, filename) {
    // link.href = URL.createObjectURL(blob);
    // link.download = filename;
    // link.click(); // This step makes sure your glb file gets downloaded
    sendFileToBackend(blob, filename);
  }

  function sendFileToBackend(blob, filename) {
    // const endpoint = "YOUR_BACKEND_URL_HERE";
    // const formData = new FormData();

    // let sceneFile= new File([blob], filename + ".glb");
    // console.log(sceneFile)
    // formData.append("file", sceneFile);

    // const options = {
    //     method:'POST',
    //     mode: 'no-cors',
    //     body: formData,
    // }

    // fetch(endpoint,options)
    //     .then(response => console.log(JSON.stringify(response)))
    //     .catch(error => console.error('Error:', error))
    let sceneFile = new File([blob], filename);
    console.log(sceneFile);
    const storage = getStorage();
    const storageRef = ref(storage, `${configurationId}.glb`);

    uploadBytes(storageRef, sceneFile)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((downloadURL) => {
        console.log("DOWNLOAD URL: ", downloadURL);
        setGlbDownloadUrl(downloadURL);
        window.open("/ar/"+configurationId, "_blank")
        // const docRef = setDoc(
        //   doc(db, "configurations", configurationId),
        //   {
        //     img: downloadURL,
        //   },
        //   { merge: true }
        // );
      });
  }

  const SubmitButton = (
    <Button
      size="md"
      className={"submit-button"}
      sx={{
        userSelect: "none",
        "&:hover": {
          backgroundPosition: "right center",
          // backgroundColor: !submitted
          //   ? props.parametrization.branding.primaryColor.rgb
          //   : "green",
          backgroundColor: "white",
        },

        backgroundColor: "white",
        // backgroundImage: !submitted
        //   ? `linear-gradient(to right, ${props.parametrization.branding.primaryColor.rgb} 0%,
        //     rgba(${props.parametrization.branding.primaryColor.r},${props.parametrization.branding.primaryColor.g},${props.parametrization.branding.primaryColor.b}, 0.4) 51%, ${props.parametrization.branding.primaryColor.rgb} 100%)`
        //   : "green",
        // backgroundSize: "200% auto",
        padding: "0px 10px 0px 10px",
        fontSize: !largeMenu ? "1.3rem" : "2.8rem",
        minHeight: "30px",
        transition: "0.5s",
        borderRadius: "40px",
        // display: "block",
        zIndex: 1500,
        borderRadius: props.parametrization.branding?.borderRadius
          ? props.parametrization.branding.borderRadius
          : "22px",

        // border: "1px solid #bbb",
        boxShadow: props.parametrization.branding?.boxShadow
          ? props.parametrization.branding.boxShadow
          : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
        // marginBottom: "-200px",
        border: props.parametrization.branding?.border
          ? props.parametrization.branding.border
          : "none",
      }}
    >
      {props.parametrization.pricing && configuration && (
        <span
          style={{
            borderRight: "1px solid #bbb",
            paddingRight: innerWidth > 800 ? "10px" : "10px",
            marginRight: innerWidth > 800 ? 0 : "10px",
            display: "flex"
          }}
          onClick={() => setOverviewModalOpen(true)}
        >
           
           <div style={{color: "#333", marginRight: "9px", marginLeft: "2px"}} ><Expand_more width={"20px"} strokewidth={2}/></div>
          <div element={"div"} style={{ textAlign: "center", color: "#000", marginRight: "8px" }}>
            {/* <span class="price-tag price-tag--two-lines" style={{"&::before":{background: "red"}}}>
<span class="price-tag__main" style={{color: "black"}}> */}
            {!props.parametrization.priceIsExact ? <div
              style={{
                color: "#888",
                fontSize: "8px",
                marginTop: "3px",
                marginBottom: "-3px",
              }}
            >
              PREISSCHÄTZUNG
            </div>:<div
            
              style={{
                color: "#888",
                fontSize: "8px",
                marginTop: "3px",
                marginBottom: "-3px",
               
              }}
            >
              
              PREIS INKL. MWST.
            </div>}
            <CountTo to={price} />
            
            {/* </span> */}

            {/* </span> */}
            {/* <small
      style={{
        display: "block",
        color: "#999",
        fontSize: "0.6rem",
      }}
    >
      PREISSCHÄTZUNG
    </small> */}
   
          </div>
          {/* <hr color={"#bbb"} style={{margin: "0 20px", height: "1px", border: 0, borderTop: "1px solid #bbb"}}/>
           */}
        </span>
      )}
      <FadeModalDialog
      verboseConfiguration={verboseConfiguration}
        scene={props.scene}
        storedConfig={storedConfig}
        reloadStoredConfig={reload}
        configuration={configuration}
        submitted={submitted}
        toggleSubmitted={toggleSubmitted}
        {...props}
      />
    </Button>
  );

  const SimpleSubmitButton = (
    <Button
      size="md"
      className={"submit-button"}
      sx={{
        userSelect: "none",
        "&:hover": {
          backgroundPosition: "right center",
          // backgroundColor: !submitted
          //   ? props.parametrization.branding.primaryColor.rgb
          //   : "green",
          backgroundColor: "white",
        },

        backgroundColor: "white",
        // backgroundImage: !submitted
        //   ? `linear-gradient(to right, ${props.parametrization.branding.primaryColor.rgb} 0%,
        //     rgba(${props.parametrization.branding.primaryColor.r},${props.parametrization.branding.primaryColor.g},${props.parametrization.branding.primaryColor.b}, 0.4) 51%, ${props.parametrization.branding.primaryColor.rgb} 100%)`
        //   : "green",
        // backgroundSize: "200% auto",
        padding: "0px 10px 0px 10px",
        fontSize: !largeMenu ? "1.3rem" : "2.8rem",
        minHeight: "30px",
        transition: "0.5s",
        borderRadius: "40px",
        // display: "block",
        zIndex: 1500,
        borderRadius: props.parametrization.branding?.borderRadius
          ? props.parametrization.branding.borderRadius
          : "22px",

        // border: "1px solid #bbb",
        boxShadow: props.parametrization.branding?.boxShadow
          ? props.parametrization.branding.boxShadow
          : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
        // marginBottom: "-200px",
        border: props.parametrization.branding?.border
          ? props.parametrization.branding.border
          : "none",
      }}
    >
      
      <FadeModalDialog
      verboseConfiguration={verboseConfiguration}
        scene={props.scene}
        storedConfig={storedConfig}
        reloadStoredConfig={reload}
        configuration={configuration}
        submitted={submitted}
        toggleSubmitted={toggleSubmitted}
        {...props}
      />
    </Button>
  );

  return (
    <>
      {/* {id == "kzGiEoS5bKtyeLGiHDme" && (
        <Helmet>
          <script type="text/javascript">
            {(function () {
              var Tawk_API = Tawk_API || {},
                Tawk_LoadStart = new Date();
              var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
              s1.async = true;
              s1.src =
                "https://embed.tawk.to/63b934acc2f1ac1e202c2df4/1h3d0f4s2";
              s1.charset = "UTF-8";
              s1.setAttribute("crossorigin", "*");
              s0.parentNode.insertBefore(s1, s0);
            })()}
          </script>
        </Helmet>
      )} */}
      {id == "CTj8E6bpuY0KwNBH49w8" && innerWidth > 1200 && false && (
        <div
          className={
            "wrapper " +
            (!largeMenu && "animate__animated animate__fadeIn animate__slow")
          }
          style={{
            position: "absolute",
            bottom: "20px",
            left: "100px",
            height: "200px",
            width: "250px",
            zIndex: 500,
          }}
        >
          {/* <ul class="video-stack">
            <li>
              <iframe
                className={
                  currentStep == 0 &&
                  "animate__animated animate__fadeIn animate__faster"
                }
                style={{ display: currentStep == 0 ? "block" : "none" }}
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/6h28_iry9u0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <iframe
                className={
                  currentStep == 1 &&
                  "animate__animated animate__fadeIn animate__faster"
                }
                style={{ display: currentStep == 1 ? "block" : "none" }}
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/N-yJypPvBN0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>

              <iframe
                className={
                  currentStep == 2 &&
                  "animate__animated animate__fadeIn animate__faster"
                }
                style={{ display: currentStep == 2 ? "block" : "none" }}
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/-dAI9PIORwo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <iframe
                className={
                  currentStep == 3 &&
                  "animate__animated animate__fadeIn animate__faster"
                }
                style={{ display: currentStep == 3 ? "block" : "none" }}
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/sWD7pBJl5Go"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <iframe
                className={
                  currentStep == 4 &&
                  "animate__animated animate__fadeIn animate__faster"
                }
                style={{ display: currentStep == 4 ? "block" : "none" }}
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/4saEuqhpKWc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <img
                src="https://i.ytimg.com/vi/yq1d_NHtwOI/maxresdefault.jpg"
                style={{ display: "none" }}
              />
              <img
                src="https://i.ytimg.com/vi/ejB-_jF4fds/maxresdefault.jpg"
                style={{ display: "none" }}
              />
              
            </li>
            <li>
              <img src="https://i.ytimg.com/vi/yq1d_NHtwOI/maxresdefault.jpg" />
            </li>
            <li>
              <img src="https://i.ytimg.com/vi/ejB-_jF4fds/maxresdefault.jpg" />
            </li>
          </ul> */}
        </div>
      )}
      <Helmet>
        {props.parametrization.productName && (
          <title>
            Ihr {props.parametrization.productName} von{" "}
            {props.parametrization.companyName}
          </title>
        )}
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content="Ihr 3D-Erlebnis." />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        {props.parametrization.branding.faviconUrl && (
          <meta
            property="og:image"
            content={props.parametrization.branding.faviconUrl}
          />
        )}
        {props.parametrization.branding.faviconUrl && (
          <link
            rel="shortcut icon"
            href={props.parametrization.branding.faviconUrl}
          />
        )}
        <meta
          property="og:title"
          content={
            props.parametrization.branding.tabTitle
              ? props.parametrization.branding.tabTitle
              : "Ynflow | Konfigurieren Sie Sich Ihr Produkt"
          }
        />
        <meta
          property="og:description"
          content={
            props.parametrization.branding.tabTitle
              ? props.parametrization.branding.tabTitle
              : "Ynflow | Konfigurieren Sie Sich Ihr Produkt"
          }
        />
        <meta
          property="og:url"
          content={
            props.parametrization.branding.customDomain
              ? props.parametrization.branding.customDomain
              : "https://ynflow.com"
          }
        />
        <title>
          {props.parametrization.branding.tabTitle
            ? props.parametrization.branding.tabTitle
            : "Ynflow | Konfigurieren Sie Sich Ihr Produkt"}
        </title>
      </Helmet>
      <Drawer
        open={overviewModalOpen}
        overlayClassName={"drawer-overlay"}
        onClose={() => setOverviewModalOpen(false)}
        direction="right"
        className="info-drawer"
        size={window.innerWidth > 1000 ? "40vw" : "80vw"}
        style={{
          zIndex: "999999999999",
          overflowY: "scroll",
          padding: innerWidth > 800 ? "10vh 7vh" : "15vh 7vh 10vh 7vh",
        }}
      >
        <div>
        <div
              onClick={() => {
                setOverviewModalOpen(false);
              }}
              style={{
                top: "calc(40px)",
                cursor: "pointer",
                right: innerWidth > 800 ? "calc(70px)" : "30px",
                height: "30px",
                //background: "rgba(246,246,246,0.5)",

                zIndex: 999999999999,
                position: "absolute",
                display: "flex",
                borderRadius: "30px",
                padding: "13px",
                paddingBottom:  innerWidth > 800 ?"30px": "70px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginTop: "-2px",
                  marginRight: "5px",
                }}
              >
                schließen
              </span>
              <CloseIcon width={"25px"} strokewidth={3} />{" "}
            </div>
          <h3 style={{ color: "#5a5a72", fontWeight: "normal" }}>Ihre Konfiguration</h3>
          {verboseConfiguration && (
            <Table aria-label="table variants" variant={"plain"}>
              <thead>
                <tr>
                  {/* <th style={{ width: '40%' }}>Option</th> */}
                  <th>Merkmal</th>
                  <th>Ihre Auswahl</th>
                  {/* <th>Carbs&nbsp;(g)</th>
            <th>Protein&nbsp;(g)</th> */}
                </tr>
              </thead>
              <tbody>
                {Object.keys(verboseConfiguration).map((k) => (
                  
                  <tr key={k}>
                    <td>{k}</td>
                    <td>
                      {verboseConfiguration[k]}
                    </td>
                    {/* <td>{row.fat}</td>
              <td>{row.carbs}</td>
              <td>{row.protein}</td> */}
                  </tr>
                ))}
                {props.parametrization.pricing && <tr><td><b>Summe</b></td><td><b><CurrencyFormat
                        suffix={" €"}
                        displayType={"text"}
                        decimalSeparator={","}
                        
                        thousandSeparator={"."}
                        value={Math.round(price,0)}
                      /></b></td></tr>}
              </tbody>
            </Table>
          )}
          {props.parametrization.id == "ALix3WF4dSsrubYu2NBH" && <p style={{fontSize: "14px", color: "#25252d"}}><br/>Die Elektroinstallation ist bei jedem WOHNRAUM KUBUS mit inbegriffen.</p>}
        <span style={{float: "right", marginTop: "40px", paddingBottom: "100px"}}>{SimpleSubmitButton}</span>
        </div>
      </Drawer>
      <FadeInModal
        // width={"300px"}
        children={infoModalText}
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
      />

      <Box
        //component={Card}
        sx={{
          position: "fixed",
          width: innerWidth > 800 ? "90px" : "60px",
          top: innerWidth > 800 ? "130px" : "120px",
          left: innerWidth > 800 ? "48px" : "15px",
          right: innerWidth > 800 ? "auto" : "20px",
        }}
      >
        <ShareModalDialog
          setOverviewModalOpen={setOverviewModalOpen}
          storedConfig={storedConfig}
          configuration={configuration}
          submitted={submitted}
          toggleConfigDelta={toggleConfigDelta}
          configDelta={configDelta}
          scene={props.scene}
          price={price}
          open={open}
          verboseConfiguration={verboseConfiguration}
          setOpen={setOpen}
          showDocs={props.parametrization.showDocs}
          setInfoModalOpen={setInfoModalOpen}
          {...props}
        />
       
        {props.tools.map((t) => {
          return <>{t}</>;
        })}
        {props.parametrization.enableAR && innerWidth < 800 && (
          <Box
            className={"docs-button"}
            onClick={(e) => {
              //  props.setInfoModalOpen(true);
              setGlbDownloadUrl(null);
              download(props.scene);
              
            }}
            sx={{ cursor: "pointer", mt: "12px", userSelect: "none" }}
          >
            <Tooltip title="AR starten" placement="right">
            <span
              style={{
                color: "#333",
                background: "white",
                borderRadius: "50px",
                display: "inline-block",
                width: "40px",
                height: "40px",
                boxShadow: props.parametrization.branding?.boxShadow
                  ? props.parametrization.branding.boxShadow
                  : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                border: props.parametrization.branding?.border
                  ? props.parametrization.branding.border
                  : "none",
              }}
            >
              <center style={{ marginTop: "5px", marginLeft: "0px" }}>
                {" "}
                {glbDownloadUrl == null?   <CircularProgress
          size={"sm"}
          sx={{
            marginTop: "4px",
            color: "#444",
            width: "21px",
            height: "21px",
          }}
        />:<AugmentedReality width={"25px"} strokewidth={2} />}
              </center>
            </span>
             
            </Tooltip>
            {}
            {/* {!user && "save & "} */}
          </Box>
        )}
      </Box>
      <Toaster
        id={"other-toaster"}
        expand
        richColors
        visibleToasts={1}
        closeButton
        position="bottom-center"
        style={{
          marginBottom: "220px",
          zIndex: 0,
          position: "absolute",
          width: "auto",
        }}
      />

      <div
        id={"menu"}
        className={"animate__animated"}
        // onTouchStart={onTouchStart}
        // onTouchMove={onTouchMove}
        // onTouchEnd={onTouchEnd}
      >
        {/* {!largeMenu && (
          <Box
            className={"animate__animated animate__fadeIn animate__slower"}
            onClick={(e) => {
              console.log(e);
            }}
            element={"div"}
            sx={{
              marginTop: "-45px",
              display: "flex",
              cursor: "pointer",
              position: "relative",
              width: "150px",
              margin:
                innerWidth > 800
                  ? "-45px auto auto auto"
                  : "-101px auto auto auto",
              zIndex: "1000",
            }}
          >
            <Box
              onClick={() => {
                toggleLargeMenu(!largeMenu);
                props.toggleLargeMenu(!largeMenu);
              }}
              element={"span"}
              sx={{
                mx: "auto",
                ml: innerWidth < 800 ? "auto" : "auto",
                marginTop: "45px",
              }}
            >
              <hr
                style={{
                  backgroundColor: "#aaaaaa",
                  cursor: "pointer",
                  border: "2px solid #aaaaaa",
                  borderRadius: "7px",
                  height: "0px",
                  width: "40px",
                }}
              />
        
            </Box>
          </Box>
        )} */}
        {/* {largeMenu && (
          <Box
            className={"animate__animated animate__fadeInRight animate__slow"}
            element={"div"}
            sx={{
              top: "20px",
              right: "20px",
              display: "flex",
              cursor: "pointer",
              position: "fixed",
              zIndex: "2000",
            }}
          >
            <Box
              onClick={() => {
                toggleLargeMenu(!largeMenu);
                props.toggleLargeMenu(!largeMenu);
              }}
              element={"span"}
              sx={{ mx: "auto" }}
            >
              {" "}
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  fontSize: "2.1rem",
                  display: "block",
                  mx: "auto",
                }}
              />
              <Typography
                variant={"overline"}
                sx={{
                  fontSize: "0.75rem",
                  marginTop: "-7px",
                  display: "block",
                  textAlign: "center",
                }}
              >
                close
              </Typography>
            </Box>
          </Box>
          
        )} */}

        <Lightbox
          index={index}
          slides={currentImages}
          controller={{ closeOnBackdropClick: true, closeOnPullDown: true }}
          zoom
          share
          carousel={{
            finite: currentImages.length == 1,
          }}
          open={index >= 0}
          close={() => setIndex(-1)}
        />

        <Box
          sx={{
            display: "block",
            position: !largeMenu ? "fixed" : "relative",
            right: !largeMenu ? 0 : "auto",
            zIndex: 1500,
            padding: innerWidth > 800 ? "20px" : "0 10px 0 0",
            top:
              innerWidth > 800
                ? !largeMenu
                  ? "155px"
                  : "auto"
                : !largeMenu
                ? "auto"
                : "auto",
            top: innerWidth > 800 ? "20px" : "20px",
            textAlign: !largeMenu ? "right" : "center",
            paddingBottom: !largeMenu ? "auto" : "60px",
          }}
        >
          {/* .btn-grad {
            background-image: linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%);
            margin: 10px;
            padding: 15px 45px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
          }

          .btn-grad:hover {
            background-position: right center; 
            color: #fff;
            text-decoration: none;
          } */}

          {SubmitButton}
        </Box>
        {!storedConfig || !storedConfig.submitted ? (
          <>
            <BottomSheetDrawer
              toggleLargeMenu={toggleLargeMenu}
              largeMenu={largeMenu}
              embedded={props.embedded}
              sheetRef={sheetRef}
              navigation={
                props.parametrization["menuConfiguration"].length >= 6
              }
            >
              <Grid
                onClick={() => {
                  if (isOpen) {
                    console.log("running on clickyyy");
                  }
                }}
                container
                sx={{
                  height: largeMenu ? "auto" : "230px",
                  margin: "auto",
                  // marginTop: (largeMenu? (-window.innerHeight)+300 + "px": 0 ),
                  position: "absolute",
                  // bottom: 0,
                  backgroundColor: "rgb(250,250,250)",
                  transition: "height .5s ease-in-out",
                  display: "flex",
                  paddingBottom: largeMenu ? "100px" : "auto",
                  zIndex: !largeMenu ? 300 : 1500,
                  // borderTop: "1px solid #ccc",
                }}
              >
                <Grid
                  xs={12}
                  //md={0}
                  md={0}
                  //lg={1.5}
                  sx={{ backgroundColor: "white" }}
                ></Grid>
                <Grid
                  xs={12}
                  // md={10}
                  // lg={9}
                  sx={{
                    margin: "auto",
                    height: "100%",
                    textAlign: "center",
                    backgroundColor:
                      window.location.href.indexOf("embed") > -1
                        ? "rgb(250,250,250)"
                        : "white",
                  }}
                >
                  <Box sx={{ margin: "auto", height: "100%" }}>
                    {props.parametrization.showProgress &&
                      !user &&
                      !configurationId && (
                        <Box>
                          <Box
                          className={"animated-progress"}
                            sx={{
                              width:
                                18 +
                                (82 * (props.currentStep + 1)) /
                                  (props.parametrization["menuConfiguration"][0]
                                    .numOrder != null
                                    ? props.parametrization["menuConfiguration"]
                                        .filter((i) => !i.hide)
                                        .sort((a, b) => a.numOrder - b.numOrder)
                                    : props.parametrization[
                                        "menuConfiguration"
                                      ].filter((i) => !i.hide)
                                  ).length +
                                "vw",
                              borderRadius: "5px",
                              //border: "1px solid lightgrey",
                              left: 0,
                              transition: "width .8s ease-in-out",
                              position: "absolute",
                              height: "3px",
                              // background:
                              //   `rgba(100, 210, 100,` +
                              //   1 +
                              //   //(
                              //   // 0.4 +
                              //   // (0.6 * (props.currentStep + 1)) /
                              //   //   (props.parametrization[
                              //   //     "menuConfiguration"
                              //   //   ][0].numOrder != null
                              //   //     ? props.parametrization[
                              //   //         "menuConfiguration"
                              //   //       ]
                              //   //         .filter((i) => !i.hide)
                              //   //         .sort(
                              //   //           (a, b) => a.numOrder - b.numOrder
                              //   //         )
                              //   //     : props.parametrization[
                              //   //         "menuConfiguration"
                              //   //       ].filter((i) => !i.hide)
                              //   //   ).length)
                              //   ")",
                              marginTop: "-39px",
                              display: "block",
                            }}
                          >
                            {/* <Typography
                        level="body-xs"
                        //fontWeight="xl"
                        textColor="common.white"
                        sx={{
                          float: "right",
                          color: "#333",
                          paddingLeft: "65px",
                          marginRight: "-120px",
                          // mixBlendMode: "difference",
                          fontSize: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        fertig in{" "}
                        <CountTo
                          color={"#333"}
                          fontSize={"10px"}
                          suffix={""}
                          to={Math.floor(
                            (210 -
                              (210 * (props.currentStep + 1)) /
                                (props.parametrization["menuConfiguration"][0]
                                  .numOrder != null
                                  ? props.parametrization["menuConfiguration"]
                                      .filter((i) => !i.hide)
                                      .sort((a, b) => a.numOrder - b.numOrder)
                                  : props.parametrization[
                                      "menuConfiguration"
                                    ].filter((i) => !i.hide)
                                ).length) /
                              60
                          )}
                        />
                        :
                        {Math.floor(
                          (Math.floor(
                            210 -
                              (210 * (props.currentStep + 1)) /
                                (props.parametrization["menuConfiguration"][0]
                                  .numOrder != null
                                  ? props.parametrization["menuConfiguration"]
                                      .filter((i) => !i.hide)
                                      .sort((a, b) => a.numOrder - b.numOrder)
                                  : props.parametrization[
                                      "menuConfiguration"
                                    ].filter((i) => !i.hide)
                                ).length
                          ) -
                            Math.floor(
                              (210 -
                                (210 * (props.currentStep + 1)) /
                                  (props.parametrization["menuConfiguration"][0]
                                    .numOrder != null
                                    ? props.parametrization["menuConfiguration"]
                                        .filter((i) => !i.hide)
                                        .sort((a, b) => a.numOrder - b.numOrder)
                                    : props.parametrization[
                                        "menuConfiguration"
                                      ].filter((i) => !i.hide)
                                  ).length) /
                                60
                            ) *
                              60) /
                            15
                        ) *
                          15 <=
                        9 ? (
                          <span
                            style={{
                              color: "#333",
                              fontSize: "10px",
                              fontWeight: "normal",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          ""
                        )}
                        <CountTo
                          color={"#333"}
                          fontSize={"10px"}
                          suffix={""}
                          to={
                            Math.floor(
                              (Math.floor(
                                210 -
                                  (210 * (props.currentStep + 1)) /
                                    (props.parametrization[
                                      "menuConfiguration"
                                    ][0].numOrder != null
                                      ? props.parametrization[
                                          "menuConfiguration"
                                        ]
                                          .filter((i) => !i.hide)
                                          .sort(
                                            (a, b) => a.numOrder - b.numOrder
                                          )
                                      : props.parametrization[
                                          "menuConfiguration"
                                        ].filter((i) => !i.hide)
                                    ).length
                              ) -
                                Math.floor(
                                  (210 -
                                    (210 * (props.currentStep + 1)) /
                                      (props.parametrization[
                                        "menuConfiguration"
                                      ][0].numOrder != null
                                        ? props.parametrization[
                                            "menuConfiguration"
                                          ]
                                            .filter((i) => !i.hide)
                                            .sort(
                                              (a, b) => a.numOrder - b.numOrder
                                            )
                                        : props.parametrization[
                                            "menuConfiguration"
                                          ].filter((i) => !i.hide)
                                      ).length) /
                                    60
                                ) *
                                  60) /
                                15
                            ) * 15
                          }
                        />{" "}
                       Minuten
                      </Typography> */}
                            {!largeMenu && (
                              <Typography
                                variant={"overline"}
                                style={{
                                  float: "left",
                                  marginLeft: "10px",
                                  fontSize: "0.77rem",
                                  color: "#333",
                                  width: "100px",
                                  textAlign: "left",
                                  display: "inline-block",
                                  lineHeight: "1.2",
                                  marginTop: "10px",
                                  paddingLeft: "10px",
                                  marginBottom: "-5px",
                                  position:
                                    innerWidth > 800 ? "relative" : "fixed",
                                  bottom: innerWidth > 800 ? null : "20px",
                                  left: innerWidth > 800 ? null : "0px",
                                }}
                              >
                                Fortschritt
                                <br />
                                <span
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "16px",
                                    color: "#444",
                                    display: "inline-block",
                                    marginTop: "-5px",
                                  }}
                                >
                                  <CountTo
                                    suffix={""}
                                    fontSize={"16px"}
                                    color={"#333"}
                                    to={
                                      18 +
                                      (82 * (props.currentStep + 1)) /
                                        (props.parametrization[
                                          "menuConfiguration"
                                        ][0].numOrder != null
                                          ? props.parametrization[
                                              "menuConfiguration"
                                            ]
                                              .filter((i) => !i.hide)
                                              .sort(
                                                (a, b) =>
                                                  a.numOrder - b.numOrder
                                              )
                                          : props.parametrization[
                                              "menuConfiguration"
                                            ].filter((i) => !i.hide)
                                        ).length
                                    }
                                  />{" "}
                                  %
                                </span>
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                    {!largeMenu && (
                      <Slider
                        {...{
                          dots:
                            props.parametrization["menuConfiguration"].length >=
                              6 && innerWidth > 800
                              ? true
                              : false,
                          adaptiveHeight: false,
                          className: "control-slider",
                          // customPaging: function (index) {
                          //   return (
                          //     <span className={"nav-dot"}>
                          //       <a
                          //         //onClick={()=>{}}

                          //         style={{
                          //           color: "#aaa",
                          //           fontSize: "10px",
                          //           textTransform: "uppercase",
                          //         }}
                          //       >
                          //         {" "}
                          //         {
                          //           (props.parametrization["menuConfiguration"][0]
                          //           .numOrder != null
                          //           ? props.parametrization["menuConfiguration"]
                          //               .filter((i) => !i.hide)
                          //               .sort((a, b) => a.numOrder - b.numOrder)
                          //           : props.parametrization[
                          //               "menuConfiguration"
                          //             ].filter((i) => !i.hide)
                          //         )[index]
                          //             .title
                          //         }
                          //       </a>
                          //       {index ==
                          //         (props.parametrization["menuConfiguration"][0]
                          //           .numOrder != null
                          //           ? props.parametrization["menuConfiguration"]
                          //               .filter((i) => !i.hide)
                          //               .sort((a, b) => a.numOrder - b.numOrder)
                          //           : props.parametrization[
                          //               "menuConfiguration"
                          //             ].filter((i) => !i.hide)
                          //         ).length -
                          //           1 && (
                          //         <a
                          //           onClick={(e) => {
                          //             e.preventDefault();
                          //             e.stopPropagation();
                          //             sheetRef.current.snapTo(0);
                          //           }}
                          //           className={"nav-dot"}
                          //           style={{
                          //             color: "#aaa",
                          //             fontSize: "10px",
                          //             marginLeft: "8px",
                          //             textTransform: "uppercase",
                          //           }}
                          //         >
                          //           {" "}
                          //           alle
                          //         </a>
                          //       )}
                          //     </span>
                          //   );
                          // },
                          infinite: false,
                          draggable: false,
                          swipe: false,
                          speed: 850,
                          touchThreshold: 11,
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          customPaging: function (index) {
                            return (
                              <Tooltip
                                sx={{
                                  background: "rgba(80,80,80,1)",
                                  fontSize: "0.76rem",
                                }}
                                title={
                                  (props.parametrization["menuConfiguration"][0]
                                    .numOrder != null
                                    ? props.parametrization["menuConfiguration"]
                                        .filter((i) => !i.hide)
                                        .sort((a, b) => a.numOrder - b.numOrder)
                                    : props.parametrization[
                                        "menuConfiguration"
                                      ].filter((i) => !i.hide))[index].title
                                }
                              >
                                <li title={"lalal"}>
                                  <button></button>
                                </li>
                              </Tooltip>
                            );
                          },

                          vertical: true,
                          beforeChange: (current, next) => {
                            setFieldMenuVisibleIndex(next > 0 ? next : 0);
                            setPreviousStep(current);
                            setCurrentStep(next > 0 ? next : 0);
                            props.setCurrentStep(next > 0 ? next : 0);
                            // console.log("next slide: ", next);
                            // console.log(
                            //   "Searching in ",
                            //   props.parametrization["menuConfiguration"][
                            //     next > 0 ? next : 0
                            //   ].meshesInvisibleWhenActive
                            // );
                            try {
                              for (let name of (props.parametrization[
                                "menuConfiguration"
                              ][0].numOrder != null
                                ? props.parametrization["menuConfiguration"]
                                    .filter((i) => !i.hide)
                                    .sort((a, b) => a.numOrder - b.numOrder)
                                : props.parametrization[
                                    "menuConfiguration"
                                  ].filter((i) => !i.hide))[current]
                                .meshesInvisibleWhenActive) {
                                console.log("SETTING VISIBLE ", name);
                                let obj = props.scene.getObjectByName(name);
                                if (obj instanceof THREE.Group) {
                                  for (let cld of obj.children) {
                                    cld.visible = true;
                                  }
                                }
                                obj.visible = true;
                              }
                            } catch (e) {
                              console.log(e);
                            }
                            try {
                              for (let name of (props.parametrization[
                                "menuConfiguration"
                              ][0].numOrder != null
                                ? props.parametrization["menuConfiguration"]
                                    .filter((i) => !i.hide)
                                    .sort((a, b) => a.numOrder - b.numOrder)
                                : props.parametrization[
                                    "menuConfiguration"
                                  ].filter((i) => !i.hide))[next]
                                .meshesInvisibleWhenActive) {
                                // console.log("SETTING INVISIBLE ", name);
                                let obj = props.scene.getObjectByName(name);
                                if (obj instanceof THREE.Group) {
                                  for (let cld of obj.children) {
                                    // console.log("got this child!", cld);
                                    cld.visible = false;
                                  }
                                }
                                obj.visible = false;
                              }
                            } catch (e) {
                              console.log(e);
                            }
                            // setting opacity focus
                            try {
                              if (
                                props.parametrization["menuConfiguration"][next]
                                  .focusOnMeshes
                              ) {
                                props.scene.traverse((obj) => {
                                  if (
                                    props.parametrization["menuConfiguration"][
                                      next
                                    ].focusOnMeshes.includes(obj.name)
                                  ) {
                                    // console.log("found ", obj.name);
                                    try {
                                      obj.material.opacity = 1;
                                      obj.material.transparent = true;
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  } else {
                                    try {
                                      obj.material.opacity = 0.025;
                                      obj.material.transparent = true;
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  }
                                });
                              }
                            } catch (e) {
                              console.log(e);
                            }

                            try {
                              if (
                                props.parametrization["menuConfiguration"][
                                  current
                                ].focusOnMeshes
                              ) {
                                props.scene.traverse((obj) => {
                                  if (
                                    props.parametrization["menuConfiguration"][
                                      current
                                    ].focusOnMeshes.includes(obj.name)
                                  ) {
                                    console.log("found ", obj.name);
                                    try {
                                      obj.material.opacity = 1;
                                      obj.material.transparent = true;
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  } else {
                                    try {
                                      obj.material.opacity = 1;
                                      obj.material.transparent = true;
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  }
                                });
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          },
                          nextArrow:
                            (props.parametrization["menuConfiguration"][0]
                              .numOrder != null
                              ? props.parametrization["menuConfiguration"]
                                  .filter((i) => !i.hide)
                                  .sort((a, b) => a.numOrder - b.numOrder)
                              : props.parametrization[
                                  "menuConfiguration"
                                ].filter((i) => !i.hide)
                            ).length !=
                            currentStep + 1 ? (
                              <MenuArrowForward
                                id={
                                  currentStep == 0 &&
                                  !previousStep &&
                                  !configurationId
                                    ? "defaultNext"
                                    : ""
                                }
                                title={"weiter"}
                                sx={{
                                  animationDelay: "4s",
                                  // animation:
                                  //   currentStep == 0 &&
                                  //   !previousStep &&
                                  //   !configurationId
                                  //     ? "pulsate-grey 1.5s infinite"
                                  //     : "",
                                  zIndex: 9999999999999,
                                  border:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "1px solid #333"
                                      : "none",
                                  borderRadius:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "50%"
                                      : "50%",
                                  fontSize: "2.0rem",
                                  color: "#333",
                                  "&:hover": {
                                    color: "#777",
                                    borderColor: "#111",
                                  },
                                  stroke: "#ffffff",
                                  strokeWidth: 1,
                                  marginTop: "-9px",
                                  marginRight:
                                    innerWidth < 1000 ? "20px" : "34%",
                                }}
                              />
                            ) : (
                              <MenuArrowForward
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                title={"weiter"}
                                sx={{
                                  border:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "1px solid #aaa"
                                      : "none",
                                  borderRadius:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "50%"
                                      : "none",
                                  fontSize: "2.0rem",
                                  color: "#aaa",
                                  "&:hover": {
                                    color: "#aaa",
                                    borderColor: "#aaa",
                                  },
                                  cursor: "not-allowed",
                                  zIndex: 9999999999999,

                                  stroke: "#ffffff",
                                  strokeWidth: 1,
                                  marginTop: "-9px",
                                  marginRight:
                                    innerWidth < 1000 ? "20px" : "34%",
                                }}
                              />
                            ),
                          prevArrow:
                            currentStep != 0 ? (
                              <MenuArrowBackward
                                title={"zurück"}
                                sx={{
                                  border:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "1px solid #333"
                                      : "none",
                                  borderRadius:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "50%"
                                      : "none",
                                  fontSize: "2.0rem",
                                  color: "#333",
                                  zIndex: 9999999999999,

                                  "&:hover": { color: "#999" },
                                  stroke: "#ffffff",
                                  strokeWidth: 1,
                                  marginTop: "-9px",
                                  marginLeft:
                                    innerWidth < 1000 ? "20px" : "34%",
                                }}
                              />
                            ) : (
                              <MenuArrowBackward
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                title={"weiter"}
                                sx={{
                                  border:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "1px solid #aaa"
                                      : "none",
                                  borderRadius:
                                    MenuArrowForward ==
                                    KeyboardArrowRightOutlinedIcon
                                      ? "50%"
                                      : "none",
                                  fontSize: "2.0rem",
                                  color: "#aaa",
                                  "&:hover": {
                                    color: "#aaa",
                                    borderColor: "#aaa",
                                  },
                                  cursor: "not-allowed",
                                  stroke: "#ffffff",
                                  zIndex: 9999999999999,

                                  strokeWidth: 1,
                                  marginTop: "-9px",
                                  marginLeft:
                                    innerWidth < 1000 ? "20px" : "34%",
                                }}
                              />
                            ),
                        }}
                        ref={controlSliderRef}
                        asNavFor={sliderRef}
                      >
                        {(props.parametrization["menuConfiguration"][0]
                          .numOrder != null
                          ? props.parametrization["menuConfiguration"]
                              .filter((i) => !i.hide)
                              .sort((a, b) => a.numOrder - b.numOrder)
                          : props.parametrization["menuConfiguration"].filter(
                              (i) => !i.hide
                            )
                        ).map((field, key) => {
                          return (
                            <div
                              key={key}
                              style={{
                                color: "#333",
                              }}
                              title={
                                submitted
                                  ? "Konfiguration wurde bereits abgeschlossen"
                                  : ""
                              }
                            >
                              <Box
                                sx={{
                                  // height: "295px",
                                  paddingTop:
                                    largeMenu && key == 0
                                      ? // "120px"
                                        "0"
                                      : "0",
                                  // paddingBottom: "50px",

                                  margin: "auto",

                                  display: "block",
                                  cursor: submitted
                                    ? "not-allowed !important"
                                    : "auto",
                                }}
                                className={submitted ? "cursor-disabled" : ""}
                              >
                                <h5
                                  variant={"h5"}
                                  style={{
                                    color: "#333",
                                    fontSize: "1.15rem",
                                    // fontFamily: "'Lato', sans-serif",
                                    fontWeight: 500,
                                    display: "block",
                                    height: "20px",
                                    marginTop: "-4px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {field.verboseTitle
                                    ? field.verboseTitle
                                    : field.title}{" "}
                                  {!props.parametrization.hideSteps &&
                                    !(
                                      props.parametrization.demo && key == 0
                                    ) && (
                                      <Typography
                                        variant={"overline"}
                                        sx={{
                                          color: "#666",
                                          display: "inline",
                                          height: "10px",
                                          marginBottom: "20px",
                                          fontSize: "1.1rem",
                                          marginLeft: "3px",
                                        }}
                                      >
                                        <span style={{ color: "#666" }}>
                                          {props.parametrization.demo
                                            ? key
                                            : key + 1}
                                          /
                                        </span>
                                        {props.parametrization.demo
                                          ? configuration.filter((i) => !i.hide)
                                              .length - 1
                                          : configuration.filter((i) => !i.hide)
                                              .length}
                                      </Typography>
                                    )}
                                  {field.info && (
                                    <Box
                                      component={"span"}
                                      sx={{ "& :hover": { color: "black" } }}
                                    >
                                      <InfoIcon
                                        sx={{
                                          display: "inline-block",
                                          cursor: "pointer",
                                          marginBottom: "-2px",
                                          marginLeft: "5px",
                                          fontSize: "1rem",
                                          "& :hover": { color: "black" },
                                          color: "grey",
                                        }}
                                        onClick={() => {
                                          // setInfoModalText(field.info);

                                          setInfoModalOpen(true);
                                          setTimeout(() => {
                                            document
                                              .getElementById(field.title)
                                              .scrollIntoView();
                                          }, 300);
                                        }}
                                      />
                                    </Box>
                                  )}
                                </h5>
                              </Box>
                            </div>
                          );
                        })}
                      </Slider>
                    )}

                    <Slider
                      ref={(slider1) => setSliderRef(slider1)}
                      //ref={sliderRef}

                      {...{
                        dots:
                          props.parametrization["menuConfiguration"].length >=
                            6 && innerWidth > 800
                            ? false
                            : false,
                        adaptiveHeight: false,
                        // customPaging: function (index) {
                        //   return (
                        //     <span className={"nav-dot"}>
                        //       <a
                        //         //onClick={()=>{}}

                        //         style={{
                        //           color: "#aaa",
                        //           fontSize: "10px",
                        //           textTransform: "uppercase",
                        //         }}
                        //       >
                        //         {" "}
                        //         {
                        //           (props.parametrization["menuConfiguration"][0]
                        //           .numOrder != null
                        //           ? props.parametrization["menuConfiguration"]
                        //               .filter((i) => !i.hide)
                        //               .sort((a, b) => a.numOrder - b.numOrder)
                        //           : props.parametrization[
                        //               "menuConfiguration"
                        //             ].filter((i) => !i.hide)
                        //         )[index]
                        //             .title
                        //         }
                        //       </a>
                        //       {index ==
                        //         (props.parametrization["menuConfiguration"][0]
                        //           .numOrder != null
                        //           ? props.parametrization["menuConfiguration"]
                        //               .filter((i) => !i.hide)
                        //               .sort((a, b) => a.numOrder - b.numOrder)
                        //           : props.parametrization[
                        //               "menuConfiguration"
                        //             ].filter((i) => !i.hide)
                        //         ).length -
                        //           1 && (
                        //         <a
                        //           onClick={(e) => {
                        //             e.preventDefault();
                        //             e.stopPropagation();
                        //             sheetRef.current.snapTo(0);
                        //           }}
                        //           className={"nav-dot"}
                        //           style={{
                        //             color: "#aaa",
                        //             fontSize: "10px",
                        //             marginLeft: "8px",
                        //             textTransform: "uppercase",
                        //           }}
                        //         >
                        //           {" "}
                        //           alle
                        //         </a>
                        //       )}
                        //     </span>
                        //   );
                        // },

                        infinite: false,
                        draggable: false,
                        swipe: false,
                        speed: 850,
                        touchThreshold: 11,
                        slidesToShow: largeMenu ? configuration.length : 1,
                        slidesToScroll: 1,

                        vertical: largeMenu ? true : false,
                        beforeChange: (current, next) => {
                          setFieldMenuVisibleIndex(next > 0 ? next : 0);
                          setPreviousStep(current);
                          setCurrentStep(next);
                          props.setCurrentStep(next);
                          console.log("next slide: ", next);
                          console.log(
                            "Searching in ",
                            (props.parametrization["menuConfiguration"][0]
                              .numOrder != null
                              ? props.parametrization["menuConfiguration"]
                                  .filter((i) => !i.hide)
                                  .sort((a, b) => a.numOrder - b.numOrder)
                              : props.parametrization[
                                  "menuConfiguration"
                                ].filter((i) => !i.hide))[next]
                              .meshesInvisibleWhenActive
                          );
                          try {
                            for (let name of (props.parametrization[
                              "menuConfiguration"
                            ][0].numOrder != null
                              ? props.parametrization["menuConfiguration"]
                                  .filter((i) => !i.hide)
                                  .sort((a, b) => a.numOrder - b.numOrder)
                              : props.parametrization[
                                  "menuConfiguration"
                                ].filter((i) => !i.hide))[current]
                              .meshesInvisibleWhenActive) {
                              console.log("SETTING VISIBLE ", name);
                              let obj = props.scene.getObjectByName(name);
                              if (obj instanceof THREE.Group) {
                                for (let cld of obj.children) {
                                  cld.visible = true;
                                }
                              }
                              obj.visible = true;
                            }
                          } catch (e) {
                            console.log(e);
                          }
                          try {
                            for (let name of (props.parametrization[
                              "menuConfiguration"
                            ][0].numOrder != null
                              ? props.parametrization["menuConfiguration"]
                                  .filter((i) => !i.hide)
                                  .sort((a, b) => a.numOrder - b.numOrder)
                              : props.parametrization[
                                  "menuConfiguration"
                                ].filter((i) => !i.hide))[next]
                              .meshesInvisibleWhenActive) {
                              console.log("SETTING INVISIBLE ", name);
                              let obj = props.scene.getObjectByName(name);
                              if (obj instanceof THREE.Group) {
                                for (let cld of obj.children) {
                                  console.log("got this child!", cld);
                                  cld.visible = false;
                                }
                              }
                              obj.visible = false;
                            }
                          } catch (e) {
                            console.log(e);
                          }
                          // setting opacity focus
                          try {
                            if (
                              props.parametrization["menuConfiguration"][next]
                                .focusOnMeshes
                            ) {
                              props.scene.traverse((obj) => {
                                if (
                                  props.parametrization["menuConfiguration"][
                                    next
                                  ].focusOnMeshes.includes(obj.name)
                                ) {
                                  console.log("found ", obj.name);
                                  try {
                                    obj.material.opacity = 1;
                                    obj.material.transparent = true;
                                  } catch (e) {
                                    console.log(e);
                                  }
                                } else {
                                  try {
                                    obj.material.opacity = 0.025;
                                    obj.material.transparent = true;
                                  } catch (e) {
                                    console.log(e);
                                  }
                                }
                              });
                            }
                          } catch (e) {
                            console.log(e);
                          }

                          try {
                            if (
                              props.parametrization["menuConfiguration"][
                                current
                              ].focusOnMeshes
                            ) {
                              props.scene.traverse((obj) => {
                                if (
                                  props.parametrization["menuConfiguration"][
                                    current
                                  ].focusOnMeshes.includes(obj.name)
                                ) {
                                  console.log("found ", obj.name);
                                  try {
                                    obj.material.opacity = 1;
                                    obj.material.transparent = true;
                                  } catch (e) {
                                    console.log(e);
                                  }
                                } else {
                                  try {
                                    obj.material.opacity = 1;
                                    obj.material.transparent = true;
                                  } catch (e) {
                                    console.log(e);
                                  }
                                }
                              });
                            }
                          } catch (e) {
                            console.log(e);
                          }
                        },
                        // nextArrow: (
                        //   (props.parametrization["menuConfiguration"][0]
                        //   .numOrder != null
                        //   ? props.parametrization["menuConfiguration"]
                        //       .filter((i) => !i.hide)
                        //       .sort((a, b) => a.numOrder - b.numOrder)
                        //   : props.parametrization[
                        //       "menuConfiguration"
                        //     ].filter((i) => !i.hide)
                        // ).length != (currentStep+1) ? <ArrowForwardIcon

                        // title={"weiter"}
                        //     sx={{
                        //       fontSize: "2.0rem",
                        //       color: "#333",
                        //       "&:hover": { color: "#111" },
                        //       stroke: "#ffffff",
                        //       strokeWidth: 1,
                        //       marginTop: "-76px",
                        //       marginRight: innerWidth < 800 ? "20px" : "34%",
                        //     }}
                        //   />:<ArrowForwardIcon
                        // onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                        //   title={"weiter"}
                        //       sx={{
                        //         fontSize: "2.0rem",
                        //         color: "#aaa",
                        //         "&:hover": { color: "#aaa" },
                        //         cursor: "not-allowed",
                        //         stroke: "#ffffff",
                        //         strokeWidth: 1,
                        //         marginTop: "-76px",
                        //         marginRight: innerWidth < 800 ? "20px" : "34%",
                        //       }}
                        //     />

                        // ),
                        // prevArrow: (currentStep != 0?
                        //   <ArrowBackIcon
                        //   title={"zurück"}
                        //     sx={{
                        //       fontSize: "2.0rem",
                        //       color: "#333",
                        //       "&:hover": { color: "#111" },
                        //       stroke: "#ffffff",
                        //       strokeWidth: 1,
                        //       marginTop: "-76px",
                        //       marginLeft: innerWidth < 800 ? "20px" : "34%",
                        //     }}
                        //   />:<ArrowBackIcon
                        //   onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                        //     title={"weiter"}
                        //         sx={{
                        //           fontSize: "2.0rem",
                        //           color: "#aaa",
                        //           "&:hover": { color: "#aaa" },
                        //           cursor: "not-allowed",
                        //           stroke: "#ffffff",
                        //       strokeWidth: 1,
                        //       marginTop: "-76px",
                        //       marginLeft: innerWidth < 800 ? "20px" : "34%",
                        //         }}
                        //       />
                        // ),
                        arrows: false,
                      }}
                    >
                      {(props.parametrization["menuConfiguration"][0]
                        .numOrder != null
                        ? props.parametrization["menuConfiguration"]
                            .filter((i) => !i.hide)
                            .sort((a, b) => a.numOrder - b.numOrder)
                        : props.parametrization["menuConfiguration"].filter(
                            (i) => !i.hide
                          )
                      ).map((field, key) => {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "#333",
                            }}
                            title={
                              submitted
                                ? "Konfiguration wurde bereits abgeschlossen"
                                : ""
                            }
                          >
                            <Box
                              sx={{
                                // height: "295px",
                                paddingTop:
                                  largeMenu && key == 0
                                    ? // "120px"
                                      "0"
                                    : "0",
                                // paddingBottom: "50px",

                                margin: "auto",
                                //pointerEvents: submitted? "none":"auto",
                                display: "block",
                                cursor: submitted ? "not-allowed" : "auto",
                              }}
                              className={submitted ? "cursor-disabled" : ""}
                            >
                              {largeMenu && (
                                <Typography
                                  variant={"h5"}
                                  className={
                                    "animate__animated animate__fadeIn"
                                  }
                                  sx={{
                                    color: "#333",
                                    fontSize: "1.15rem",
                                    // fontFamily: "'Lato', sans-serif",
                                    fontWeight: 400,
                                    display: "block",
                                    height: "20px",
                                    marginTop: "30px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {field.verboseTitle
                                    ? field.verboseTitle
                                    : field.title}{" "}
                                  {field.info && (
                                    <InfoIcon
                                      sx={{
                                        display: "inline-block",
                                        cursor: "pointer",
                                        marginBottom: "-2px",
                                        marginLeft: "5px",
                                        fontSize: "1rem",
                                        "& :hover": { color: "black" },
                                        color: "grey",
                                      }}
                                      onClick={() => {
                                        // setInfoModalText(field.info);
                                        setInfoModalOpen(true);
                                      }}
                                    />
                                  )}
                                  {!(
                                    props.parametrization.demo && key == 0
                                  ) && (
                                    <Typography
                                      variant={"overline"}
                                      sx={{
                                        color: "#666",
                                        display: "inline",
                                        height: "10px",
                                        marginBottom: "20px",
                                        fontSize: "1.1rem",
                                        marginLeft: "3px",
                                      }}
                                    >
                                      <span style={{ color: "#666" }}>
                                        {props.parametrization.demo
                                          ? key
                                          : key + 1}
                                        /
                                      </span>
                                      {props.parametrization.demo
                                        ? configuration.filter((i) => !i.hide)
                                            .length - 1
                                        : configuration.filter((i) => !i.hide)
                                            .length}
                                    </Typography>
                                  )}
                                </Typography>
                              )}
                              {field.inputType == "submit" && (
                                <>
                                  <div
                                    style={{
                                      // marginTop: "-4px",
                                      marginBottom: "10px",
                                      padding: "0 10px",
                                      fontSize:
                                        innerWidth > 1000
                                          ? "16px"
                                          : innerWidth > 800
                                          ? "14px"
                                          : "11px",
                                    }}
                                  >
                                    Fertig? Dann schließen Sie Ihre
                                    Konfiguration jetzt ab, damit wir einen
                                    ersten Eindruck von Ihren Vorstellungen
                                    erhalten.
                                    <br /> Wir treten danach schnellstmöglich
                                    mit Ihnen in Kontakt und beraten Sie
                                    persönlich weiter.{" "}
                                  </div>
                                  <div
                                  // className={"animate__animated animate__headShake animate__infinite animate__slow"}
                                  >
                                    {SubmitButton}
                                  </div>
                                </>
                              )}
                              {field.inputType == "color" && (
                                <ColorSelection
                                  currentMenu={props.parametrization}
                                  currentField={configuration[key]}
                                  currentValue={
                                    // (!configurationId || storedConfig) &&
                                    configuration.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"]
                                  }
                                  setCurrentValue={(newValue) => {
                                    console.log(
                                      "updateing color/material: ",
                                      newValue
                                    );
                                    // important to use spread operator here to get fresh reference!
                                    let newArray = [...configuration];
                                    newArray.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"] = newValue;
                                    console.log(
                                      "updateing color/material: ",
                                      newValue
                                    );
                                    // console.log("updating with ", newValue, newArray, key);
                                    !submitted && setConfiguration(newArray);
                                    console.log(
                                      "updateing color/material: ",
                                      newValue
                                    );
                                    // handle color / material changes here
                                    // try {
                                    //   field.associatedMeshesNames.forEach(
                                    //     (meshName) => {
                                    //       console.log("updating ", meshName, newValue)
                                    //       let meshObject =
                                    //         props.scene.getObjectByName(meshName);
                                    //       if (newValue[0] == "#") {
                                    //         // It's a color!
                                    //         meshObject.material.color = new THREE.Color(
                                    //           newValue
                                    //         );
                                    //       } else {
                                    //         // It's a material!
                                    //         console.log("materials are: ", materials)
                                    //         meshObject.material = materials[newValue];
                                    //       }
                                    //     }
                                    //   );
                                    // } catch (e) {
                                    //   console.log(
                                    //     "Could not change color/material: ",
                                    //     e
                                    //   );
                                    // }

                                    //     let base = scene.getObjectByName("Unterbau");
                                    //     let baseValue = configuration.filter((i) => i.title == "Base")[0]
                                    //             .currentValue;
                                    //       baseValue && (base.material = materials[baseValue]);
                                    // // Change color of roof:

                                    //       let roof = scene.getObjectByName("ceiling_low_coffee-stand_0");
                                    //       let roofValue = configuration.filter((i) => i.title == "Roof color")[0]
                                    //         .currentValue;
                                    //       roofValue && (roof.material.color = new THREE.Color(roofValue));
                                  }}
                                  choices={field.choices}
                                />
                              )}

                              {field.inputType == "demo" && (
                                <div
                                  style={{
                                    maxWidth: "650px",
                                    margin: "auto",
                                    height: "140px",
                                    paddingTop: "5px",
                                    fontSize:
                                      innerWidth < 800 ? "0.75rem" : "0.9rem",
                                    color: "#444",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: field.text,
                                  }}
                                ></div>
                              )}
                              {field.inputType == "text" && (
                                <div

                                // onKeyDown={(event) => {
                                //   if (
                                //     event.keyCode === 13 ||
                                //     event.keyCode == 9
                                //   ) {
                                //     sliderRef.slickGoTo(key + 1);
                                //   }
                                //   // else if(event.keyCode === 37) {
                                //   //   sliderRef.slickGoTo(key-1)
                                //   // }
                                // }}
                                >
                                  <ConfiguratorTextField
                                    key={key}
                                    largeMenu={largeMenu}
                                    maxLetters={
                                      configuration[key]["maxLetters"]
                                    }
                                    currentValue={
                                      configuration.filter(
                                        (i) => i.title == field.title
                                      )[0]["currentValue"]
                                    }
                                    currentMenu={configuration[key]}
                                    setCurrentValue={(newValue) => {
                                      // important to use spread operator here to get fresh reference!
                                      let newArray = [...configuration];
                                      newArray.filter(
                                        (i) => i.title == field.title
                                      )[0]["currentValue"] = newValue;
                                      console.log(
                                        "updating with ",
                                        newValue,
                                        newArray,
                                        key
                                      );

                                      !submitted && setConfiguration(newArray);
                                    }}
                                  />
                                </div>
                              )}
                              {field.inputType == "extendableSelect" && (
                                <center>
                                  <ConfiguratorExtendableSelect
                                    key={key}
                                    submitted={submitted}
                                    largeMenu={largeMenu}
                                    options={field.options}
                                    field={field}
                                    parametrization={props.parametrization}
                                    currentValue={
                                      configuration.filter(
                                        (i) => i.title == field.title
                                      )[0]["currentValue"]
                                    }
                                    configuration={configuration}
                                    setConfiguration={props.setConfiguration}
                                    setCurrentValue={(newValue) => {
                                      // important to use spread operator here to get fresh reference!
                                      let newArray = [...configuration];
                                      newArray.filter(
                                        (i) => i.title == field.title
                                      )[0]["currentValue"] = newValue;
                                      // console.log("updating with ", newValue, newArray, key);

                                      !submitted && setConfiguration(newArray);

                                      if (field.inducesOn) {
                                        try {
                                          let prefix = "";
                                          console.log(
                                            "induces on 3: ",
                                            field.inducesOn
                                          );
                                          field.inducesOn.forEach(
                                            (inducesOnFieldTitle) => {
                                              if (
                                                configuration.filter(
                                                  (i) =>
                                                    i.title ==
                                                    inducesOnFieldTitle
                                                )[0].currentValue
                                              ) {
                                                // make every manometer invisible
                                                prefix = "";
                                                props.scene.traverse(
                                                  (child) => {
                                                    // console.log(configuration);
                                                    // console.log(
                                                    //   "check if name includes: ",
                                                    //   inducesOnFieldTitle,
                                                    //   configuration.filter(
                                                    //     (i) =>
                                                    //       i.title == inducesOnFieldTitle
                                                    //   )[0].currentValue
                                                    // );
                                                    if (
                                                      child.name.includes(
                                                        configuration.filter(
                                                          (i) =>
                                                            i.title ==
                                                            inducesOnFieldTitle
                                                        )[0].currentValue
                                                      )
                                                    ) {
                                                      console.log(
                                                        "setting child invisible: ",
                                                        child
                                                      );
                                                      child.visible = false;
                                                      child.layers.disable(0);
                                                      child.layers.enable(1);
                                                    }
                                                  }
                                                );
                                                configuration
                                                  .filter(
                                                    (i) =>
                                                      i.title ==
                                                      inducesOnFieldTitle
                                                  )[0]
                                                  .dependsOn.forEach(
                                                    (dependencyFieldTitle) => {
                                                      let value =
                                                        configuration.filter(
                                                          (i) =>
                                                            i.title ==
                                                            dependencyFieldTitle
                                                        )[0].currentValue;

                                                      prefix = `${
                                                        Array.isArray(value)
                                                          ? value.filter(
                                                              (i) => i
                                                            ).length
                                                          : value
                                                      }|${prefix}`;
                                                    }
                                                  );
                                                prefix =
                                                  prefix +
                                                  configuration.filter(
                                                    (i) =>
                                                      i.title ==
                                                      inducesOnFieldTitle
                                                  )[0].currentValue;
                                                let meshObject =
                                                  props.scene.getObjectByName(
                                                    prefix
                                                  );
                                                console.log(
                                                  "setting visible: ",
                                                  prefix
                                                );
                                                // make correct manometer visible

                                                if (
                                                  meshObject instanceof
                                                  THREE.Group
                                                ) {
                                                  console.log(
                                                    "it's a group",
                                                    meshObject,
                                                    meshObject.children
                                                  );
                                                  meshObject.visible = true;
                                                  for (let cld of meshObject.children) {
                                                    if (
                                                      cld instanceof THREE.Group
                                                    ) {
                                                      console.log(
                                                        "it's a sub-group",
                                                        cld,
                                                        cld.children
                                                      );
                                                      cld.visible = true;
                                                      for (let subcld of cld.children) {
                                                        subcld.visible = true;
                                                        subcld.layers.disable(
                                                          1
                                                        );
                                                        subcld.layers.enable(0);
                                                      }
                                                    } else {
                                                      cld.visible = true;
                                                      cld.layers.disable(1);
                                                      cld.layers.enable(0);
                                                    }
                                                  }
                                                } else {
                                                  meshObject.visible = true;
                                                  meshObject.layers.disable(1);
                                                  meshObject.layers.enable(0);
                                                }
                                              }
                                            }
                                          );
                                        } catch (e) {
                                          console.log(e);
                                        }
                                      }

                                      // handle visibility here!:
                                      let prefix = "";
                                      try {
                                        console.log(
                                          "looking aaaat ...",
                                          field.title
                                        );
                                        field.options.forEach((options) => {
                                          // option.title is the mesh name!
                                          // check if there is a dependency -> concat with pipe along dependsOn array
                                          let meshName = options.title;
                                          prefix = "";
                                          if (
                                            field.dependsOn &&
                                            field.dependsOn.length > 0
                                          ) {
                                            field.dependsOn.forEach(
                                              (dependencyFieldTitle) => {
                                                let value =
                                                  configuration.filter(
                                                    (i) =>
                                                      i.title ==
                                                      dependencyFieldTitle
                                                  )[0].currentValue;

                                                prefix = `${
                                                  Array.isArray(value)
                                                    ? value.filter((i) => i)
                                                        .length
                                                    : value
                                                }|${prefix}`;
                                              }
                                            );
                                          }

                                          let meshObject =
                                            props.scene.getObjectByName(
                                              prefix + options.title
                                            );
                                          console.log(
                                            "setting visible now: ",
                                            prefix + options.title,
                                            meshObject
                                          );
                                          //meshObject.visible = options.title == newValue;
                                          let isVisible =
                                            options.title == newValue;
                                          if (isVisible) {
                                            meshObject.visible = true;
                                            meshObject.layers.disable(1);
                                            meshObject.layers.enable(0);
                                          } else {
                                            meshObject.visible = false;
                                            meshObject.layers.disable(0);
                                            meshObject.layers.enable(1);
                                          }
                                        });
                                      } catch (e) {
                                        console.log(
                                          "Problem changing visibility in single select field: ",
                                          e
                                        );
                                      }
                                    }}
                                  />
                                </center>
                              )}
                              {(field.inputType == "singleSelect" ||
                                field.inputType == "layeredSelect") && (
                                <ConfiguratorSelectField
                                  price={price}
                                  setDeliveryCosts={setDeliveryCosts}
                                  submitted={submitted}
                                  key={key}
                                  currentMenu={props.parametrization}
                                  largeMenu={largeMenu}
                                  options={field.options}
                                  field={field}
                                  configuration={configuration}
                                  setConfiguration={props.setConfiguration}
                                  currentValue={
                                    configuration.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"]
                                  }
                                  setCurrentValue={(newValue) => {
                                    if (newValue || field.allowUnselect) {
                                      // important to use spread operator here to get fresh reference!

                                      let newArray = [...configuration];
                                      console.log(
                                        "SETTING NEW VALUE FOR LAYERED SELECT:",
                                        newValue
                                      );
                                      newArray.filter(
                                        (i) => i.title == field.title
                                      )[0]["currentValue"] = newValue;
                                      // console.log("updating with ", newValue, newArray, key);
                                      !submitted && setConfiguration(newArray);

                                      if (field.inducesOn) {
                                        try {
                                          let prefix = "";
                                          console.log(
                                            "induces on 4: ",
                                            field.inducesOn
                                          );
                                          field.inducesOn.forEach(
                                            (inducesOnFieldTitle) => {
                                              if (
                                                configuration.filter(
                                                  (i) =>
                                                    i.title ==
                                                    inducesOnFieldTitle
                                                )[0].currentValue
                                              ) {
                                                // make every manometer invisible
                                                prefix = "";
                                                props.scene.traverse(
                                                  (child) => {
                                                    // console.log(configuration);
                                                    // console.log(
                                                    //   "check if name includes: ",
                                                    //   inducesOnFieldTitle,
                                                    //   configuration.filter(
                                                    //     (i) =>
                                                    //       i.title == inducesOnFieldTitle
                                                    //   )[0].currentValue
                                                    // );
                                                    if (
                                                      configuration.filter(
                                                        (i) =>
                                                          i.title ==
                                                          inducesOnFieldTitle
                                                      )[0].currentValue
                                                        .length &&
                                                      child.name.includes(
                                                        configuration.filter(
                                                          (i) =>
                                                            i.title ==
                                                            inducesOnFieldTitle
                                                        )[0].currentValue
                                                          ? configuration.filter(
                                                              (i) =>
                                                                i.title ==
                                                                inducesOnFieldTitle
                                                            )[0].currentValue
                                                          : "###"
                                                        // != undefined
                                                      )
                                                    ) {
                                                      console.log(
                                                        "setting child invisible: ",
                                                        child
                                                      );
                                                      child.visible = false;
                                                      child.layers.disable(0);
                                                      child.layers.enable(1);
                                                    }
                                                  }
                                                );
                                                configuration
                                                  .filter(
                                                    (i) =>
                                                      i.title ==
                                                      inducesOnFieldTitle
                                                  )[0]
                                                  .dependsOn.forEach(
                                                    (dependencyFieldTitle) => {
                                                      let value =
                                                        configuration.filter(
                                                          (i) =>
                                                            i.title ==
                                                            dependencyFieldTitle
                                                        )[0].currentValue;

                                                      prefix = `${
                                                        Array.isArray(value)
                                                          ? value.filter(
                                                              (i) => i
                                                            ).length
                                                          : value
                                                      }|${prefix}`;
                                                    }
                                                  );
                                                prefix =
                                                  prefix +
                                                  configuration.filter(
                                                    (i) =>
                                                      i.title ==
                                                      inducesOnFieldTitle
                                                  )[0].currentValue;
                                                let meshObject =
                                                  props.scene.getObjectByName(
                                                    prefix
                                                  );
                                                console.log(
                                                  "setting visible: ",
                                                  prefix
                                                );
                                                // make correct manometer visible

                                                if (
                                                  meshObject instanceof
                                                  THREE.Group
                                                ) {
                                                  console.log(
                                                    "it's a group",
                                                    meshObject,
                                                    meshObject.children
                                                  );
                                                  meshObject.visible = true;
                                                  for (let cld of meshObject.children) {
                                                    if (
                                                      cld instanceof THREE.Group
                                                    ) {
                                                      console.log(
                                                        "it's a sub-group",
                                                        cld,
                                                        cld.children
                                                      );
                                                      cld.visible = true;
                                                      for (let subcld of cld.children) {
                                                        subcld.visible = true;
                                                        subcld.layers.disable(
                                                          1
                                                        );
                                                        subcld.layers.enable(0);
                                                      }
                                                    } else {
                                                      cld.visible = true;
                                                      cld.layers.disable(1);
                                                      cld.layers.enable(0);
                                                    }
                                                  }
                                                } else {
                                                  meshObject.visible = true;
                                                  meshObject.layers.disable(1);
                                                  meshObject.layers.enable(0);
                                                }
                                              }
                                            }
                                          );
                                        } catch (e) {
                                          console.log(e);
                                        }
                                      }

                                      // handle visibility here!:
                                      let prefix = "";
                                      try {
                                        field.options.forEach((options) => {
                                          // option.title is the mesh name!
                                          // check if there is a dependency -> concat with pipe along dependsOn array
                                          let meshName = options.title;
                                          prefix = "";
                                          if (
                                            field.dependsOn &&
                                            field.dependsOn.length > 0
                                          ) {
                                            field.dependsOn.forEach(
                                              (dependencyFieldTitle) => {
                                                console.log(
                                                  dependencyFieldTitle
                                                );
                                                console.log(
                                                  "this: ",
                                                  configuration
                                                );
                                                prefix = `${
                                                  configuration.filter(
                                                    (i) =>
                                                      i.title ==
                                                      dependencyFieldTitle
                                                  )[0].currentValue
                                                }|${prefix}`;
                                              }
                                            );
                                          }

                                          let meshObject =
                                            props.scene.getObjectByName(
                                              prefix + options.title
                                            );
                                          console.log(
                                            "setting visible now: ",
                                            prefix + options.title,
                                            meshObject
                                          );
                                          //meshObject.visible = options.title == newValue;
                                          let isVisible =
                                            options.title == newValue;
                                          if (meshObject != undefined) {
                                            if (isVisible) {
                                              meshObject.visible = true;
                                              meshObject.layers.disable(1);
                                              meshObject.layers.enable(0);
                                            } else {
                                              meshObject.visible = false;
                                              meshObject.layers.disable(0);
                                              meshObject.layers.enable(1);
                                            }
                                          }
                                        });
                                      } catch (e) {
                                        console.log(
                                          "Problem changing visibility in single select field: ",
                                          e
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                              {field.inputType == "multiSelect" && (
                                <ConfiguratorMultiSelectField
                                  key={key}
                                  largeMenu={largeMenu}
                                  options={field.options}
                                  field={field}
                                  // this adjustment for currentValue and setCurrentValue has to be made to the other field compnents as well
                                  currentValue={
                                    configuration.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"]
                                  }
                                  setCurrentValue={(newValue) => {
                                    console.log("got this input", newValue);
                                    // important to use spread operator here to get fresh reference!
                                    let newArray = [...configuration];
                                    newArray.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"] = newValue;
                                    // console.log("updating with ", newValue, newArray, key);
                                    !submitted && setConfiguration(newArray);

                                    if (field.inducesOn) {
                                      try {
                                        let prefix = "";
                                        console.log(
                                          "induces on 5: ",
                                          field.inducesOn
                                        );
                                        field.inducesOn.forEach(
                                          (inducesOnFieldTitle) => {
                                            if (
                                              configuration.filter(
                                                (i) =>
                                                  i.title == inducesOnFieldTitle
                                              )[0].currentValue
                                            ) {
                                              // make every manometer invisible
                                              prefix = "";
                                              props.scene.traverse((child) => {
                                                // console.log(configuration);
                                                // console.log(
                                                //   "check if name includes: ",
                                                //   inducesOnFieldTitle,
                                                //   configuration.filter(
                                                //     (i) =>
                                                //       i.title == inducesOnFieldTitle
                                                //   )[0].currentValue
                                                // );
                                                if (
                                                  child.name.includes(
                                                    configuration.filter(
                                                      (i) =>
                                                        i.title ==
                                                        inducesOnFieldTitle
                                                    )[0].currentValue
                                                  )
                                                ) {
                                                  console.log(
                                                    "setting child invisible: ",
                                                    child
                                                  );
                                                  child.visible = false;
                                                  child.layers.disable(0);
                                                  child.layers.enable(1);
                                                }
                                              });
                                              configuration
                                                .filter(
                                                  (i) =>
                                                    i.title ==
                                                    inducesOnFieldTitle
                                                )[0]
                                                .dependsOn.forEach(
                                                  (dependencyFieldTitle) => {
                                                    prefix = `${
                                                      configuration.filter(
                                                        (i) =>
                                                          i.title ==
                                                          dependencyFieldTitle
                                                      )[0].currentValue
                                                    }|${prefix}`;
                                                  }
                                                );
                                              prefix =
                                                prefix +
                                                configuration.filter(
                                                  (i) =>
                                                    i.title ==
                                                    inducesOnFieldTitle
                                                )[0].currentValue;
                                              let meshObject =
                                                props.scene.getObjectByName(
                                                  prefix
                                                );
                                              console.log(
                                                "setting visible: ",
                                                prefix
                                              );
                                              // make correct manometer visible

                                              if (
                                                meshObject instanceof
                                                THREE.Group
                                              ) {
                                                console.log(
                                                  "it's a group",
                                                  meshObject,
                                                  meshObject.children
                                                );
                                                meshObject.visible = true;
                                                for (let cld of meshObject.children) {
                                                  cld.visible = true;
                                                  cld.layers.disable(1);
                                                  cld.layers.enable(0);
                                                }
                                              } else meshObject.visible = true;
                                              meshObject.layers.disable(1);
                                              meshObject.layers.enable(0);
                                            }
                                          }
                                        );
                                      } catch (e) {
                                        console.log(e);
                                      }
                                    }

                                    // handle visibility here!:
                                    let prefix = "";
                                    try {
                                      field.options.forEach((options) => {
                                        // option.title is the mesh name!
                                        // check if there is a dependency -> concat with pipe along dependsOn array
                                        let meshName = options.title;
                                        prefix = "";
                                        if (
                                          field.dependsOn &&
                                          field.dependsOn.length > 0
                                        ) {
                                          field.dependsOn.forEach(
                                            (dependencyFieldTitle) => {
                                              console.log(dependencyFieldTitle);
                                              console.log(
                                                "this: ",
                                                configuration
                                              );
                                              prefix = `${
                                                configuration.filter(
                                                  (i) =>
                                                    i.title ==
                                                    dependencyFieldTitle
                                                )[0].currentValue
                                              }|${prefix}`;
                                            }
                                          );
                                        }

                                        let meshObject =
                                          props.scene.getObjectByName(
                                            prefix + options.title
                                          );
                                        console.log(
                                          "setting visible now: ",
                                          prefix + options.title,
                                          meshObject,
                                          newValue
                                        );
                                        //meshObject.visible = options.title == newValue;
                                        let isVisible = newValue.includes(
                                          options.title
                                        );
                                        if (isVisible) {
                                          meshObject.visible = true;
                                          meshObject.layers.disable(1);
                                          meshObject.layers.enable(0);
                                          for (let cld of meshObject.children) {
                                            cld.visible = true;
                                            cld.layers.disable(1);
                                            cld.layers.enable(0);
                                          }
                                        } else {
                                          meshObject.visible = false;
                                          meshObject.layers.disable(0);
                                          meshObject.layers.enable(1);
                                          for (let cld of meshObject.children) {
                                            cld.visible = true;
                                            cld.layers.disable(0);
                                            cld.layers.enable(1);
                                          }
                                        }
                                      });
                                    } catch (e) {
                                      console.log(
                                        "Problem changing visibility in single select field: ",
                                        e
                                      );
                                    }
                                  }}
                                />
                              )}
                              {field.inputType == "multiSelectAmounts" && (
                                <ConfiguratorMultiSelectAmountsField
                                  key={key}
                                  largeMenu={largeMenu}
                                  options={field.options}
                                  field={field}
                                  // this adjustment for currentValue and setCurrentValue has to be made to the other field compnents as well
                                  currentValue={
                                    configuration.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"]
                                  }
                                  setCurrentValue={(newValue) => {
                                    console.log("got this input", newValue);
                                    // important to use spread operator here to get fresh reference!
                                    let newArray = [...configuration];
                                    newArray.filter(
                                      (i) => i.title == field.title
                                    )[0]["currentValue"] = newValue;
                                    // console.log("updating with ", newValue, newArray, key);
                                    !submitted && setConfiguration(newArray);

                                    if (field.inducesOn) {
                                      try {
                                        let prefix = "";
                                        console.log(
                                          "induces on 5: ",
                                          field.inducesOn
                                        );
                                        field.inducesOn.forEach(
                                          (inducesOnFieldTitle) => {
                                            if (
                                              configuration.filter(
                                                (i) =>
                                                  i.title == inducesOnFieldTitle
                                              )[0].currentValue
                                            ) {
                                              // make every manometer invisible
                                              prefix = "";
                                              props.scene.traverse((child) => {
                                                // console.log(configuration);
                                                // console.log(
                                                //   "check if name includes: ",
                                                //   inducesOnFieldTitle,
                                                //   configuration.filter(
                                                //     (i) =>
                                                //       i.title == inducesOnFieldTitle
                                                //   )[0].currentValue
                                                // );
                                                if (
                                                  child.name.includes(
                                                    configuration.filter(
                                                      (i) =>
                                                        i.title ==
                                                        inducesOnFieldTitle
                                                    )[0].currentValue
                                                  )
                                                ) {
                                                  console.log(
                                                    "setting child invisible: ",
                                                    child
                                                  );
                                                  child.visible = false;
                                                  child.layers.disable(0);
                                                  child.layers.enable(1);
                                                }
                                              });
                                              configuration
                                                .filter(
                                                  (i) =>
                                                    i.title ==
                                                    inducesOnFieldTitle
                                                )[0]
                                                .dependsOn.forEach(
                                                  (dependencyFieldTitle) => {
                                                    prefix = `${
                                                      configuration.filter(
                                                        (i) =>
                                                          i.title ==
                                                          dependencyFieldTitle
                                                      )[0].currentValue
                                                    }|${prefix}`;
                                                  }
                                                );
                                              prefix =
                                                prefix +
                                                configuration.filter(
                                                  (i) =>
                                                    i.title ==
                                                    inducesOnFieldTitle
                                                )[0].currentValue;
                                              let meshObject =
                                                props.scene.getObjectByName(
                                                  prefix
                                                );
                                              console.log(
                                                "setting visible: ",
                                                prefix
                                              );
                                              // make correct manometer visible

                                              if (
                                                meshObject instanceof
                                                THREE.Group
                                              ) {
                                                console.log(
                                                  "it's a group",
                                                  meshObject,
                                                  meshObject.children
                                                );
                                                meshObject.visible = true;
                                                for (let cld of meshObject.children) {
                                                  cld.visible = true;
                                                  cld.layers.disable(1);
                                                  cld.layers.enable(0);
                                                }
                                              } else meshObject.visible = true;
                                              meshObject.layers.disable(1);
                                              meshObject.layers.enable(0);
                                            }
                                          }
                                        );
                                      } catch (e) {
                                        console.log(e);
                                      }
                                    }

                                    // handle visibility here!:
                                    let prefix = "";
                                    try {
                                      field.options.forEach((options) => {
                                        // option.title is the mesh name!
                                        // check if there is a dependency -> concat with pipe along dependsOn array
                                        let meshName = options.title;
                                        prefix = "";
                                        if (
                                          field.dependsOn &&
                                          field.dependsOn.length > 0
                                        ) {
                                          field.dependsOn.forEach(
                                            (dependencyFieldTitle) => {
                                              console.log(dependencyFieldTitle);
                                              console.log(
                                                "this: ",
                                                configuration
                                              );
                                              prefix = `${
                                                configuration.filter(
                                                  (i) =>
                                                    i.title ==
                                                    dependencyFieldTitle
                                                )[0].currentValue
                                              }|${prefix}`;
                                            }
                                          );
                                        }

                                        let meshObject =
                                          props.scene.getObjectByName(
                                            prefix + options.title
                                          );
                                        console.log(
                                          "setting visible now: ",
                                          prefix + options.title,
                                          meshObject
                                        );
                                        //meshObject.visible = options.title == newValue;
                                        let isVisible =
                                          options.title == newValue;
                                        if (isVisible) {
                                          meshObject.visible = true;
                                          meshObject.layers.disable(1);
                                          meshObject.layers.enable(0);
                                        } else {
                                          meshObject.visible = false;
                                          meshObject.layers.disable(0);
                                          meshObject.layers.enable(1);
                                        }
                                      });
                                    } catch (e) {
                                      console.log(
                                        "Problem changing visibility in single select field: ",
                                        e
                                      );
                                    }
                                  }}
                                />
                              )}
                              {field.inputType == "upload" && (
                                <ConfiguratorUploadField />
                              )}
                            </Box>
                          </div>
                        );
                      })}
                    </Slider>
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  //md={1}
                  md={0}
                  //lg={1.5}
                  sx={{ backgroundColor: "white" }}
                ></Grid>
              </Grid>
            </BottomSheetDrawer>
          </>
        ) : (
          <center style={{ marginTop: "-140px" }}>
            Vielen Dank! Wir haben Deine Konfiguration erhalten!
            <br />
            <Button
              sx={{
                background: props.parametrization.branding.primaryColor.rgb,
                padding: "5px 10px",
                marginTop: "10px",
                color: "white",
                fontWeight: "normal",
                fontSize: "1rem",
                maxWidth: "270px",
                borderRadius: props.parametrization.branding.borderRadius
                  ? props.parametrization.branding.borderRadius
                  : "auto",
              }}
              onClick={() => {
                props.parametrization.GTM_ids &&
                  props.parametrization.GTM_ids.map((id, key) => {
                    let dataLayer = window["dataLayer" + key] || [];
                    // console.log("pushing to", dataLayer);

                    dataLayer.push({
                      event: "yn_duplicate",
                      configurator: props.parametrization.id,
                    });
                  });
                //toggleShowLoadingSpinner(true);
                //console.log(email, actionCodeSettings, auth);
                // sendSignInLinkToEmail(
                //   auth,
                //   email,
                //   actionCodeSettings
                // ).then((data) => {
                //window.localStorage.setItem("emailForSignIn", email);

                addDoc(collection(db, "configurations"), {
                  submitted: false,
                  parametrizationId: id,
                  comment: "",
                  createdTimestamp: new Date().toISOString(),
                  lastUpdateTimestamp: new Date().toISOString(),
                  data: props.configuration.reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur.title]: cur.currentValue,
                    }),
                    {}
                  ),
                }).then((docRef) => {
                  if (window.location.href.indexOf("embed") > -1) {
                    navigate(`/embed/${id}/${docRef.id}`);
                  } else {
                    navigate(`/experience/${id}/${docRef.id}`);
                  }
                  if (props.parametrization.screenshotCam) {
                    const storage = getStorage();
                    let renderer = new THREE.WebGLRenderer({
                      antialias: true,
                      preserveDrawingBuffer: true,
                    });
                    renderer.setClearColor(0x000000, 0);
                    renderer.setSize(800, 500);
                    let camera = new THREE.PerspectiveCamera(
                      70,
                      800 / 500,
                      1,
                      1000
                    );
                    camera.position.z =
                      props.parametrization.screenshotCam.positionZ; //1.5;
                    camera.position.y =
                      props.parametrization.screenshotCam.positionY; //0.6;
                    camera.position.x =
                      props.parametrization.screenshotCam.positionX; //5.4;
                    camera.rotation.y =
                      props.parametrization.screenshotCam.rotationY; //1.3;
                    // camera.rotation.x = -0.2;
                    camera.setFocalLength(45);
                    // camera.zoom=5;
                    // should only be called when there really is a
                    try {
                      // save screenshot to firestore storage
                      console.log("saving screenshot!", props);

                      //var w = window.open('', '');
                      //w.document.title = "Screenshot";
                      //w.document.body.style.backgroundColor = "red";
                      var img = new Image();
                      // Without 'preserveDrawingBuffer' set to true, we must render now
                      // The call of render method causes memory leak :-(
                      renderer.render(props.scene, camera);
                      img.src = renderer.domElement.toBlob(function (blob) {
                        var image = new Image();
                        image.src = blob;
                        const storageRef = ref(storage, `${docRef.id}`);
                        uploadBytes(storageRef, blob)
                          .then((snapshot) => {
                            return getDownloadURL(snapshot.ref);
                          })
                          .then((downloadURL) => {
                            console.log("url: ", downloadURL);
                            const docRef1 = setDoc(
                              doc(db, "configurations", docRef.id),
                              {
                                img: downloadURL,
                              },
                              { merge: true }
                            );

                          });
                        // renderer = null;
                        //     // renderer.domElement.remove();
                        //   // var uploadTask = storageRef.child('images/' + configuration.id).put(blob);
                      });
                      //w.document.body.appendChild(img);
                    } catch (e) {
                      console.log(e);
                    }
                  }
                  //toggleShowLoadingSpinner(false);
                  props.toggleConfigDelta(false);
                  setOpen(false);
                  props.setOpen(false);
                  toast("Duplizieren war erfolgreich!", {
                    //description: 'Monday, January 3rd at 6:00pm',
                    duration: 3000,
                    id: "saving-successfull",
                    style: {
                      background: "rgba(30,180,30,0.92)",
                      color: "rgba(255,255,255,1)",
                      margin: innerWidth < 800 ? "0" : "auto",
                      zIndex: 500,
                      lineHeight: "1.2",
                      fontSize: innerWidth < 800 ? "0.6rem" : "0.76rem",
                      position: "relative",
                      padding: "8px 12px 8px 12px",
                      textAlign: "center",
                      border: 0,
                      width: innerWidth < 800 ? "auto" : "300px",
                      userSelect: "none",
                      display: "block",
                    },
                    className: "my-toast",
                    descriptionClassName: "my-toast-description",
                  });
                });

                // });
              }}
            >
              Konfiguration duplizieren und weitermachen
            </Button>
          </center>
        )}
      </div>

      <audio volume={"50%"} src={audioSource} id={"clickSound"} />
    </>
  );
};
