import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

import { LinkedIn } from '@mui/icons-material';

const mock = [
  {
    name: 'Erhard Dammrath, Dipl.-Ing.',
    title: 'CAD',
    avatar: '/erhard_dammrath_profile.png',
    linkedin: "https://www.linkedin.com/in/erhard-dammrath-b15594203/"
  },
 

  {
    name: 'Martin Dammrath, MSc',
    title: 'Technical Specialist',
    avatar: '/martin_dammrath_profile_korr.png',
    linkedin: "https://www.linkedin.com/in/martin-c-dammrath/",
   
  },
  {
    name: 'Johannes Dammrath',
    title: 'Customer Success',
    avatar: '/johannes_dammrath_profile.png',
    linkedin: "https://www.linkedin.com/in/johannes-dammrath-61bb79263/"
  },
  
  // {
  //   name: 'Chary Smith',
  //   title: 'SEO at Comoti',
  //   avatar: 'https://assets.maccarianagency.com/avatars/img6.jpg',
  // },
];

const People = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        // '&::after': {
        //   position: 'absolute',
        //   content: '""',
        //   width: '30%',
        //   height: '50%',
        //   zIndex: 1,
        //   top: 0,
        //   right: 0,
        //   backgroundSize: '18px 18px',
        //   backgroundImage: `radial-gradient(${alpha(
        //     theme.palette.primary.dark,
        //     0.4,
        //   )} 20%, transparent 20%)`,
        //   opacity: 0.2,
        // },
      }}
    >
      <Box zIndex={2} position={'relative'}>
        <Box marginBottom={4}>
          {/* <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            gutterBottom
            color={'secondary'}
            align={'center'}
          >
            Our team
          </Typography> */}
          <Typography
            variant={'h3'}
            gutterBottom
            color={"#222"}
            align={'center'}
            mb={"60px"}
            mt={"150px"}
            sx={{ fontWeight: 700 }}
          >
            Unterschiedliche Persönlichkeiten - eine Mission
          </Typography>
          {/* <Typography
            variant={'h6'}
            component={'p'}
            color={'text.secondary'}
            align={'center'}
            data-aos="fade-up"
          >
            
            Wir sind als Team mit unterschiedlichen Skills breit aufgestellt. Wir arbeiten  fokussiert und partnerschaftlich am Erfolg unserer Kunden.
          </Typography> */}
          {/* <Box marginTop={2} display={'flex'} justifyContent={'center'}>
            <Button color={'primary'} variant={'contained'} size={'large'}>
              View all
            </Button>
          </Box> */}
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={i}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box component={Card} width={"350px"} boxShadow={0} bgcolor={'transparent'}>
                <Box
               
                  component={"img"}
                  borderRadius={2}
                   width={"350px"}
                   height={"400px"}
                  // minHeight={320}
                  src={item.avatar}
                  loading={"lazy"}
                  className={"gradient-background"}
                  sx={{filter: "grayscale(1) contrast(1.2)", "&:hover":{filter: "grayscale(0)"},transition: "filter 0.3s ease-in-out",objectFit: "cover", backgroundImage: "linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);"}}
                 
                />
                <CardContent>
                  <ListItemText
                    primary={item.name}
                    secondary={item.title}
                    primaryTypographyProps={{ fontWeight: 700 }}
                  />
                  <Box marginTop={1}>
                    <IconButton
                      aria-label="linkedin"
                      size={'small'}
                      sx={{color:'#0072b1'}}
                      onClick={()=>{window.open(item.linkedin, "_blank")}}
                    >
                      <LinkedIn />
                    </IconButton>
                    {/* <IconButton
                      aria-label="twitter"
                      size={'small'}
                      color={'primary'}
                    >
                      <TwitterIcon />
                    </IconButton> */}
                  </Box>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default People;
