import * as React from "react";
import { Transition } from "react-transition-group";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ConfigForm from "ConfigForm/ConfigForm";
import CheckIcon from '@mui/icons-material/Check';
import { primaryColor } from "Routes";
import CountTo from "CountTo";
import { useTour } from "@reactour/tour";
import useWindowSize from "useWindowSize";

export default function FadeModalDialog({configuration, priceComponent, verboseConfiguration, toggleSubmitted, parametrization, storedConfig, reloadStoredConfig, submitted, scene}) {
  const [open, setOpen] = React.useState(false);
  const {isOpen} = useTour();
  const innerWidth = useWindowSize();
  return (
    <React.Fragment>
      {!submitted? <Typography
        onClick={() => !isOpen && setOpen(true)}
        variant={"overline"}
        sx={{
          
          // borderWidth: "0px 1px 0px 1px",
          // borderRadius: "4px",
          display: "inline",
          padding: (innerWidth > 800?"9px":"2px"),
          paddingX: (innerWidth > 800?"20px":"8px"),
          fontWeight: "100",
          fontSize: (innerWidth > 800?"1.15rem":"0.8rem"),
          color: "#333",
          transition: "background .3s ease-in-out",
          cursor: "pointer",
          height: "auto",
          minWidth: "50px",
          position:"relative" ,
          // fontFamily: "'Lato', sans-serif",
          fontWeight: "500",
          zIndex: 200,
          "&:disabled": {
            color: "#555"
          }
          // "&:hover": {
          //   backgroundColor: "#eeeeee"
          // }
        }}
      >
        Fertig{" "}
        <ArrowRightAltIcon strokeWidth={1} sx={{ marginBottom: innerWidth > 800 ? "-6px" : "-4px" }} />
      </Typography>:
      <Typography
      // onClick={() => setOpen(true)}
      variant={"overline"}
      sx={{
        // border: "1px solid #bbb",
        // borderWidth: "0px 1px 0px 1px",
        // borderRadius: "4px",
        display: "inline",
        padding: "7px",
        paddingLeft: "15px",
        fontWeight: "400",
        fontSize: innerWidth > 800 ?"1.2rem" : "0.8rem",
        color: "#333",
        transition: "background .3s ease-in-out",
        
        // cursor: "pointer",
        height: "auto",
        position:  "relative",
        bottom: (innerWidth > 800) ? null : "0px",
        right: (innerWidth > 800)? null : "0px"
        // "&:hover": {
        //   backgroundColor: "#eeeeee"
        // }
      }}
    >
      Abgesendet{" "}
      
      <CheckIcon strokeWidth={1} sx={{ marginBottom: "-5px", color: "green" }} />
    </Typography>
      }
     
      <Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={() => {!submitted && setOpen(false)}}
            slotProps={{
              backdrop: {
                sx: {
                  
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
              height: "auto",
              maxHeight: "auto",
              overflow: "scroll",
              zIndex: 1000000000000,
            }}
          >
            <ModalDialog
              aria-labelledby="fade-modal-dialog-title"
              aria-describedby="fade-modal-dialog-description"
              sx={{
                height: "auto",
                maxHeight: "500vh",
                //marginY: (innerWidth > 800? null : "150px"),
                
                opacity: 0,
                transition: `opacity 300ms height`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              {/* <CloseIcon
                sx={{ float: "right", ml: "auto", cursor: "Pointer" }}
                onClick={() => setOpen(false)}
              /> */}
              
              <ConfigForm verboseConfiguration={verboseConfiguration} toggleOpen={setOpen} scene={scene} toggleSubmitted={toggleSubmitted} parametrization={parametrization} configuration={configuration} storedConfig={storedConfig} reloadStoredConfig={reloadStoredConfig}/>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
     
    </React.Fragment>
  );
}
