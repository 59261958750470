import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';
import WirelessCharging from 'icons/wireless-charging';
import MagicWand from 'icons/magic-wand';
import Tactic from 'icons/tactic';

const mock = [
  {
    title: 'Power-Play',
    subtitle:
      'Wir arbeiten in schnellen Zyklen und kooperieren eng mit Ihnen. Nur so können wir erstklassige Ergebnisse erzielen, die Ihnen und Ihren Kunden das Leben leichter machen werden.',
    icon: <WirelessCharging width={"60px"}/>,
  },
  {
    title: 'Liebe zum Detail',
    subtitle:
      'Software ist nur dann ein Erfolgstreiber, wenn sie exzellent designt ist. Unsere Benutzeroberfläche ist smart aufgebaut, trägt jedoch nicht auf und legt den Fokus auf Ihr Produkt.',
    icon: <MagicWand width={"60px"}/>,
  },
  {
    title: 'Vorausdenker',
    subtitle:
      'Wir denken Ihr Geschäft und Ihre Prozesse gemeinsam mit Ihnen weiter, denn wir sind selbst Unternehmer und kennen die geschäftlichen Herausforderungen.',
    icon: <Tactic width={"60px"}/>,
  },
];

const WhyYnflow = () => {
  return (
    <Box 
    // bgcolor={'rgb(250,250,250)'}
    >
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant={'h3'}
              gutterBottom
              align={'center'}
              mb={"60px"}
              mt={"150px"}
              sx={{ fontWeight: 700, color: '#222' }}
            >
              Das macht Ynflow zu einem einzigartigen Partner
            </Typography>
            {/* <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              align={'center'}
              sx={{ color: 'common.white' }}
            >
              3D bewirkt Wunder im Vertrieb, denn 
            </Typography> */}
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}
                >
                  {/* <span style={{color: "black"}}>{item.icon}</span> */}
                  
                  <Typography
                    
                    gutterBottom
                    align={'left'}
                    sx={{ fontWeight: 700, color: '#222' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }} align={'left'}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyYnflow;
