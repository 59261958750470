import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Container from "components/Container";
import { Outlet, useNavigate, Link, useLocation } from "react-router-dom";

import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth } from "index";
// import { Link } from "@mui/material";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { primaryColor } from "Routes";
import { isTemplateMiddle } from "typescript";

const converter = {
  toFirestore(post) {
    return {
      text: post.text,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

const Page = ({ children, pages }) => {
  const [activeLink, setActiveLink] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
    console.log("active link ", activeLink)
  }, [location]);

  const [firestoreUser, firestoreUserLoading, firestoreUserError] = useCollectionData(
    user ? query(
      collection(getFirestore(app), "users").withConverter(converter),
      where("email", "==", user.email)
    ):null,
    [],
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(()=>{
    firestoreUser && console.log("firestoreUSer", firestoreUser)
  },[firestoreUser])

  const theme = useTheme();

  return (
    <Box
      sx={{
        
        height: "100vh",
        overflow: "auto",
        //backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #fffdff 100%);",
        backgroundColor: "#f3f6fd",
       
      }}

      // bgcolor={"alternate.main"}
    >
      {/* {firestoreUser && firestoreUser[0] && firestoreUser[0].branding && firestoreUser[0].branding.brandIcon && 
      <Box component={"img"}
      sx={{margin :"30px", marginBottom: "-120px"}}
      width={"100px"}
     
      src={firestoreUser[0].branding.brandIcon}
      />} */}
      <Box
      
        // bgcolor={"white"}
        //padding={0}
        paddingTop={2}
      >
        <Box sx={{  padding: "20px", paddingRight: "70px", pl: 6, pt: 0 }}>
          
          <Box
            element={"div"}
            sx={{ textAlign: window.innerWidth > 900 ? "right" : "left" }}
          >
             {user && (
              <Typography variant={"body1"}>
                <span style={{ fontSize: "0.8rem"}}>
                  {user.email} |{" "}
                  <Link
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "underline",
                     
                    }}
                    onClick={() => {
                      signOut(auth);
                      try {
                        window.localStorage.removeItem("emailForSignIn");
                        navigate("/portal/my-configurations")
                      } catch (error) {
                        console.log(error);
                        navigate("/portal")
                      }
                      
                    }}
                  >
                    Log out
                  </Link>
                </span>{" "}
              </Typography>
            )}
            <span style={{fontSize: "0.65rem", display: "inline-block", width: "54px"}}>powered by {"  "}</span><br/> <Box
              component={"img"}
              sx={{
                //mb: 3,
                //marginTop: "-5px",
                position: "sticky",
                //marginBottom: "50px",
                top: "0px",
                opacity: "0.8",

              }}
              src={"/y_logo.png"}
              width={"54px"}
            ></Box>
           
          </Box>
          {/* <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ color: "#222", marginTop: "-20px" }}
          >
           {firestoreUser ? <>Hi, {firestoreUser && firestoreUser[0].firstName} {firestoreUser && firestoreUser[0].lastName}</>:
           <>Welcome to your personal dashboard</>}
          </Typography>
          <Typography variant="h6" sx={{ color: "#222" }}>
            Check all projects with clients who are interested in your product.
          </Typography> */}
        </Box>
      </Box>
      <Box
        paddingTop={"0 !important"}
        px={{ xs: 2, sm: 3, md: 4, lg: 6 }}
        //paddingTop={1}
      >
        <Grid container spacing={4} >
          <Grid item xs={12} sm={12} md={0.8} sx={{overflowX: "hidden",}}>
            <Box sx={{ml: "auto"}}>
              <List
                disablePadding
                sx={{
                  display: { xs: "inline-flex", md: "flex" },
                  flexDirection: { xs: "row", md: "column" },
                  overflow: "auto",
                  flexWrap: "nowrap",
                  width: "100%",
                  // height: (window.innerWidth > 900? "calc(100vh - 500px)": "auto"),
                  paddingY: { xs: 3, md: "70px" },
                  // paddingX: { xs: 4, md: 0 },
                  
                }}
              >
                {pages.map((item) => (
                  <>
                  <Link
                    key={item.id}
                    // component={"a"}
                    to={item.href}
                    disableGutters
                    style={{
                      width: "50px",
                      textDecoration: "none",
                      marginRight: { xs: 2, md: 0 },
                      flex: 0,
                      paddingX: { xs: 0, md: 3 },
                      marginBottom: "23px",
                      marginLeft: "auto",
                     
                      borderLeft: {
                        xs: "none",
                        md: "2px solid transparent",
                      },
                      borderLeftColor: {
                        md:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : "transparent",
                      },
                    }}
                  >
                    <Box sx={{padding: "7px", transition: "all .2s ease-in-out", width: "50px", color: (activeLink && activeLink.includes(item.id) ? "white" : "#1f1c2e"), backgroundColor: (activeLink && activeLink.includes(item.id) ? "#1f1c2e" : "transparent"), height: "50px",borderRadius: "25px", "&:hover": {backgroundColor: (activeLink && activeLink.includes(item.id) ? "#1f1c2e" : "#c3cff4"), color: "white"  }}}>
                    {item.icon && item.icon}
                    </Box>
                    {/* <Typography
                      variant="subtitle1"
                      noWrap
                      color={
                        activeLink === item.href
                          ? "text.primary"
                          : "text.secondary"
                      }
                      sx={{textDecoration: "none","&:hover": {color: theme.palette.primary.main},
                      transition: "color .1s ease-in-out",
                      fontSize: "1.1rem", color: "black", paddingLeft: "20px", marginBottom: "10px"}}
                    >
                      {item.title}
                    </Typography> */}
                  </Link>
                  </>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={11.2} >
            <Card sx={{ boxShadow: 3, padding: 4, borderRadius: "32px", boxShadow: "none", minHeight: "900px" }}>
              {children ? children : <Outlet context={firestoreUser && firestoreUser[0]}/>}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
