import React, { useEffect } from "react";
import "./phonemockupstyles.css";
import { Box, Container, Grid } from "@mui/material";
import AttractCustomers from "AttractCustomers";
import { About } from "views/WebBasic/components";

const PhoneMockup = () => {
  useEffect(() => {
    // window.onload = function () {
    document.querySelector(".workspace").classList.add("active");
    //   }

    const gallery = document.querySelectorAll(".gallery-content__img"),
      mobileBody = document.querySelector(".mobile-body"),
      bg = document.querySelector(".bg-content");

    gallery.forEach((g) => {
      g.addEventListener("click", function () {
        const imgSrc = this.dataset.img,
          activeGallery = document.querySelector(
            ".gallery-content__img.active"
          );

        activeGallery.classList.remove("active");
        this.classList.add("active");
        bg.style.backgroundImage = `url('${imgSrc}')`;
        mobileBody.style.backgroundImage = `url('${imgSrc}')`;
      });
    });
  }, []);
  return (
    <div className={"gradient-background"} style={{ padding: "5%" }}>
      {" "}
      <div class="workspace">
        {/* <div class="note">The iphone is created using HTML and CSS</div> */}
        <div class="bg-content"></div>
        <Grid container>
          <Grid sx={{ m: "auto" }}>
            <Box
              sx={{ my: "auto", mx: "auto", marginLeft: "-20%", display: "flex" }}
              component={"div"}
              data-aos={"zoom-in"}
              data-aos-delay={300}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <div
                style={{ margin: "auto" }}
                className={
                  "mobile-body " + (window.innerWidth > 800 ? "dsk" : "")
                }
              >
                <Box
                  component={"video"}
                  //   src={
                  //     theme.palette.mode === 'light'
                  //       ? 'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-16094471%20(Original).mp4?alt=media&token=39b5005f-00be-4b55-8cf8-31a456d49005'
                  //       : 'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-16094471%20(Original).mp4?alt=media&token=39b5005f-00be-4b55-8cf8-31a456d49005'
                  //   }
                  //   loading={'lazy'}
                  width={0.9}
                  //   preload='auto'
                  autoPlay
                  loop
                  muted
                  //controls
                  height={1}
                  max-height={"100vh"}
                  sx={{
                    //filter: "drop-shadow(0.8rem 0.8rem 0.75rem #222)",
                    // zIndex: 999999999999,
                    //display: "inline-block",
                    objectFit: "contain",
                    borderRadius: " 100px 100px 100px 100px",
                    //paddingBottom: "100px",
                    background: "rgb(246,246,246)",
                    marginTop: "10px",
                    paddingTop: "50px",

                    // filter:
                    //   theme.palette.mode === 'dark'
                    //     ? 'brightness(0.7)'
                    //     : 'none',
                  }}
                >
                  {" "}
                  <source
                    src="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild_demo.mp4?alt=media&token=23a4ff7b-774f-4d82-bd34-2856a041970d"
                    type="video/mp4"
                  ></source>
                </Box>
                <div class="top-bar">
                  <div class="camera"></div>
                  <div class="speaker"></div>
                </div>
                <div class="button volume-up"></div>
                <div class="button volume-down"></div>
                <div class="button silent"></div>
                <div class="button power"></div>
                <div class="layer-2"></div>
                <div class="layer-1"></div>
                <div class="layer-gradient-1"></div>
                <div class="layer-gradient-2"></div>
              </div>
            </Box>
          </Grid>
          {/* <div class="gallery-content">
      <div class="gallery-content__img active" 
           data-img="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-1.jpg">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-1.jpg" rel="preload"/>
      </div>
      <div class="gallery-content__img" 
           data-img="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-2.jpg">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-2.jpg" rel="preload"/>
      </div>
      <div class="gallery-content__img" 
           data-img="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-3.jpg">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-3.jpg" rel="preload"/>
      </div>
      <div class="gallery-content__img" 
           data-img="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-4.jpg">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-4.jpg" rel="preload"/>
      </div>
      <div class="gallery-content__img" 
           data-img="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-5.jpg">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/830072/bg-5.jpg" rel="preload"/>
      </div>
    </div> */}
          <Grid sx={{ my: "auto" }}>
            <Container sx={{ marginTop: "-100px", my: "auto" }}>
              <AttractCustomers />
            </Container>
          </Grid>
        </Grid>
      </div>
      <Box sx={{ width: "auto", m: 0, p: 0, pb: "100px" }}>
        <About />
      </Box>
    </div>
  );
};

export default PhoneMockup;
