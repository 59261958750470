import React, {useState} from "react";
import { Button } from "@mui/joy";

function ClipboardCopy({ copyText, parametrization }) {
    const [isCopied, setIsCopied] = useState(false);
  
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return (
      <div>
        <input style={{fontSize: "14px", width: "350px", padding: "10px", borderRadius: "8px"}} type="text" value={copyText} readOnly />
        {/* Bind our handler function to the onClick button property */}
        <Button variant={"contained"} style={{
             borderRadius: parametrization.branding.borderRadius?parametrization.branding.borderRadius:"8px",
                  
          color: "#222",background: "white",fontSize: "14px", marginLeft: "8px", border: "1px solid grey",}} onClick={handleCopyClick}>
          <span style={{display: "inline-block", textAlign: "center", width: "130px"}}>{isCopied ? '✅ kopiert!' : 'Kopieren'}</span>
        </Button>
      </div>
    );
  }

  export default ClipboardCopy;