import * as React from "react";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Box, TextField } from "@mui/material";
import { Button } from "@mui/joy";
import { useParams } from "react-router-dom";
import useWindowSize from "useWindowSize";
var debounce = require("lodash.debounce");

export default function ConfiguratorTextField({
  key,
  currentValue,
  setCurrentValue,
  maxLetters,
  currentMenu,
  largeMenu
}){

// This needs to be debounced:
// if (!maxLetters || (text <= maxLetters)) {
//   setCurrentValue(text != "" ? text : null);
// }

let {configurationId} = useParams();
const [text, setText] = React.useState(currentValue);
const [initiallyLoaded, setInitiallyLoaded] = React.useState(false);
const debounced = debounce((value) => {
  console.log("debounce w/ ",value)
  if (!maxLetters || (value.length <= maxLetters)) {
    
       setCurrentValue(value != "" ? value : null);
       console.log("lazy updated text")
     }
}, 500)
React.useEffect(()=>{
  if(currentValue){
    setText(currentValue)
    setInitiallyLoaded(true)
  }
},[currentValue])
const debounceRequest = React.useCallback((value) => debounced(value), []);
const innerWidth = useWindowSize();
React.useEffect(()=>{
  if(largeMenu || !(window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))){
  if(!currentMenu.rows && !configurationId){debounceRequest(text)}
  }
},[text])
  return (
    <>
    <Box
      sx={{
        overflow: "auto",
        px: 2,
        height: "130px",
        display: "flex",
        margin: "auto",
      }}
    >
  {currentMenu.rows ? <textarea  value={text}
        // variant="plain"
        onFocus={()=>{
          // NOT LARGE MENU!!!
          if(!largeMenu && (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))){
            let menu = document.getElementsByClassName("react-modal-sheet-container")[0]
            menu.style.setProperty("--tf",menu.style.transform)
            menu.style.transform = "none"

          }
        }}
        variant={"outlined"}
        onChange={e => setText(e.target.value)}
onBlur={ (e) => {
  if(!largeMenu && (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))){
  let menu = document.getElementsByClassName("react-modal-sheet-container")[0]
  menu.style.transform = menu.style["--tf"]
  }
  console.log("BLURR")
  if (!maxLetters || (e.target.value.length <= maxLetters)) {
    setCurrentValue(e.target.value != "" ? e.target.value : null);
  }
}
        } rows='1' style={{borderRadius: "8px",fontFamily: "'Montserrat', sans-serif",  padding: "10px",resize: "none",margin: "auto", width: "300px", height: "100px"}} class='auto-expand' id="textarea1" placeholder={currentMenu.placeholder}>
          
        </textarea>

      :<><Input
      multiline={currentMenu.rows}
      fullWidth
      rows={2}
     
        sx={{
          height:"auto",
          margin: "auto",
          overflow: "hidden",
          background: "white",
          fontSize: currentMenu.rows? "1rem":"1.8rem",
          padding: "10px 60px",
         
          width: innerWidth > 800 ? currentMenu.rows?"350px":"auto" : "200px",
          input: {
            color: "#333",
            textAlign: "center",
            fontSize: innerWidth > 800 ? currentMenu.rows?"1rem": "1.4rem" : currentMenu.rows?"0.8rem":"0.9rem",
            fontWeight: 300,
            // fontFamily: "'Lato', sans-serif",
          },
         
        }}
        // color="neutral"
        disabled={false}
        placeholder={currentMenu.placeholder? currentMenu.placeholder : "Enter here..."}
        size="lg"
        // rows={2}
        value={text}
        // variant="plain"
        variant={"outlined"}
        // inputProps={{
        //   onBlur: (e)=> {if ((!maxLetters || (e.target.value.length <= maxLetters))) {
        //     console.log("BLURRjkbj")
        //     // setCurrentValue(e.target.value != "" ? e.target.value : null);
        //     debounce(e.target.value != "" ? e.target.value : null)
            
        //   }}
        // }}
        onFocus={()=>{
          // NOT LARGE MENU!!!
          if(!largeMenu && (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))){
            let menu = document.getElementsByClassName("react-modal-sheet-container")[0]
            menu.style.setProperty("--tf",menu.style.transform)
            menu.style.transform = "none"

          }
        }}
        


        onBlur={(e)=>{if ((!maxLetters || (e.target.value.length <= maxLetters))) {
          console.log("BLURRjkbj")
          if(!largeMenu && (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))){
            let menu = document.getElementsByClassName("react-modal-sheet-container")[0]
            menu.style.transform = menu.style["--tf"]
            }
          // setText(e.target.value != "" ? e.target.value : null);
          setCurrentValue(e.target.value != "" ? e.target.value : null)
        }}}
          onChange={(e) => {
            // const debounced = debounce((value) => {
            //   console.log("debounce w/ ",value)
            //   if (!maxLetters || (value.length <= maxLetters)) {
                
            //        setCurrentValue(value != "" ? value : null);
            //        console.log("lazy updated text")
            //      }
            // }, 50)
            
            
            if ((!maxLetters || (e.target.value.length <= maxLetters))) {
              setText(e.target.value != "" ? e.target.value : null);
              // debounced(e.target.value != "" ? e.target.value : null)
              // debounced(e.target.value != "" ? e.target.value : null);
            }
            
          }
          }
        
       
      />
      {/* <Button onClick={()=> setCurrentValue(text != "" ? text : null)}>Save</Button>
       */}
      </>
      }
     
    </Box>
     {maxLetters && <small style={{display: "block", fontSize: "0.8rem", color: (currentValue && (currentValue.length == maxLetters)? "red" :"#666"), marginTop: innerWidth > 800 ?"-35px" : "-35px"}}>{maxLetters} letters max.</small>}</>
  );
}
